.IconTextPart {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  text-align: center;
  height: 40px;
  font-size: 14px;
  width: 70%;
  align-items: center;
  /* font-weight: 600; */
  position: relative;
  margin-left: 26px;
}
.iconPaper {
  /* height: 60px; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 5px,
    rgb(255, 255, 0) 0px -2px 0px inset !important; */
  background-color: #423e44;
}

@media only screen and (max-width: 768px) {
  .iconPaper {
    height: auto;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 5px,
      rgb(255, 255, 0) 0px -2px 0px inset; */
  }
  .IconTextPart {
    display: flex;
    justify-content: space-between;
    text-align: center;
    flex-direction: column;
    height: auto;
    /* background-color: white; */
    padding-bottom: 14px;
    align-items: center;
    margin: auto;
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1160px) {
  .IconTextPart {
    display: flex;
    justify-content: space-between;
    text-align: center;
    flex-direction: column;
    height: auto;
    /* background-color: white; */
    padding-bottom: 14px;
    align-items: center;
    margin: auto;
    width: 100%;
  }
}
.menuLink {
  text-decoration: none;

  color: #ffffff !important;

  font-size: 14px;
  font-weight: 500 !important;
  position: relative;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  cursor: pointer; /* Add cursor pointer */
}

.menuLink:hover,
.activeMenuLink {
  /* color: #ED2026 !important; */
  color: #ffffff;

  text-decoration: underline;
  text-underline-offset: 11px;
  text-decoration-thickness: 3px;
}

.menuLink::after,
.activeMenuLink::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0; /* Adjust this value if necessary */
  max-width: 100%; /* Set width to 100% to make the underline span the entire width of the link */
  height: 2px;
  background-color: #e0001b;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease-in-out;
}

.menuLink:hover::after,
.activeMenuLink::after {
  transform: scaleX(1);
}
