.roundDiv {
  width: 64px;
  height: 55px;
  background-color: rgb(243, 243, 243);
  /* border-radius: 50%; */
  padding: 5px !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 1px solid transparent; /* Initial border settings */
  padding: 10px; /* Optional: Adds some padding */
}
.roundDiv:hover {
  color: #179949 !important;
  border-color: #179949 !important; /* Changes border color to green on hover */
}
@media (max-width: 1024px) {
  .roundDiv {
    width: 105px;
    height: 55px;
    background-color: rgb(243, 243, 243);
    /* border-radius: 50%; */
    /* font-size: 1px !important; */
    /* padding: 5px !important; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .roundDiv {
    width: 55px;
    height: 55px;
    background-color: rgb(243, 243, 243);
    /* border-radius: 50%; */
    padding: 5px !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 425px) {
  .roundDiv {
    width: 65px;
    height: 55px;
    background-color: rgb(243, 243, 243);
    /* border-radius: 50%; */
    padding: 5px !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 375px) {
  .roundDiv {
    width: 85px;
    height: 55px;
    background-color: rgb(243, 243, 243);
    /* border-radius: 50%; */
    padding: 5px !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 320px) {
  .roundDiv {
    width: 105px;
    height: 55px;
    background-color: rgb(243, 243, 243);
    /* border-radius: 50%; */
    padding: 5px !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.eventsCard {
  background-color: #ffffff;
  width: 92%;
  border-radius: 8px;
  height: 280px;
  padding: 15px;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-right: 10px;
}
@media (max-width: 1024px) {
  .eventsCard {
    background-color: #ffffff;
    width: 100%;
    border-radius: 8px;
    height: 279px;
    padding: 15px;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-right: 10px;
  }
}
/* .eventsPart {
  display: flex !important;
  justify-content: flex-start !important;
  flex-direction: row !important;
} */
.eventsPart {
  display: flex !important;
  justify-content: flex-start !important;
  flex-direction: row !important;
  gap: 10px; /* Adds horizontal gap between elements */
  padding: 10px 0; /* Adds vertical gap between elements */
  border-bottom: 1px solid #f1f1f1;
}
.eventsPart:hover {
  border-bottom: 1px solid #179949;
}
.boardText {
  color: #6c6d70 !important;
  font-size: 14px !important;
  text-align: left;
  margin-bottom: 2px !important;
  font-weight: bold !important;
  text-transform: capitalize;
}
.subText {
  color: #dadada !important;
  font-size: 11px !important;
  text-align: left;
}
.textPart {
  /* width: 150px !important; */
  display: flex;
  flex-direction: column;
  /* margin-left: 20px !important; */
}
.date {
  color: #179949 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-align: center;
}
@media (max-width: 1024px) {
  .date {
    color: #179949 !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: center;
  }
}
.month {
  color: #179949 !important;
  /* margin-top: 4px !important; */
  font-size: 12px !important;
  /* font-weight: 600 !important; */
}
@media (max-width: 1024px) {
  .month {
    color: #179949 !important;
    /* margin-top: 4px !important; */
    font-size: 12px !important;
    /* font-weight: 600 !important; */
  }
}
.Time {
  color: white !important;
  background-color: black;
  font-size: 10px !important;
  white-space: nowrap;
  /* font-weight: 600 !important; */
  /* margin-top: 4px !important; */
  padding-right: 7px !important;
  padding-left: 7px !important;
  margin-bottom: 4px !important;
}
@media (max-width: 1024px) {
  .Time {
    color: white !important;
    background-color: black;
    font-size: 10px !important;
    font-weight: 600 !important;
    white-space: nowrap;
    margin-top: 8px !important;
  }
}
.root {
  width: 100%;
  max-width: 100%;
  display: flex;
  /* bgcolor: background.paper; */
  padding-top: 10px !important;
  padding-bottom: 16px !important;
}
.Eventslanding-responsive {
  /* margin-top: 8px; */
  padding: 16px;
  border-radius: 10px !important;
  padding-bottom: 25px !important;
}

/* @media (max-width: 768px) {
  .Eventslanding-responsive {
    margin-top: 0px !important;
  }
} */
.mainPaperforeevents {
  /* height: 375px; */
  border-radius: 8px !important;
  padding: 16px;
  padding-top: 0px;
  padding-right: 0px;
  margin-top: 20px;
}
.main-filter-events {
  border-radius: 8px !important;
  padding: 10px;
  padding-top: 0px;
  padding-right: 0px;
  margin-top: 16px;
  height: 344px;
}
/* @media (max-width: 1440px) {
  .main-filter-events {
    border-radius: 8px !important;
    padding: 10px;
    padding-top: 0px;
    padding-right: 0px;
    margin-top: 16px;
    height: 346px !important;
  }
} */
.new-date-box {
  width: 90px;
  height: 80px;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.event-date-box {
  width: 80px;
  height: 80px;
  background-color: #f6f6f6;
  border-radius: 5px;
}

.new-time {
  background-color: #221e1f;
  color: white;
  padding: 3px;
  font-size: 10px !important;
  text-align: center;
  margin-top: 5px;
  white-space: nowrap;
  margin-right: 7px !important;
  margin-left: 7px !important;
  /* margin-bottom: 4px !important; */
  border-radius: 3px;
  position: sticky;
  bottom: 0;
}
.MuiPopover-paper-MuiMenu-paper {
  opacity: 1;
  transform: none;
  transition: opacity 292ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 194ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 80px;
  left: 1050px;
  transform-origin: 68px 145.667px;
}

.selected-label {
  /* background-color: #ffe0e1; */
  font-size: 12px !important;
  color: #8c8c8f;
  border-radius: 2px;
}

.MuiFormControlLabel-label {
  font-size: 12px !important;
}
.filterLabels {
  height: 20px;
  font-size: 12px !important;
  padding: 2px;
  margin: 2px !important;
}
.calendarRoot .MuiInputBase-root {
  border-bottom: none !important;
  outline: none;
  padding: 2px !important;
  font-size: 12px;
  color: var(--redprimary);
  /* background-color: #e6ffe6; */
  border-radius: 5px;
  border: #ffffff !important;
}
.calendarRoot .MuiInputBase-root:before {
  border-bottom: none !important;
  left: 0;
  border: #ffffff !important;
}

.calendarRoot .MuiInputBase-root:after {
  border-bottom: none !important;
  left: 0;
  border: #ffffff !important;
}
.calendarRoot .MuiInputBase-root .MuiInputBase-input,
.calendarRoot .MuiInputBase-input {
  padding: 1px !important;
}

.calendarRoot .MuiInputBase-root .MuiSvgIcon-root {
  fill: #e0001b !important;
}
.eventcalanderMonanddate {
  /* display: flex; */
  /* justify-content: space-between; */
  padding: 10px;
  border-bottom: 1px solid #f1f1f1;
}
.viewEven {
  font-family: Calibri Regular !important;
  font-size: 14px !important;
  text-decoration: none;
  color: #6c6d70;
}
.viewEven:hover {
  color: green !important;
  text-decoration: underline !important;
}
.event-f-date {
  padding: 1px 13px 8px 0px;
  /* border-bottom: 1px solid #f1f1f1; */
}

.eventcalanderTime {
  font-size: 13px !important;
  color: #333333;
  opacity: 0.5;
}
.eventcalanderTitle {
  font-size: 16px !important;
  color: #8c8c8f;
  font-weight: 600 !important;
  font-family: "Calibri Regular" !important;
}
.event-filter-Title {
  font-size: 12px !important;
  color: #8c8c8f !important;
  font-weight: 600;
}
.eventType {
  color: white;
  padding: 3px;
  border-radius: 5px;
  width: max-content;
  font-size: 12px !important;
  margin-bottom: 5px !important;
}
.eventType-filter {
  color: #ffffff;
  border-radius: 5px;
  padding: 3px;
  text-transform: capitalize !important;
  font-size: 12px !important;
  font-family: Calibri Regular !important;
  margin-bottom: 5px !important;
  width: 150px !important;
}
.news-readmore-type {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 5px;
  padding: 5px 6px;
  text-transform: capitalize !important;
  font-size: 14px !important;
  width: 135px !important;
}
.eventcalanderDescription {
  font-size: 13px !important;
  color: #333333;
  opacity: 0.5;
  font-family: "Calibri Regular" !important;
  padding-right: 10px;
}
.customlistforeventcalander {
  padding: 0px !important;
  margin-bottom: 10px;
}
.root react-calendar_month-view_weekdays {
  text-transform: capitalize;
  font-weight: 200;
}

.Calendar {
  border: 1px solid white;
  border-bottom: 1px solid #f1f1f1;
  /* padding-bottom: 20px; */
  margin: auto;
}

.calContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* margin-bottom: 8px; */
}

/* @media (max-width:768px) {
  .calContent {
    margin-right: 70px;
  }
} */

.calendar1 {
  border: 0px solid white;
  font-size: 14px;
  margin: 15px;
}
.calendar {
  border: 0px solid white;
  font-size: 12px;
  /* margin: 5px; */
}
.calendar .react-calendar {
  font-size: 12px;
}

.calendar .react-calendar__navigation {
  display: flex;
  height: 35px !important;
  margin-bottom: 1em;
}

.calendar .react-calendar_month-view_weekdays {
  text-transform: capitalize;
}

.calendar .react-calendar_month-view_days .react-calendar__tile {
  font-size: 9px !important;
  background-color: red !important;
}

.calendar .react-calendar__tile--rangeEnd {
  /* border-radius: 50% !important; Make border circular */
  color: none !important;
  background-color: #009bad !important;
  border: 2% solid transparent; /* Set border width using percentage */
}
.hasEvent {
  color: #009bad;
}

/* Customize selected date border and border-radius */
.react-calendar__tile--active {
  border: 2px solid #e0001b !important; /* Customize border color */
  /* border-radius: 50%; Customize border-radius */
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  /* border-radius: 50%; */
  border: 2vw solid #009bad; /* Using vw unit for border thickness */
}

/* Additional CSS for responsiveness */
@media screen and (max-width: 768px) {
  .react-calendar__tile--active {
    border: 4px solid #009bad; /* Adjust border thickness for smaller screens */
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    border: 4px solid #009bad; /* Adjust border thickness for smaller screens */
  }
}

.calendar button {
  font-size: 12px;
}

.calenderHeader {
  font-size: 16px !important;
  color: #009bad !important;
  /* margin-bottom: 12px !important; */
}

.calenderHeader2 {
  font-size: 12px !important;
  color: #089de3 !important;
  margin-top: 0px;
  /* margin-bottom: 8px !important; */
  text-decoration: none !important;
}

.border {
  border: 1px solid #837c7c17;
}

.border .react-calendar_month-view_weekdays {
  text-transform: capitalize;
}

.border .react-calendar {
  width: 240px !important;
  max-width: 100%;
}

.border .react-calendar__tile--now {
  background-color: #009bad !important;
  /* border-radius: 50%; */
}

@media (max-width: 768px) {
  .border .react-calendar {
    width: 240px !important;
    max-width: 100%;
  }
}

.react-calendar {
  width: 350px;
  max-width: 100%;
}

@media (max-width: 768px) {
  .react-calendar {
    width: 240px !important;
    max-width: 100%;
  }
}

.selectedEventslist {
  color: gray;
  font-size: 14px;
  text-align: left;
  /* margin-top: 0px;
  margin-bottom: 5px; */
}
.bullet {
  height: 8px;
  width: 8px;
  background-color: #009bad;
  /* border-radius: 50%; */
  display: inline-block;
}

.list {
  list-style: none;
  padding-left: 15px;
  font-size: 11px;
  opacity: 60%;
  margin-top: 0px;
  text-align: left;
}

.highlightedDate {
  /* background: #009BAD; */
  color: #e01919;
  /* border-radius: 50%; */
}

.react-calendar__tile--hasActive {
  background: #009bad;
}

/* .eventDot {
 Add styles for .eventDot
} */

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Calibri Regular !important;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
  /* border-radius: 50%; */
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  background-color: rgb(214, 234, 245);
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 22.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 0.833em;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #f1e5e6;
  color: grey;
  border-radius: 10px !important;
}

.react-calendar__tile--now {
  background: lightblue;
  color: #2875c7;
  /* border-radius: 50% !important; */
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: skyblue;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #f1e5e6;
  color: grey;
  border-radius: 10px !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #f1e5e6;
  color: grey;
  border-radius: 10px !important;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
.container {
  display: flex;
  justify-content: center; /* Horizontally centers the content */
  align-items: center; /* Vertically centers the content */
}

.typographyStyles2 {
  color: #6c6d70;
  height: 298px;
  padding-top: 100px;
}
.Stylesviewallevents {
  color: #6c6d70;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 100px; */
}

/* MUIcalander style */
.Muicalendar {
  border: 1px solid white;
  border-bottom: 1px solid #f1f1f1;
  /* padding-bottom: 20px; */
  margin: auto;
}
.css-1rtg91x-MuiDateCalendar-root {
  overflow: hidden;
  width: 100% !important;
  max-height: 336px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 336px;
}

.css-i5q14k-MuiDayCalendar-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: space-evenly !important;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.css-flbe84-MuiDayCalendar-weekContainer {
  margin: 2px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: space-evenly !important;
}
.css-1glxl41-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  color: #fff;
  background-color: #1976d2 !important;
  font-weight: 500;
}
.css-2vujm1-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  color: #fff;
  background-color: #009bad !important;
  font-weight: 500;
}
.css-1vs7z2v-MuiYearCalendar-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow-y: auto;
  height: 100%;
  padding: 0 4px;
  /* width: 320px; */
  width: 100% !important;
  max-height: 280px;
  box-sizing: border-box;
  position: relative;
}
.css-1386ez6-MuiMonthCalendar-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  padding: 0 4px;
  /* width: 320px; */
  width: 100% !important;
  box-sizing: border-box;
}
.css-1rtg91x-MuiDateCalendar-root {
  overflow: hidden;
  width: 320px;
  /* max-height: 321px; */
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 321px !important;
}
.css-1rv8zyt-MuiStack-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
  padding-top: 0px !important;
}
.todayMarker {
  background-color: #009bad !important; /* Color for today's date */
  color: white !important; /* Text color for today's date */
  /* border-radius: 50% !important; */
}

.react-calendar__tile--now {
  background: #e0001b !important; /* Remove background color */
  color: #ffffff !important; /* Set text color */
  border-radius: 10px !important;
}
.hasEvent {
  background-color: #009bad;
}
.eventMarker {
  color: #009bad; /* Desired color for event dates */
  font-weight: bold; /* Optionally, you can make the text bold */
}
/* Change the shape of the selected date tile to a circle */
.MuiDateCalendar-root .MuiYearCalendar-monthButton.Mui-selected,
.MuiDateCalendar-root .MuiYearCalendar-monthButton.Mui-selected:hover {
  border-radius: 50%;
}

/* Adjust the size of the selected date tile */
.MuiDateCalendar-root .MuiYearCalendar-monthButton.Mui-selected {
  width: 36px;
  height: 36px;
}
.events {
  background-color: #ffffff;
  /* width: 100%; */
  border-radius: 8px;
  height: 245px;
  padding: 0px 15px 15px 15px;
  /* margin-top: 16px; */
  /* margin-bottom: 16px;
  margin-right: 10px; */
  overflow-y: scroll;
  scrollbar-width: thin;
}
.rbc-time-header {
  display: none !important;
}
.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: 1px solid #c4091e !important;
  display: flex;
  /* max-height: 100%; */
  height: auto !important;
  /* min-height: 20px; */
  flex-flow: column wrap;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
}
.rbc-time-gutter > .rbc-timeslot-group > .rbc-time-slot > .rbc-label {
  text-align: right;
}
/* .rbc-time-gutter > .rbc-timeslot-group > .rbc-time-slot {
  background-color: green !important;
} */
.rbc-event-label {
  display: block;
}
.rbc-calendar {
  /* height: 350px !important; */
  height: 100vh;
  max-height: 510px;
  max-width: 100%;
}
.rbc-events-container {
  position: relative;
  height: 100%;
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: var(--redprimary) !important;
  color: white;
  font-size: 8px;
  padding: 2px 5px;
  max-width: fit-content;
}
.rbc-toolbar button {
  color: #373a3c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: 4px 4px !important;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
  height: 30px !important;
}
.rbc-event-continues-after {
  border-radius: 4px !important;
}
.big-calendarContainer {
  background-color: #d9ffe3 !important;
  max-width: 100% !important;
  margin: 24px;
  /* margin-bottom: 88px; */
  padding: 16px;
  border-radius: 10px;
  margin-bottom: 69px !important;
}
.calendar-container {
  /* display: flex; */
  background-color: #ffffff;
  margin-top: 15px;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}
.rbc-header {
  font-size: 12px !important;
  color: #9e9ea1 !important;
  text-transform: uppercase;
  padding: 3px 3px !important;
  height: 30px !important;
}
.rbc-date-cell {
  flex: 1 1;
  min-width: 0;
  padding: 3px !important;
  text-align: left !important;
  color: #9e9ea1 !important;
}

.calendar-main {
  flex: 3;
  /* padding: 20px; */
}

.calendar-sidebar {
  flex: 1;
  padding: 20px;
  background-color: #f3f3f3;
  border-left: 1px solid #ddd;
}

.event-details {
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.event-details p {
  margin: 0;
  padding: 5px 0;
}

.event-details strong {
  color: #e0001b;
}
.rbc-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: left;
  margin-bottom: 20px;
}
.rbc-toolbar .rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: left !important;
  font-size: 16px;
  font-weight: bold;
  color: var(--redprimary);
}
.active-button {
  background-color: #e0001b !important;
  color: white !important;
}
.rbc-toolbar .rbc-btn-group {
  display: flex;
  align-items: center;
}

.rbc-toolbar button {
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.rbc-toolbar button:hover {
  background-color: #f0f0f0;
}

.rbc-toolbar button:focus {
  outline: none;
}

.rbc-toolbar .rbc-toolbar-label {
  font-size: 18px;
  font-weight: bold;
}
.fc-custom-prev-button,
.fc-custom-next-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.fc-custom-prev-button:hover,
.fc-custom-next-button:hover {
  color: #007bff;
}

.fc-button.fc-button-primary .fa {
  font-size: 20px;
}

@media (max-width: 1024px) {
  .events {
    height: 245px;
  }
}
.EventReference {
  background-color: #e0001b;
  color: #ffffff;
  border-radius: 3px;
  padding: 6px 10px 6px 10px;
  text-transform: capitalize !important;
  font-size: 12px !important;
  margin-top: 8px !important;
  width: 150px;
  text-align: center;
}
@media (max-width: 1024px) {
  .EventReference {
    width: 120px;
  }
}

.createtwo {
  /* color: white !important; */
  padding: 4px !important;
  background-color: #ffdf00 !important;
  color: #000000 !important;
  font-size: 12px !important;
  width: 85% !important;
  height: 24px !important;
  margin-left: 11px !important;
  margin-top: 10px !important;
  font-weight: 400 !important;
}
.eventspage {
  overflow-y: auto;
}
