.breadcrumb-container {
    display: flex;
    align-items: center;
    padding: 8px;
  }
  
  .breadcrumb-content {
    display: flex;
    align-items: center;
  }
  
  .breadcrumb-item {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }
  
  .breadcrumb-icon {
    color: gray;
    margin-right: 4px;
  }
  
  .breadcrumb-text {
    cursor: pointer;
    font-size: 16px !important;
    color: gray;
    transition: color 0.3s, font-weight 0.3s;
    font-weight: 700;
  }
  
  .breadcrumb-text:hover {
    color: #606c74;
  }
  
  .breadcrumb-selected {
    font-weight: bold;
    color: #606c74;
  }
  
  .breadcrumb-no-folders {
    color: gray;
  }
  