.bannerPart {
  /* background-image: linear-gradient(rgb(188, 186, 186), rgb(215, 42, 63)); */
  height: 330px;
  border-radius: 8px;
  width: 100%;
  /* margin-left: 16px; */
}
.slider-container {
  position: relative;
}

.custom-next-arrow {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust as needed */
  transform: translateY(-50%);
  cursor: pointer;
  /* background: red; */
  color: white;
  padding: 5px 10px;
  border-radius: 2px;
}

.custom-prev-arrow {
  position: absolute;
  top: 50%;
  /* left: -10px;  Adjust as needed */
  transform: translateY(-50%);
  cursor: pointer;
  /* background: green; */
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 5;
}

.slickDotsContainer {
  position: absolute;
  bottom: 5px !important;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slickDots .slick-dots {
  position: absolute;
  bottom: 10px !important;
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
}

.slickDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
  margin: 0 5px;
  cursor: pointer;
}

.arrowContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 800px;
  display: flex;
  justify-content: space-between;
}

.arrowIcon {
  font-size: 2em;
  color: black;
  cursor: pointer;
}
.slider-container {
  position: relative;
}
.arrowIcon {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: #fff;
  cursor: pointer;
}

.arrowIcon.left {
  left: 10px; /* Adjust as needed */
}

.arrowIcon.right {
  right: 10px; /* Adjust as needed */
}
.image {
  height: 305px;
  border-radius: 8px;
  width: 100%;
}

.bannerHero {
  width: 80%;
  height: 300px !important;
  /* position: relative; */
  border-radius: 10px;
}

/* @media (max-width: 768px) {
  .bannerHero {
    width: 100%;
    margin: 0;
  }
} */

.bannerButton {
  position: absolute;
  bottom: 13px;
  left: 16px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 6px;
  background: #009bad;
  color: #fff;
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 6px;
  cursor: pointer;
}

.playArrow {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 0px;
  margin-right: 0px;
  width: 18px !important;
  height: 18px !important;
}
.playbuttonName {
  font-size: 14px;
}
.banner-slide {
  position: relative;
  width: 100%; /* Set the width to take the full width */
  margin: 0 auto;
  /* margin-bottom: 20px; Adjust the bottom margin as needed */
  border-radius: 20px !important; /* Set the same border-radius as CEO message */
}

/* .bannerHero {
  width: 100%;
  height: auto;
  position: relative;
  border-radius: 10px !important;
   image-rendering: -moz-crisp-edges;
} */

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  padding: 0px;
  background-origin: border-box;
  border-radius: 10px; /* Set the border-radius for overlay to match bannerHero */
  overflow: hidden;
}

.overlay h2 {
  margin-top: 100px;
  margin-left: 20px;
  margin-bottom: 10px;
}

.overlay p {
  margin: 0 20px;
}

.text {
  color: white;
  position: relative;
  margin: 0 2rem;
  bottom: 40px;
}

.indicators {
  margin-top: -50px;
}

.root {
  max-width: 100%;
}

.contentEditorWidth {
  max-width: 100% !important;
}

.buttonPosition {
  position: absolute;
  top: 45%;
}

@media (max-width: 768px) {
  .buttonPosition {
    position: absolute;
    top: 100%;
    left: 45%;
  }
}

.videoPart {
  width: 100%;
  height: 200px;
  position: relative;
}

.cardHeight {
  padding-top: 20px;
  background: transparent !important;
  box-shadow: none;
  border-radius: 10px;
}

.contentHeader {
  position: relative;
  background-color: unset !important;
  padding-top: 15px;
}

.contentArea {
  padding-left: 0px !important;
  padding-right: 0px !important;
  display: flex;
  justify-content: space-between;
}

.innerBackground {
  /* background: url(${background}); */
  height: 100px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  border-radius: 8px;
  position: relative;
}

.innerBannerOverlay {
  position: absolute;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 0, 0, 0),
    rgb(2 53 77)
  );
  height: 90%;
  color: #ffffff;
  bottom: 0;
  border-radius: 8px;
}

.breadcrumbsHeader {
  font-size: 20px;
  line-height: 20px;
  padding-top: 10px;
}

.breadcrumbsLinks {
  background: #009bad !important;
  height: 30px;
  text-transform: capitalize;
}

.breadcrumbs {
  color: #ffffff !important;
  display: inline-flex;
}

.breadLinks {
  color: #ffffff !important;
  text-decoration: none !important;
  font-size: 24px !important;
}

.breadLinks1 {
  color: #ffffff !important;
  font-size: 14px !important;
  text-decoration: none !important;
}

.breadLinks2 {
  color: #ffffff !important;
  font-size: 14px !important;
  opacity: 0.5;
  text-decoration: none !important;
}

.ceoContent {
  width: 100%;
  display: block;
  margin-top: 7px;
  opacity: 0.6;
  padding-right: 10px;
}

/* .videoContent {} */

.video {
  height: 253px;
  display: block;
  overflow: hidden;
  width: fit-content;
  border-radius: 5px;
  padding-bottom: 0px !important;
}

.displayImg {
  height: 253px;
  display: block;
  overflow: hidden;
  width: 100%;
  border-radius: 5px;
  position: relative;
  padding-bottom: 0px !important;
}

.videoTitle {
  position: absolute;
  color: white;
  top: 46%;
  left: 34%;
  transform: translateX(-50%);
  text-align: left;
  cursor: pointer;
  text-decoration: none;
}

.DescPart {
  position: absolute;
  color: white;
  top: 82%;
  left: 16%;
  transform: translateX(-50%);
  text-align: left;
  cursor: pointer;
  text-decoration: none;
}

.exploreBtn {
  position: absolute;
  color: white;
  background-color: #009bad;
  bottom: 3%;
  left: 25%;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.bannerTitle {
  font-size: 28px !important;
  font-weight: 600 !important;
  color: #333333 !important;
  text-align: left;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}

.bannerDate {
  font-size: 16px !important;
  color: #009bad;
  text-align: left;
  margin-bottom: 10px !important;
}

.bannerContent {
  color: #333333;
  font-size: 13px;
  text-align: left;
  padding-bottom: 20px;
  opacity: 0.6;
}

.captionOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  text-align: center;
}
.error-div {
  height: 237px;
  margin-top: 7px;
  margin-left: 16px;
}
.errorHerobanner {
  color: #8a9198;
  font-size: 16px !important;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 111px;
}
.heroBannerGride{
  border-radius: 8px;
    margin-left: 16px;
}

.breadLinks {
  font-size: 24px !important;
}

.mainContainerForHero {
  max-width: 100% !important;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px !important;
}

.heroBannerGrid {
  align-items: center;
}
.heroImage {
  width: 70% !important;
  /* height: 400px !important; */ 
  border-radius: 10px;
  /* max-height: 300px; */
  object-fit: contain !important;
}
.heroImage video {
  max-width: 70%;
  /* margin: 0 auto; */
  border-radius: 10px;
  max-height: 300px;
  margin-left: 0px;
}

.ReadmoreEventsDesc img {
  max-width: 70%;
  max-height: 300px;
  /* display: block; */
  margin: 0 auto;
  border-radius: 10px;
}