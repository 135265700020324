.clearIcon-it {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.TableHeader {
  font-size: 14px !important;
  color: #707073 !important;
  font-weight: 600 !important;
  line-height: 21.09px;
}

.EventsTableCell {
  font-size: 14px !important;
  color: #ababab !important;
  /* background-color: rgba(255, 255, 255, 1) !important; */
}
.span {
  color: #423e44;
  font-size: 12px;
}
.input-placeholder::placeholder {
  font-size: 12px;
  color: #a0a096;
}
.span .MuiInputBase-root {
  height: 33px;
  color: #423e44;
  border: 1px solid#E3E4E5;
  border-left: 3px solid #d20f3d;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 38px;
  height: 33px;
  color: gray;
  border: 1px solid#E3E4E5;
  padding: 0 0 0 8px;
  border-radius: 3px 0 0 3px;
  border-left: 3px solid #d20f3d;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.input-group {
  display: flex;
  align-items: center;
  gap: 0px;
}

.input-group select,
.input-group input {
  padding: 8px;
  font-size: 12px;
  border: 1px solid#E3E4E5;
  border-left: 3px solid #d20f3d;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-right: none;
  color: grey;
}
.input-group select {
  background-color: #f3f0f0;
}

.input-group input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.country-code {
  display: flex;
  align-items: center;
}

.country-code select,
.country-code input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  margin-right: 5px;
}

.country-code input {
  width: 100%;
}
.textarea {
  color: #a0a096;
  font-size: 12px;
}
.dashboardDiv {
  /* background-color: #ffe9e9; */
  background-color: #d20f3d;
  height: 100px;
  width: 100%;
  color: white;
  /* position: relative; */
}
.dashboard-upper {
  display: flex;
  justify-content: space-between;
  padding: 10px 50px;
}
.dashboard-text {
  font-size: 24px !important;
  color: white !important;
  font-weight: 600 !important;
}
.new-req-text {
  font-size: 16px !important;
  color: white !important;
  font-weight: 600 !important;
  padding-left: 40px !important;
}
.request-btn {
  background-color: #423e44 !important;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  padding: 5px 8px;
}
.icons-parts {
  border-radius: 8px;
  background-color: #ffffff;
  margin: 0px 50px;
  margin-top: -50px !important;
  padding: 15px 30px;
}
.approveList {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  /* padding: 20px 50px; */
  /* height: 60px; */
}

.searchFilterBox {
  padding-top: 10px;
  margin-bottom: 15px;
  margin-left: 20px;
  display: flex;
  justify-content: left;
}
.searchBarWrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0;
  padding: 0;
}
/* Search Bar */
.departSearchBar-it {
  background-color: #ffffff;
  width: 350px;
  height: 35px;
  padding-left: 2.5rem;
  border: 1px solid #f6f7f9;
  border-radius: 5px;
  outline: none;
  transition: 0.3s ease;
  color: #c2c0c0;
  box-sizing: border-box;
  margin-left: -15px;
}

.searchIcon-it {
  /* position: relative;
  left: 22px;
  top: 1px; */
  color: #c2c0c0;
  cursor: pointer;
}
.policiesSearchBar::placeholder {
  color: #9e9ea7;
}

.policiesSearchBar .MuiTextField-root {
  width: 100%;
}

/* Filter Box */
.policiesSearchBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.policiesSearchBox .MuiSelect-root {
  width: 48%;
}
.export-btn {
  /* color: #e0001b; */
  color: #aeadaf;
}
.export-div {
  /* border: 1px solid #aeadaf; */
  /* background-color: #ffffff; */
  padding: 5px 7px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-part {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  /* background-color: #ffffff; */
  margin: 10px 50px 0px 50px;
  padding: 0px 0px 10px 0px;
}
.search-Div {
  width: 100%;
  /* padding-top: 20px; */
}
.newPosOfDialog1 {
  padding: 20px;
  width: 20% !important;
}
.DrawerHeaderBox {
  background-color: var(--blackprimary);
  margin: 0px -20px;
  margin-top: -20px;
  padding: 5px 15px 5px 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.DrawerDescriptionHeaderBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -15px;
}
.filter-div {
  /* background-color: #ececec !important; */
  /* background-color: #ffffff !important; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 4px 20px; */
  /* width: 20px !important; */
  border-radius: 5px !important;
  color: #423e44 !important;
  text-transform: capitalize;
  text-decoration: none;
  cursor: pointer;
}
.filter-div:hover {
  background-color: transparent !important;
}
.clear-submit-div {
  width: 170px;
  display: flex;
  justify-content: space-between;
  float: right;
  margin: 18px 85px 10px 0px;
}
.reject-submit-div {
  width: 170px;
  display: flex;
  justify-content: space-between;
  float: right;
  margin: 30px 0px 20px 20px;
}
.text-b {
  color: #423e44 !important;
  font-size: 14px !important;
  margin-top: 10px !important;
  margin-left: 15px !important;
}
.spanText {
  color: #423e44 !important;
  text-transform: capitalize;
  text-decoration: none;
  padding-left: 10px;
  font-size: 14px !important;
}
.main-pic {
  width: 100px;
  height: 100px;
  float: left;
  border-radius: 5px;
  /* padding-left: 40px; */
}
.form-part {
  padding: 15px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.width200 {
  min-width: 200px;
}

.request-label {
  font-size: 12px;
  color: #a0a096;
}
.request-parts {
  height: auto;
  border-radius: 8px;
  /* background-color: #ffffff; */
  padding: 10px 50px;
  width: 100%;
  margin: 0px 50px;
  /* position: absolute; */
}
.custom-calendar .css-1rtg91x-MuiDateCalendar-root {
  width: 90% !important;
}
.calendarRequest .MuiInputBase-root {
  border-bottom: none !important;
  outline: none;
  padding: 2px !important;
  font-size: 12px;
  color: #a0a096;
  /* background-color: #e6ffe6; */
  /* border-radius: 5px; */
  border: #ffffff !important;
}
.calendarRequest .MuiInputBase-root:before {
  border-bottom: none !important;
  left: 0;
  border: #ffffff !important;
}

.calendarRequest .MuiInputBase-root:after {
  border-bottom: none !important;
  left: 0;
  border: #ffffff !important;
}
.calendarRequest .MuiInputBase-root .MuiInputBase-input,
.calendarRequest .MuiInputBase-input {
  padding: 1px !important;
}

.calendarRequest .MuiInputBase-root .MuiSvgIcon-root {
  fill: #a0a096 !important;
}
.R-Texts {
  color: #a0a096 !important;
  font-size: 14px !important;
  margin-bottom: 5px !important;
}
.upload-div {
  border: 1px solid #a0a096;
  padding: 6px 6px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  width: 236px;
}
.upload-btn {
  color: white;
  background-color: #423e44;
  border-radius: 5px;
  font-size: 12px;
  padding: 5px;
}
.approve-btn {
  color: white !important;
  background-color: #423e44 !important;
  /* border: 1px solid white !important; */
  text-transform: capitalize !important;
  padding: 3px 5px !important;
  border-radius: 5px !important;
  display: inline-block !important;
  white-space: nowrap !important;
}
.selected {
  color: #d20f3d !important;
  background-color: #ffffff !important;
  text-transform: capitalize !important;
  font-size: 12px !important;
  padding: 10px 10px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.notSelected {
  color: #ffffff !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  font-size: 12px !important;
  padding: 10px 10px !important;
  text-transform: capitalize !important;
  cursor: pointer;
}
.cancel-req-btn {
  color: #423e44 !important;
  background-color: white !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  padding: 5px 10px !important;
  text-transform: capitalize !important;
  cursor: pointer;
}
.submit-req-btn {
  color: white !important;
  background-color: #423e44 !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  padding: 5px 10px !important;
  text-transform: capitalize !important;
  cursor: pointer;
  /* margin-left: 20px !important; */
}
.tab-contain {
  /* width: 250px; */
  display: flex;
  justify-content: space-between;
  margin: 0px 40px;
  align-items: center;
}
.itformStatus {
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 1.75 !important;
  padding: 5px 10px !important;
}
.approvalStatus {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: rgb(255, 255, 255);
}
.all-Select {
  background-color: white !important;
  font-size: 14px !important;
}
.all-btn {
  color: #423e44 !important;
  background-color: white !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  padding: 5px 10px !important;
  text-transform: capitalize !important;
  cursor: pointer;
  width: max-content;
}
.all-btn:hover {
  color: white !important;
  background-color: #423e44 !important;
}
.table-over{
  height:400px;
   overflow-y:auto;
}
.non-selectable-footer {
  user-select: none;
}

.all-btn.active {
  color: white !important;
  background-color: #423e44 !important;
}

.drag-text {
  color: #a0a096 !important;
  font-size: 12px;
  padding-top: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.border-gray {
  border-left: 2px solid #a0a096;
}
.form-Last {
  padding: 15px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}
.TableBreadcrumbs {
  display: flex;
  align-items: center;
  padding-left: 40px;
}
.TableBreadcrumbs1 {
  font-size: 14px !important;
  color: white !important;
  text-transform: capitalize !important;
  opacity: 0.8;
  font-weight: 500;
  text-decoration: none;
}
.Breadcrumbs2 {
  font-size: 14px !important;
  color: white !important;
  text-transform: capitalize !important;
  font-weight: 500;
  text-decoration: none;
}

.breadcrumbarIconNew {
  margin: 6px 0px 0 0px !important;
  color: #e0001b !important;
}
.breadcrumsIconNew1 {
  margin: 6px 0px 0 0px !important;
  color: white !important;
}
.breadcrumbIconNew2 {
  margin: 7px 0px 0 0px !important;
  color: #7c7f82 !important;
}
.line {
  /* height: 40px; */
  color: #423e44;
  display: block;
  margin: 10px 0px;
  width: 1px !important;
}
.lastItem {
  width: 60px;
  padding-right: 10px;
  /* padding-left: 60px; */
}
.MuiSelect-select {
  font-size: 12px;
}
.error {
  color: var(--redprimary);
  font-size: 12px;
  margin-top: 5px;
}
.search-first-div {
  display: flex;
  justify-content: space-between;
  /* width: 43%; */
  /* margin-left: -15px; */
}
.status {
  margin: auto;
  padding-left: 50px;
  padding-right: 50px;
}
.status-inner-div {
  width: 300px;
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}
.all-menu {
  text-align: center;
  margin: auto;
}
@media only screen and (max-width: 768px) {
  .all-buttons {
    width: 150px;
    display: flex;
    margin: auto;
    justify-content: space-between;
    padding-left: 30px;
  }
  .export-div {
    margin: 5px;
  }
  .all-menu {
    text-align: center;
    margin: 5px;
  }
  .departSearchBar-it {
    max-width: 100%;
    font-size: 12px;
    padding-left: 2rem;
  }
  .searchIcon-it {
    left: 8px;
  }
  .dashboard-upper {
    padding: 15px 20px;
  }
  .status-inner-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: auto;
    margin: 10px 0px;
  }
  .status {
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
  .m-auto {
    margin: auto;
  }
  .search-part {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 10px;
    padding: 0px 0px;
  }
  .search-first-div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 170px;
  }
  .lastItem {
    padding-left: 1px;
  }
  .icons-parts {
    height: auto;
    margin-top: -30px !important;
  }
  .line {
    display: none;
  }
  .approveList {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 10px;
    align-items: center !important;
    gap: 30px;
  }
  .width200 {
    min-width: 100%;
  }
  .form-part {
    padding: 15px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  .form-Last {
    padding: 15px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
}

@media only screen and (max-width: 425px) {
  .approveList {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-direction: column;
    padding: 10px;
    align-items: center !important;
  }
}
