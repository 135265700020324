.root {
  max-width: 100%;
  background: #009BAD !important;
  color: #ffffff !important;
  height: 315px;
}


/* BirthdayContentpage.tsx css */
.newscontentbigPaper {
  box-shadow: 10px 1px 30px -10px #c2bcbc;
  border-radius: 20px;
  background-color: #d1eae33b;
}

.newscontenteditorBox {
  margin: 30px;
}

.documentTypeWrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.manualTag {
  margin-left: 10px !important;
  flex-grow: 1;
  top: -5px;
  width: 360px !important;
}

.error {
  color: red;
  font-size: 12px;
}

.media {
  height: 0;
  padding-top: 56.25%; /* 16:9 */
}

.expand {
  transform: rotate(0deg);
  margin-left: auto;
}

.expandOpen {
  transform: rotate(180deg);
}

.avatar {
  background-color: #f44336; /* red[500] in MUI */
}

.annHeader {
  padding-left: 16px;
  padding-top: 15px;
  font-size: 1rem !important;
  color: #ffffff !important;
}

.annTitle {
  line-height: 24px!important;
  height: 50px;
  overflow: hidden;
  color: #ffffff !important;
  margin-bottom: 10px;
  font-size: 20px !important;
  text-decoration: none;
}

.annTitle a {
  color: #ffffff !important;
  text-decoration: none;
}

.annContents {
  overflow: hidden;
  color: #ffffff !important;
  margin-bottom: 10px;
  opacity: 0.8;
}

.annContents a {
  color: #ffffff !important;
  text-decoration: none;
}

.annContents a:focus {
  color: #ffffff !important;
}

.annContents a:hover {
  color: #ffffff !important;
}

.annDesc {
  opacity: 0.8;
  height: 161px;
  overflow: hidden;
}

.readMore {
  font-size: 12px;
  color: #089DE3;
}

.NewsMainPart {
  height: 600px;
  width: 100%;
  background-color: white;
  margin-left: 20px;
  padding-right: 10px;
  border-radius: 10px;
  margin-bottom: 1px !important;
  margin-top: -28px !important;
}


.DataGrid {
  border: none !important;
}

.NewsSection {
  /* height: auto;
  width: 100%;
  background-color: #d1eae33b; */
  padding-right: 55px;
  /* border-top-right-radius: 9px; */
}

.upperPart {
  display: flex;
  justify-content: space-between;
  margin: 30px;
  padding-top: 30px;
  align-items: center;
}

.newscontenHeader {
  /* color: #02354d; */
  color:#e0001b;
  font-size: 16px;
  font-weight: 600;
}

.new {
  background-color: #009BAD;
  border-radius: 5px;
}

.new MuiButton {
  color: white !important;
  background-color: #009BAD;
}

.create {
  color: white !important;
  padding: 6px 12px !important;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.plus {
  text-align: center;
  margin-top: 5px;
  margin-right: 10px;
  color: white !important;
}

/* .newPosOfDialog {
  padding: 20px;
  width: 40%;
} */

.dropZone {
  padding: 20px;
  text-align: center;
  border: 3px dashed #eeeeee;
  color: #bdbdbd;
  background-color: #fafafa;
  margin-bottom: 20px;
}

.newItem {
  text-align: left;
  color: #02354d !important;
  font-weight: 500;
  font-size: 22px !important;
  margin-top: 10px !important;
}

.label {
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.label2 {
  display: flex;
  align-items: center;
}

.subHeading {
  font-size: 12px !important;
  color: #606C74 !important;
  font-weight: 500;
}

.subHeadingimg {
  width: 15px;
  margin-right: 8px;
}

.NewspreviewHeading {
  font-size: 14px !important;
  /* color: #333333 !important; */
  /* font-weight: 500; */
  margin-top: 20px !important;
  justify-content: center;
  height: auto;
  display: flex;
  color: white !important; 
  background-color: #848484;
  display: flex;
  justify-content:center;
  align-items: center;
}
.NewpreviewTag {
  font-size: 14px !important;
  /* color: #333333 !important; */
  /* font-weight: 500; */
  margin-top: 10px !important;
  justify-content: center;
  height: auto;
  display: flex;
  color: white !important; 
  background-color: #848484;
  display: flex;
  justify-content:center;
  align-items: center;
  border-radius: 4px !important;
  width: 50%;
}


.previewParagraph {
  font-size: 13px !important;
  color: #606C74 !important;
  /* display: flex; */
  /* justify-content: center; */
}
.NewspreviewParagraph {
  font-size: 13px !important;
  color: #606C74 !important;
}


.sideBar {
  height: 100%;
  background-color: #02354d;
  text-align: center;
  border-radius: 20px 1px 1px 20px;
}

.span {
  color: gray;
}

.span .MuiInputBase-root {
  height: 40px;
  color: gray;
}

.file MuiFormControl-root MuiSvgIcon {
  color: black;
  background-color: black;
}

.iconDiv {
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin: 15px;
}

.iconView {
  display: flex;
  justify-content: space-between;
  width: 50px;
}

.actionPart {
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin-right: 350px;
}

.saveBtn {
  background-color: #009BAD !important;
  color: white !important;
  text-decoration: none !important;
  font-size: 14px !important;
}

.cancelBtn {
  background-color: #606C74 !important;
  color: white !important;
  font-size: 14px !important;
}

.actionDivTwo {
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  gap: 30px;
  margin: 40px 0px;
  justify-content: flex-end !important;
}


.titleIcon {
  width: 13px;
  margin-right: 5px;
}

.checkLike {
  width: 15px;
  margin-right: 15px;
}

.dialogTitle {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.newsbackgroundImage {
  margin: 10px;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center !important;
  /* Align the image to the center */
  border-radius: 8px !important;
  /* border: 1px solid; */
  /* text-align: center; */
  /* border-radius: 8px; */
  width: 100% !important;
  height: 200px !important;
  width: auto;
  /* Let the browser calculate the width to maintain aspect ratio */
  height: auto;
  /* Let the browser calculate the height to maintain aspect ratio */
  max-width: 70% !important;
  /* Limit the maximum width of the image */
  max-height: 228px !important;
  /* Limit the maximum height of the image */
  display: block;
  /* Ensure it behaves like a block element */
}

/* Adjust size for smaller devices */
@media screen and (max-width: 768px) {
  .backgroundImage {
    max-width: 90%;
    /* Reduce max-width for smaller devices */
  }
}

/* Adjust size for even smaller devices */
@media screen and (max-width: 480px) {
  .backgroundImage {
    max-width: 100%;
    /* Reduce max-width further for even smaller devices */
  }
}

.dialogBtn {
  color: #606C74;
  font-size: 12px;
}

.svg {
  cursor: pointer;
  border: 1px dashed gray;
  border-radius: 5px;
  padding: 20px;
  height: auto;
  align-items: center;
}

.svg input {
  color: white;
  background-color: #009BAD;
}

.svg p {
  font-size: 12px;
  margin: 0;
  text-align: center;
  color: #606C74;
}

.svg span {
  display: none !important;
  font-size: 12px;
}

.svg button {
  color: white;
  background-color: #009BAD;
  font-size: 12px;
  margin-top: 10px;
}

.svg img {
  width: 45px;
}



.NewsHeadercontentEditor {
  /* color: #01375b; */
  color:#ed2026;
  font-size: 18px !important;
  font-weight: 600;
}
.newsDescription-style {
  color: rgba(125, 125, 125, 1);
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  /* white-space nowrap; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 2; */
  /* position: relative;
  left: 36px;
  margin-right: 102px; */
}

.TableHeader {
  font-size: 14px !important;
  color: #707073 !important;
  font-weight: 600 !important;
  line-height: 21.09px;
}

/* .newsTableHeader .MuiDataGrid-columnHeaderTitleContainer {
  margin-bottom: 15px;
} */

.newsTableCell {
  font-size: 14px;
  color: #ABABAB !important;
}

.custom-data-grid .MuiDataGrid-colCellTitle {
  height: 30px;
  /* Adjust the height as per your requirement */
}

.newsData-table1 {
  /* margin-top: 15px; */
  /* max-width: fit-content; */
  /* Set a maximum width */
  /* width: 100%; */
  /* Ensure it takes full width of its container */
  /* padding: 20px; */
  /* border-radius: 20px; */
  /* box-shadow: 10px !important; */
  box-shadow: none;
  box-shadow: 0px 0px 0px 0px #c2bcbc !important;
  height: 430px !important;
  /* border: 1px solid red; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 15px;
  background-color: white;
overflow-x: auto;
  /* overflow-x: auto;
  margin-left: 15px; */
  /* margin-right: 20px !important; */
  /* background-color: rgba(248, 246, 246, 1); */
  /* Enable horizontal scrolling for small screens */
}

.MuiDataGrid-virtualScroller {
  overflow: auto;
  height: 467px !important;
  position: relative;
  z-index: 0;
}

.css-1iyq7zh-MuiDataGrid-columnHeaders {
  max-height: 50px;

  min-height: 50px;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid;
  border-top-left-radius: var(--unstable_DataGrid-radius);
  border-top-right-radius: var(--unstable_DataGrid-radius);
}

.css-184cyof-MuiDataGrid-root {
  min-height: auto;
  --unstable_DataGrid-radius: 4px;
  --unstable_DataGrid-headWeight: 500;
  --unstable_DataGrid-overlayBackground: rgba(255, 255, 255, 0.38);
  --DataGrid-cellOffsetMultiplier: 2;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  box-sizing: border-box;
  position: relative;
  /* border-width: 1px; */
  border-style: none !important;
  border-color: none !important;
  border-radius: var(--unstable_DataGrid-radius);
  color: #333333;
  font-family: Calibri Regular !important;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  outline: none;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  min-height: 0;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-anchor: none;

}

.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
  padding-left: 0;
  font-size: 12px !important;
}

.even-row {
  background-color: rgba(249, 249, 249, 1) !important
}

.odd-row {
  background-color: rgba(255, 255, 255, 1) !important; /* Red color for odd rows */
}

.css-q9eoha-MuiDataGrid-root .MuiDataGrid-withBorderColor  {
  border-color: snow !important;
}


.addUser1 {
  display: flex;
  justify-content: left;
  /* line-height: 4.334 !important; */
  margin-left: 12px !important;
  font-size: 1rem !important;
  margin-top: 0px !important;
  gap: 10px !important;
  position: relative;
  top: 25px;
  /* align-items: left; */
  /* background-color: #009BAD; */
}

.plusIcon1 {
  width: 17px !important;
  height: 14px !important;
}

.addevents {
  background-color: #e0001b;
  color: white;
  height: 56px;
  position: relative;
  right: 10px;
  margin-top: -40px;
  margin-right: -35px;
  margin-left: -17px;
}

.addUser2 {
  display: flex;
  justify-content: left;
  line-height: 1.334 !important;
  margin-left: 14px !important;
  font-size: 1rem !important;
  margin-top: 0px !important;
  gap: 10px !important;
  position: relative;
  top: 23px;
  /* align-items: left; */
  /* background-color: #009BAD; */
}

.addevents2 {
  background-color: #e0001b;
  color: white;
  height: 56px;
  position: relative;
  right: 10px;
  margin-top: -36px !important;
  margin-right: -30px;
  margin-left: -10px;
  margin-bottom: 32px;
}
.spanmanualTag{
width: 240px !important;
position: relative;
bottom: 17px !important;
margin-top: 0px !important;
}
/* .manualTag{
  height: 6px !important;
} */
.Breadcrumbs {
  display: flex;
  font-size: 13px;
  position: relative;
  left: 31px;
  bottom: 19px;
  gap: 10px;
  font-weight: 600 !important;

}

.Breadcrumbs1 {
  color: rgba(125, 125, 125, 1);
}

.Breadcrumbs2 {
  color: #009BAD;
}
.ArrowIcon{
width: 7px;
height: 14px;
}
.NewsHeaderStack {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  padding-top: 10px;
  gap: 20px;
  /* justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-right: 11px;
  margin-left: 0px;
  border-radius: 10px;
  margin-bottom: 38px !important;
  margin-top: -20px !important; */
}

/* .ql-editor img {
  max-width: 100%; 
  height: auto;    
  display: block; 
} */


.newsdescriptionreadmorepage1 img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}


.newsdescriptionreadmorepage1 {
  font-size: 14px;
  color: #605D62;
  /* opacity: 0.6; */
  margin-top: 10px !important;
  overflow-x: hidden;
}
.newsdescriptionreadmorepage1 img {
  max-width: 70%;
  /* margin: 0 auto; */
  border-radius: 10px;
  margin-left: 0px;
}
.newsdescriptionreadmorepage1 video {
  max-width: 70%;
  /* margin: 0 auto; */
  border-radius: 10px;
  margin-left: 0px;
}
.custom-switch .MuiSwitch-switchBase.Mui-checked {
  color: var(--redprimary) !important;
}

.custom-switch .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: var(--redprimary) !important;
}
.newsforreadmorerightsidedescription{
  font-size: 13px;
color:#605D62;
opacity: 0.6;
/* height: 50px; */
text-overflow: ellipsis;
display: inline-block;
overflow: hidden;
display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
}

.previewParagraph1 video {
  max-width: 100%; /* Responsive videos */
  height: auto; /* Maintain aspect ratio */
}
