/* eslint-disable */

.brand {
  color: #ffffff;
  text-align: left;
}
.logo {
  margin-bottom: 20px;
  width: 120px;
  height: 45px;
}
.paperContainer {
  background: linear-gradient(90deg, #fff, #75135d00),
    url(../Assets/Images/remoBanner.png);
  color: #fff;
  height: 100vh;
  overflow: hidden;
  padding: 20px;
  width: 100%;
}
.buttonColor {
  background-color: #f87a1e;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  margin-top: 25px;
}
.title {
  font-size: 4.2rem;
  font-weight: 600;
  display: inline-block;
  position: relative;
}
.subtitle {
  color: #333;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  margin: 20px 0 30px !important;
  max-width: 400px;
  opacity: 0.9;
}
.main {
  background: #ffffff;
  position: relative;
  z-index: 3;
}
.mainRaised {
  margin: -60px 30px 0px;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.link {
  text-decoration: none;
}
.textCenter {
  text-align: center;
}
.welcomeItem {
  margin-top: -80px;
  padding-left: 80px;
  position: absolute;
  top: 40%;
  width: auto;
}
.signInButton {
  background-color: #f77a00 !important;
  color: #fff;
  padding: 6px 15px;
}
.notBtn {
  background-color: blue;
  color: #fff;
  padding: 6px 15px;
}
