/* eslint-disable */
/* import { makeStyles } from "@mui/styles"; */

/* export const useStyles = makeStyles({ */
.linkedinPaper {
  /* margin: 24px; */
  border-radius: 10px !important;
  margin-left: 10px;
}
/* Custom scrollbar styles for the container */
.customScrollbar {
  overflow-y: scroll;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #90a4ae #f1f1f1; /* Firefox */
}

/* Webkit browsers */
.customScrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.customScrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.customScrollbar::-webkit-scrollbar-thumb {
  background-color: #90a4ae;
  border-radius: 10px;
}
.headersText {
  color: #d20f3d !important;
  font-size: 16px;
  font-weight: bold !important;
  text-align: left;
  padding-left: 20px;
  padding-top: 10px;
  /* margin-bottom: 10px !important; */
}
.rootForSocialMedia {
  flex-grow: 1;
  height: auto;
  border-radius: 10px !important;
  /* max-width: 100%; */
  /* background-color: theme.palette.background.paper; */
}
/* @media (max-width: 768px) {
        .rootForSocialMedia {
          margin-top: 8px !important;
        }
      } */
.tabWidthForSocialMedia {
  width: 100%;
}

.tabHeaderForSocialMedia {
  border-radius: 4px;
  background-color: transparent !important;
}

/* .tab1HeaderForSocialMedia {
  min-width: 68px !important;
  padding: 10px !important;
  background-color: #f5f7fb !important;
  color: #00acee;
} */
.tab1HeaderForSocialMedia {
  /* min-width: 68px !important; */
  min-width: 40px !important;

  /* padding: 10px !important; */
  /* border-bottom: 2px solid #00acee; */
  background-color: #f5f7fb !important;
  color: #00acee;
}

@media (max-width: 768px) {
  .tab1HeaderForSocialMedia {
    min-width: 68px !important;
    padding: 5px !important;
  }
}

.tab2HeaderForSocialMedia {
  /* minWidth: 68px!important; */
  /* borderBottom: 2px solid #bc2a8d; */
  background-color: #f5f7fb !important;
  color: #bc2a8d;
}

@media (max-width: 768px) {
  .tab2HeaderForSocialMedia {
    min-width: 68px !important;
    padding: 5px !important;
  }
}

.tab3HeaderForSocialMedia {
  /* minWidth: 68px!important; */
  /* borderBottom: 2px solid #bc2a8d; */
  background-color: #f5f7fb !important;
  color: #4267b2;
}

@media (max-width: 768px) {
  .tab3HeaderForSocialMedia {
    min-width: 68px !important;
    padding: 5px !important;
  }
}

.tab4HeaderForSocialMedia {
  /* minWidth: 68px!important; */
  /* borderBottom: 2px solid #bc2a8d; */
  background-color: #f5f7fb !important;
  color: #0077b5;
}

@media (max-width: 768px) {
  .tab4HeaderForSocialMedia {
    min-width: 68px !important;
    padding: 5px !important;
  }
}

.HeaderCtaForSocialMedia {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row;
  justify-content: space-between !important;
  margin-left: 15px;
  margin-right: 0px;
}

@media (max-width: 768px) {
  .HeaderCtaForSocialMedia {
    display: flex;
    justify-content: space-between !important;
    flex-direction: column;
    width: 100%;
    margin-right: 34px;
    margin-left: 0px;
  }
}

.HeaderForSocialMedia {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 54px;
  padding: 10px;
  width: 285px;
}

@media (max-width: 768px) {
  .HeaderForSocialMedia {
    width: 85% !important;
  }
}

._aa4cForSocialMedia {
  width: 100%;
}

@media (max-width: 768px) {
  ._aa4cForSocialMedia {
    width: 95%;
    min-width: 280px !important;
  }
}
.socialhbody {
  width: 100% !important;
  overflow-y: auto; /* Add this line for the scrollbar */
  padding-bottom: 0 !important;
  scrollbar-width: thin;
  height: 308px;
}
