.homePage {
  background-color: #f1f1f1;
  width: 100%;
}

.content {
  /* margin-left: 30px; */
  margin-right: 30px;
  margin-top: 16px;
}
.pr30 {
  padding-right: 30px;
}
.grid-container {
  display: flex;
}

.hero-banner-container {
  flex-grow: 1;
  transition: margin-left 0.3s ease-in-out;
}

.shrinked-hero {
  margin-left: 60px; /* Same as menu-container width */
}

.expanded-hero {
  margin-left: 300px; /* Same as quick-links-container width */
}
/* .documentPortalBox {
  max-width: 100% !important;
  height: 100% !important;
  cursor: pointer;
  background-color: #f04449;
  padding: 0px 0px 10px 15px;
  position: static;
} */
/* .mainContainer {
  transition: width 0.3s ease-in-out;
  max-width: 100% !important;
  height: 100% !important;
  cursor: pointer;
  background-color: #ffffff;
  padding: 5px 5px 5px 10px;
}
.mainMenuContainer {
  transition: width 0.3s ease-in-out;
  max-width: 100% !important;
  height: 100% !important;
  cursor: pointer;
  background-color: #f04449;
  padding: 5px 5px 5px 10px;
} */
.icons-use {
  color: #6c6d70;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  transition: color 0.3s;
}
.icons-new-use {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  transition: color 0.3s;
  position: relative;
}

.usesIcon {
  color: #6c6d70;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.3s;
}

.usesIcon:hover {
  color: #6c6d70;
  /* padding: 4px;
  width: 20px;
  height: 20px;
  border-radius: 5px; */
}
.label-link {
  /* color: #e0001b; */
  color: #9a9b9d;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  float: left;
  transition: color 0.3s;
}
.label-new-link {
  color: #ffffff;
  font-size: 12px;
  /* font-weight: 600; */
  text-align: left;
  float: left;
  transition: color 0.3s;
}

.useFull-div {
  display: flex;
  flex-direction: row;
  padding: 5px;
  margin-top: 10px;
  width: 150px;
  transition: background-color 0.3s, color 0.3s;
}
.useFull-div:hover {
  background-color: #f3f3f3;
  border-radius: 4px;
}

.useFull-div:hover .icons-new-use {
  color: #e0001b;
}
.icons-new-use img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease-in-out;
}

.icons-new-use img:last-child {
  opacity: 0;
}

.useFull-div:hover .icons-new-use img:first-child {
  opacity: 0;
}

.useFull-div:hover .icons-new-use img:last-child {
  opacity: 1;
}

.useFull-div:hover .label-new-link {
  color: #e0001b;
}
.icons-new {
  width: 18px;
  display: block;
}
.icons-new:hover {
  display: none;
}
.icons-newHover {
  width: 18px;
  display: none;
}
.icons-newHover:hover {
  width: 22px;
  display: block;
}

/* @media only screen and (max-width: 768px) {
  .all-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .linkCard {
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 1120px) {
  .all-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .linkCard {
    height: auto;
  }
} */
.usefull-tooltip .tooltip {
  background-color: white !important;
  color: #e0001b !important;
  font-size: 12px !important;
  border: 1px solid #dadde9 !important;
}

.usefull-arrow::before {
  color: black !important;
}
.mainContainer {
  transition: width 0.3s ease-in-out;
  max-width: 100% !important;
  height: 100% !important;
  cursor: pointer;
  background-color: transparent;
  padding: 5px 5px 5px 10px;
}

.menu-container {
  width: 40px;
  background-color: #ffffff;
}

.links-lists {
  background-color: #ffffff;
  color: #e0001b;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}
.links-lists :hover {
  background-color: #e0001b !important;
  color: #ffffff !important;
  padding: 8px;
}

.quick-links-container {
  width: 200px;
  background-color: #ffffff;
}
.useFull-link {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  margin-top: 10px;
  transition: background-color 0.3s, color 0.3s;
}
.useFull-link:hover {
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 3px;
  margin-top: 10px;
  transition: background-color 0.3s, color 0.3s;
  /* width: 30px;
  height: 40px; */
  border-radius: 5px;
  /* margin: auto; */
}
.useFull-text {
  font-size: 15px !important;
  color: #b6b7ba !important;
  font-weight: 700 !important;
  padding: 20px 10px 10px 10px !important;
}
.useFull-new-text {
  font-size: 15px !important;
  color: #ffffff !important;
  /* font-weight: 700 !important; */
  padding: 20px 10px 10px 10px !important;
}

@media only screen and (max-width: 768px) {
  .content {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
  }
}
.pr301 {
  padding-right: 1px;
}
@media only screen and (max-width: 1024px) {
  .pr301 {
    padding-right: 10px;
  }
}
@media only screen and (max-width: 1024px) {
  .pr300 {
    padding-right: 10px;
  }
}
