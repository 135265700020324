/* Styles.css */

.ul {
  margin-inline-end: -50px; /* Adjust the value as needed */
}

/* .contentEditorWidth {
    max-width: 100% !important;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
  } */

.calendar {
  width: 100% !important;
  height: 100% !important;
  line-height: 2.125em !important;
  border: 0px solid #a0a096;
  color: #333333 !important;
}
.calendar1 {
  width: 100% !important;
  height: 100% !important;
  line-height: 2.125em !important;
  border: 0px solid #a0a096;
  color: #333333 !important;
}
.calendar1 {
  border: 0px solid white;
  font-size: 14px;
  margin-top: 15px !important;
}
.typographyStyles1 {
  color: #009bad !important;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-bottom: 1px solid #f1f1f1;
}

.paperStyles2 {
  position: relative;
  margin-bottom: 340px;
  padding-bottom: 200px;
  margin-left: 800px;
  margin-right: 80px;
  margin-top: 50px;
}

.paperStyles {
  height: 375px;
}

.hoverDot {
  color: red;
  cursor: pointer;
  transition: transform 0.2s;
}

.hoverDot:hover {
  transform: scale(1.5);
}

.highlight {
  background-color: yellow;
}

/* .calendarPaper {
     Add any other styles you need for the paper 
  } */

/* .highlightedDate {
    background: #009BAD; 
    color: #fff; 
    border-radius: 50%;
  } */

.react-calendar__tile--hasActive {
  background: #6f48eb;
}
.react-calendar_month-view_weekdays {
  text-transform: capitalize !important;
  background-color: #009bad !important;
  opacity: 0.5;
}

/* .eventscalendarContainer {
    margin-top: 0px;
    display: flex;
  }
  
  .eventscalendarContainer > div {
    flex: 1;
    margin-right: 16px;
  } */

.EventsTitle {
  font-size: 29px !important;
  font-weight: 600 !important;
  /* padding-left: 20px !important ; */
}

.EventsDateTime {
  font-size: 12px !important;
  margin: 10px 0 0 0;
  /* padding-left: 20px !important ; */
  color: darkgray;
}

.Events {
  color: #D20F3D;
  font-size: 22px;
  font-weight: 600;
  position: relative;
  bottom: 5px !important;
}

.CopyLink {
  position: relative;
  bottom: 7px;
  left: 50%;
  transform: translateX(-50%);
}
.eventimg {
  width: 100%;
  height: 350px;
  border-radius: 10px;
  object-fit: cover;
}
.eventDesc {
  font-size: 13px !important;
  color: #333333;
  opacity: 0.6;
  /* margin-left: 20px !important; */
}

/* Style for a highlighted date with an active event */
.highlightedEventAndActiveDate {
  background-color: #009bad; /* Red background */
  color: #ffffff; /* White text */
}

/* Style for a highlighted date with an inactive event */
.highlightedEventDate {
  background-color: #009bad; /* Yellow background */
}

/* Style for a highlighted active date without an event */
.highlightedActiveDate {
  background-color: #009bad; /* Green background */
}
.mainContainerforannuncement {
  max-width: 100% !important;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px !important;
}
