.root {
  max-width: 100%;
  background: #009BAD !important;
  color: #ffffff !important;
  height: 315px;
}

.error {
  color: red;
  font-size: 12px;
}

.media {
  height: 0;
  padding-top: 56.25%; /* 16:9 */
}

.expand {
  transform: rotate(0deg);
  margin-left: auto;
}

.expandOpen {
  transform: rotate(180deg);
}

.avatar {
  background-color: #f44336;
}

.annHeader {
  padding-left: 16px;
  padding-top: 15px;
  font-size: 1rem !important;
  color: #ffffff !important;
}

.annTitle {
  line-height: 24px !important;
  height: 50px;
  overflow: hidden;
  color: #ffffff !important;
  margin-bottom: 10px;
  font-size: 20px !important;
  text-decoration: none;
}

.annContents {
  overflow: hidden;
  color: #ffffff !important;
  margin-bottom: 10px;
  opacity: 0.8;
}

.annDesc {
  opacity: 0.8;
  height: 161px;
  overflow: hidden;
}

.readMore {
  font-size: 12px;
  color: #089DE3;
}

.MainPart {
  height: 600px;
  width: 100%;
  background-color: white;
  margin-left: 20px;
  padding-right: 10px;
  border-top-right-radius: 9px;
}

.m30 {
  margin: 30px;
}

.DataGrid {
  border: none !important;
}

.Section {
  height: auto;
  width: 100%;
  background-color: #d1eae33b;
  padding-right: 55px;
  border-top-right-radius: 9px;
}

.upperPart {
  display: flex;
  justify-content: space-between;
  /* margin: 30px; */
  padding-top: 30px;
  align-items: center;
}

.Announcement {
  color: #02354d;
  font-size: 16px;
  font-weight: 600;
}

.new {
  background-color: #009BAD;
  border-radius: 5px;
}

.create {
  background-color: #423E44  !important;
  color: white !important;
  padding: 6px 12px !important;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.plus {
  text-align: center;
  margin-top: 5px;
  margin-right: 10px;
  color: white !important;
}

.quicklink-drawer {
  padding: 20px;
  width: 30%;
}

.dropZone {
  padding: 20px;
  text-align: center;
  border: 3px dashed #eeeeee;
  color: #bdbdbd;
  background-color: #fafafa;
  margin-bottom: 20px;
}

.newItem {
  text-align: center;
  color: #02354d !important;
  font-weight: 500;
  font-size: 22px !important;
  margin-top: 10px !important;
}
.quickpreview-heading {
  text-align: center;
  color: #02354d !important;
  font-weight: 500;
  font-size: 22px !important;
  margin-top: 20px !important;
  margin-bottom: 20px;
}
.label {
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.label2 {
  display: flex;
  align-items: center;
}

.subHeading {
  font-size: 12px !important;
  color: #606C74 !important;
  font-weight: 500;
}

.subHeadingimg {
  width: 15px;
  margin-right: 8px;
}

.previewHeading {
  font-size: 20px !important;
  color: #333333 !important;
  font-weight: 500;
  margin-top: 20px !important;
}

.previewParagraph {
  font-size: 13px !important;
  color: #606C74 !important;
}

.bigPaper {
  box-shadow: 10px 1px 30px -10px #c2bcbc;
  border-radius: 20px;
  background-color: #d1eae33b;
}

.sideBar {
  height: 100%;
  background-color: #02354d;
  text-align: center;
  border-radius: 20px 1px 1px 20px;
}

.span {
  color: gray;
}

.file {
  color: black;
  background-color: black;
}

.iconDiv {
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin: 15px;
}

.iconView {
  display: flex;
  justify-content: space-between;
  width: 50px;
}

.actionPart {
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin-right: 350px;
}

.saveBtn {
  background-color: #009BAD !important;
  color: white !important;
  text-decoration: none !important;
  font-size: 14px !important;
}

.cancelBtn {
  background-color: #606C74 !important;
  color: white !important;
  font-size: 14px !important;
}

.actionDivTwo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  margin: 40px 0px;
}

.titleIcon {
  width: 13px;
  margin-right: 5px;
}

.checkLike {
  width: 15px;
  margin-right: 15px;
}

.dialogTitle {
  display: flex;
  justify-content: space-between;
  padding: 0px;
}

.backgroundImage {
  text-align: center;
  border-radius: 8px;
  width: 100%;
  height: 300px;
  margin-top: 10px !important;
}

.dialogBtn {
  color: #606C74;
  font-size: 12px;
}

.svg {
  cursor: pointer;
  border: 1px dashed gray;
  border-radius: 5px;
  padding: 20px;
  height: auto;
  align-items: center;
}


.QlBreadcrumbs {
  display: flex;
  font-size: 13px;
  position: relative;
  left: 14px;
  bottom: 0px;
  gap: 10px;
  font-weight: 600 !important; 
}

.QlBreadcrumbs1 {
  color: rgba(125, 125, 125, 1);
}

.QlBreadcrumbs2 {
  color: #009BAD;
}
.QlArrowIcon{
width: 7px;
height: 14px;
}
.QlpreviewHeading{
  color:var(--blackprimary) !important;
}
.QuicklinksDescription-style2{
  color: rgba(125, 125, 125, 1);
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  /* white-space nowrap; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-left: 34px;
}
/* .css-i4bv87-MuiSvgIcon-root {
  width: 17px !important;
} */