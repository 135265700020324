body {
  margin: 0;
  font-family: Calibri Regular !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f1f1f1;
}

code {
  font-family: Calibri Regular !important;
}
