/* Reset and additional styles may be needed based on your project requirements */
/* eslint-disable */

.footer {
  background-color: White;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  padding: 5px 50px;
  /* height: 47px; */
}
.footer-layout {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
  z-index: 999;
  user-select: none;
}

.footerLeft p {
  color: var(--redprimary);
  font-size: 12px;
  margin: 0;
  /* font-family: roboto; */
}

/* Responsive styles for screens with max width of 768px */
/* @media (max-width: 768px) {
    .footer {
      margin-top: 85px;
    }
  } */
