.App {
  font-family: Calibri Regular !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.Main {
  height: 100%;
}

.MuiTypography-root,
.MuiDataGrid-cell,
.MuiDataGrid-root,
.MuiList-root {
  font-family: "Calibri Regular" !important; /* Use !important to override Material-UI defaults */
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
::-webkit-scrollbar {
  height: 6px !important;
  width: 6px !important;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #90a4ae;
  border-radius: 10px;
}
:root {
  --redprimary: #d20f3d !important;
}
:root {
  --blackprimary: #423e44 !important;
}
:root {
  --greenprimary: #179949 !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  padding: 7.5px 14px;
  font-size: 12px !important;
}

.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  height: auto;
  min-height: 1.4375em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px !important;
  color: #a0a096 !important;
}
.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  font-size: 12px !important;
  /* padding: 16.5px 14px; */
}
.contenteditorheader {
  /* color: #01375b; */
  color: #d20f3d;
  font-size: 18px !important;
  font-weight: 600;
  white-space: nowrap;
}
.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus-within {
  outline: none !important;
}
.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 6px;
}
