.mainContainer {
  transition: width 0.3s ease-in-out;
  max-width: 100% !important;
  height: 100% !important;
  cursor: pointer;
  background-color: transparent;
  padding: 5px 5px 5px 10px;
}
.useFull-new-text {
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 2px !important;
  color: #ffffff !important;
  /* font-weight: 700 !important; */
  padding: 20px 10px 10px 5px !important;
}
.mainMenuContainer {
  transition: width 0.3s ease-in-out;
  max-width: 100% !important;
  height: 100% !important;
  cursor: pointer;
  background-color: var(--redprimary);
  padding: 10px;
}
.icons-new-use {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  margin-right: 10px;
  transition: color 0.3s;
  position: relative;
}
.label-new-link {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  float: left;
  transition: color 0.3s;
}
.useFulldiv {
  display: flex;
  flex-direction: row;
  padding: 5px;
  margin-top: 10px;
  /* width: 150px; */
  border-radius: 4px !important; /* Add the border-radius */
  transition: background-color 0.3s, color 0.3s;
}
.useFulldiv:hover {
  background-color: #f3f3f3;
  /* border-radius: 4px; */
}

.useFulldiv:hover .icons-new-use {
  color: var(--redprimary);
}

.useFulldiv:hover .label-new-link {
  color: var(--redprimary);
}
.icons-new-use img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease-in-out;
}

.icons-new-use img:last-child {
  opacity: 0;
}

.useFulldiv:hover .icons-new-use img:first-child {
  opacity: 0;
}

.useFulldiv:hover .icons-new-use img:last-child {
  opacity: 1;
}
