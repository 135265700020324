.root {
    max-width: 100%;
    background: #e0001b ;
    color: #ffffff !important;
    height: 315px;
  }
  
  /* BirthdayContentpage.tsx css */
  .ceocontentbigPaper {
    box-shadow: 10px 1px 30px -10px #c2bcbc;
    border-radius: 20px;
    background-color: #d1eae33b;
  }
  
  .ceocontenteditorBox {
    margin: 30px;
  }
  
  .error {
    color: red;
    font-size: 12px;
  }
  
  .media {
    height: 0;
    padding-top: 56.25%; /* 16:9 */
  }
  
  .expand {
    transform: rotate(0deg);
    margin-left: auto;
  }
  
  .expandOpen {
    transform: rotate(180deg);
  }
  
  .avatar {
    background-color: #f44336; /* red[500] in MUI */
  }
  
  .annHeader {
    padding-left: 16px;
    padding-top: 15px;
    font-size: 1rem !important;
    color: #ffffff !important;
  }
  
  .annTitle {
    line-height: 24px !important;
    height: 50px;
    overflow: hidden;
    color: #ffffff !important;
    margin-bottom: 10px;
    font-size: 20px !important;
    text-decoration: none;
  }
  
  .annTitle a {
    color: #ffffff !important;
    text-decoration: none;
  }
  
  .annContents {
    overflow: hidden;
    color: #ffffff !important;
    margin-bottom: 10px;
    opacity: 0.8;
  }
  
  .annContents a {
    color: #ffffff !important;
    text-decoration: none;
  }
  
  .annContents a:focus {
    color: #ffffff !important;
  }
  
  .annContents a:hover {
    color: #ffffff !important;
  }
  
  .annDesc {
    opacity: 0.8;
    height: 161px;
    overflow: hidden;
  }
  
  .readMore {
    font-size: 12px;
    color: #089de3;
  }
  
  .CeoMainPart {
    height: 600px;
    width: 100%;
    /* background-color: white; */
    margin-left: 20px;
    padding-right: 10px;
    /* border-top-right-radius: 9px; */
    border-radius: 10px;
    margin-bottom: 1px !important;
    margin-top: -28px !important;
  }
  
  .DataGrid {
    border: none !important;
  }
  .DepartQuicklinksHeaderStack{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    padding-top: 10px;
    gap: 20px;
  }
  
  
  .DepartQuicklinksHeader {
    color: #01375b;
    font-size: 18px !important;
    font-weight: 600;
    white-space: nowrap;
  }
  
  .new {
    background-color: #009bad;
    border-radius: 5px;
  }
  
  .new MuiButton {
    color: white !important;
    background-color: #009bad;
  }
  
  .create {
    color: white !important;
    padding: 6px 12px !important;
  }
  
  .flexBetween {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
  
  .plus {
    text-align: center;
    margin-top: 5px;
    margin-right: 10px;
    color: white !important;
  }
  
  /* .newPosOfDialog {
    padding: 20px;
    width: 30% !important;
  } */
  
  .dropZone {
    padding: 20px;
    text-align: center;
    border: 3px dashed #eeeeee;
    color: #bdbdbd;
    background-color: #fafafa;
    margin-bottom: 20px;
  }
  
  .newItem {
    /* text-align: left;
    color: #02354d !important;
    font-weight: 500;
    font-size: 22px !important; */
    margin-top: 10px !important;
  }
  
  .label {
    margin-bottom: 10px;
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  
  .label2 {
    display: flex;
    align-items: center;
  }
  
  .subHeading {
    font-size: 12px !important;
    color: #606c74 !important;
    font-weight: 500;
  }
  
  .subHeadingimg {
    width: 15px;
    margin-right: 8px;
  }
  
  .previewHeading {
    /* font-size: 20px !important;
    color: #333333 !important;
    font-weight: 500; */
    margin-top: 20px !important;
  }
  
  .previewParagraph {
    font-size: 13px !important;
    color: #606c74 !important;
  }
  
  .sideBar {
    height: 100%;
    background-color: #02354d;
    text-align: center;
    border-radius: 20px 1px 1px 20px;
  }
  
  .span {
    color: gray;
  }
  
  .span .MuiInputBase-root {
    height: 40px;
    color: gray;
  }
  
  .file MuiFormControl-root MuiSvgIcon {
    color: black;
    background-color: black;
  }
  
  .iconDiv {
    display: flex;
    justify-content: space-between;
    width: 200px;
    margin: 15px;
  }
  
  .iconView {
    display: flex;
    justify-content: space-between;
    width: 50px;
  }
  
  .actionPart {
    display: flex;
    justify-content: space-between;
    width: 200px;
    margin-right: 350px;
  }
  
  .saveBtn {
    background-color: #e0001b !important;
    color: white !important;
    text-decoration: none !important;
    font-size: 14px !important;
  }
  
  .cancelBtn {
    background-color: #606c74 !important;
    color: white !important;
    font-size: 14px !important;
  }
  
  .actionDivTwo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    margin: 40px 0px;
  }
  
  .titleIcon {
    width: 13px;
    margin-right: 5px;
  }
  
  .checkLike {
    width: 15px;
    margin-right: 15px;
  }
  
  .dialogTitle {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }
  
  /* .backgroundImage1 {
    text-align: center;
    border-radius: 8px;
    width: 100%;
    height: 100%;
  } */
  
  .dialogBtn {
    color: #606c74;
    font-size: 12px;
  }
  
  .svg {
    cursor: pointer;
    border: 1px dashed gray;
    border-radius: 5px;
    padding: 20px;
    height: auto;
    align-items: center;
  }
  
  .svg input {
    color: white;
    background-color: #009bad;
  }
  
  .svg p {
    font-size: 12px;
    margin: 0;
    text-align: center;
    color: #606c74;
  }
  
  .svg span {
    display: none !important;
    font-size: 12px;
  }
  
  .svg button {
    color: white;
    background-color: #009bad;
    font-size: 12px;
    margin-top: 10px;
  }
  
  .svg img {
    width: 45px;
  }
  
  .custom-DepartQuicklinkssheader-class {
    background-color: rgba(5, 137, 152, 0.1) !important;
    font-size: 16px;
    color: rgba(125, 125, 125, 1);
    font-weight: 500;
    line-height: 21.09px;
  }
  .custom-ceoheader-class1 {
    background-color: rgba(5, 137, 152, 0.06) !important;
    /* Set header background to blue with opacity */
    color: rgba(125, 125, 125, 1) !important;
    height: 20px;
    font-size: 14px;
  }
  .custom-DepartQuicklinkscell-class {
    font-size: 14px !important;
    color: #ABABAB !important;
  }
  
  .Quicklinsdatatable {
    /* margin-top: 15px;*/
    max-width: fit-content; 
  
    box-shadow: none;
    height: 100% !important;
    /* border: 1px solid red; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* overflow-x: auto;
    margin-left: 15px; */
    /* margin-right: 20px !important; */
    /* background-color: rgb(255, 255, 255 ) !important; */
    /* Enable horizontal scrolling for small screens */
    border-radius: 10px;
  }
  .ceoDescriptionstyle {
    color: rgba(125, 125, 125, 1);
    overflow: hidden;
    font-size: 14px;
    text-overflow: ellipsis;
    /* white-space nowrap; */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* position: relative;
    line-height: 150%;
    left: 36px;
    margin-right: 102px; */
  }
  
  .even-row {
    background-color:rgba(249, 249, 249, 1)  !important;
  }
  
  .odd-row {
    background-color: rgba(
      255,
      255,
      255,
      1
    ) !important; /* Red color for odd rows */
  }
  
  .DepartQuicklinksBreadcrumbs {
    display: flex;
    font-size: 13px;
    gap: 10px;
    font-weight: 600 !important;
    margin-bottom: 10px;
  }
  
  /* .DepartQuicklinksBreadcrumbs1 {
    font-size: 14px !important;
    color: #333333 !important;
    text-transform: uppercase !important;
    opacity: 0.8;
    font-weight: 500;
  } */
  
  /* .DepartQuicklinksBreadcrumbs2 {
    font-size: 14px !important;
    color: #009bad !important;
    text-transform: uppercase !important;
    font-weight: 500;
  } */
  .CeoArrowIcon {
    width: 7px;
    height: 14px;
  }
  
  .backgroundImage1 {
    margin-top: 20px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center !important;
    /* Align the image to the center */
    border-radius: 8px !important;
    /* border: 1px solid; */
    /* text-align: center; */
    /* border-radius: 8px; */
    width: 100% !important;
    height: 200px !important;
    width: auto;
    /* Let the browser calculate the width to maintain aspect ratio */
    height: auto;
    /* Let the browser calculate the height to maintain aspect ratio */
    max-width: 70% !important;
    /* Limit the maximum width of the image */
    max-height: 228px !important;
    /* Limit the maximum height of the image */
    display: block;
    /* Ensure it behaves like a block element */
  }
  
  /* Adjust size for smaller devices */
  @media screen and (max-width: 768px) {
    .backgroundImage {
      max-width: 90%;
      /* Reduce max-width for smaller devices */
    }
  }
  
  /* Adjust size for even smaller devices */
  @media screen and (max-width: 480px) {
    .backgroundImage {
      max-width: 100%;
      /* Reduce max-width further for even smaller devices */
    }
  }
  .ceoPreviewDescription {
    font-size: 13px !important;
    color: #606c74 !important;
  }
  .previewCeoTitle {
    color: #009bad !important;
    justify-content: center !important;
    display: flex !important;
    margin-top: 20px !important;
    font-size: 20px !important;
    margin-bottom: 20px !important;
  }
  .ceoPreviewDescription {
    font-size: 13px !important;
    color: #606c74 !important;
  }
  .previewCeoTitle {
    color: #009bad !important;
    justify-content: center !important;
    display: flex !important;
    margin-top: 20px !important;
    font-size: 20px !important;
    margin-bottom: 20px !important;
  }
  .AboutUsplusIcon1 {
    width: 17px !important;
    height: 14px !important;
  }
  .AboutUsaddevents {
    /* background-color: rgb(2, 53, 77);
    color: white; */
    height: 44px;
    position: relative;
    right: 10px;
    margin-top: -22px;
    margin-right: -30px;
    margin-left: -10px;
  }
  .previewHeading {
    /* font-size: 20px !important; */
    /* color: #333333 !important; */
     margin-top: 20px !important;
    justify-content: center;
    display: flex;
    color: #009bad !important;
  }
  /* .previewParagraph { */
    /* font-size: 13px !important;
    color: #606c74 !important; */
    /* display: flex;
    justify-content: center;
  } */