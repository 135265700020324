.linkCard {
  background-color: #ffffff;
  width: 100%;
  border-radius: 8px;
  height: 180px;
  padding: 15px;
}
.headerText {
  color: #e0001b !important;
  font-size: 15px !important;
  font-weight: bold !important;
  text-align: left;
  /* margin-bottom: 10px !important; */
}
.useCard {
  width: 150px;
  height: 60px;
  background-color: #fdebed;
  border-radius: 8px;
  padding: 5px;
}

.all-card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.icons-use {
  /* color: #e0001b; */
  color: #6c6d70;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  transition: color 0.3s;
}

.usesIcon {
  color: #6c6d70;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.3s;
}

/* .usesIcon:hover {
  color: #e0001b;
 padding: 4px;
  width: 20px;
  height: 20px;
  border-radius: 5px; 
} */
.label-link {
  color: #9a9b9d;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  float: left;
  transition: color 0.3s;
}

.useFull-div {
  display: flex;
  flex-direction: row;
  padding: 5px;
  margin-top: 10px;
  width: 150px;
  transition: background-color 0.3s, color 0.3s;
}
.useFull-div:hover {
  background-color: #f3f3f3;
}

/* .useFull-div:hover .icons-use {
  color: white;
}

.useFull-div:hover .label-link {
  color: white;
} */

/* @media only screen and (max-width: 768px) {
  .all-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .linkCard {
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 1120px) {
  .all-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .linkCard {
    height: auto;
  }
} */
.usefull-tooltip .tooltip {
  background-color: white !important;
  color: #e0001b !important;
  font-size: 12px !important;
  border: 1px solid #dadde9 !important;
}

.usefull-arrow::before {
  color: black !important;
}
.mainContainer {
  transition: width 0.3s ease-in-out;
  max-width: 100% !important;
  height: 100% !important;
  cursor: pointer;
  background-color: transparent;
  padding: 5px 5px 5px 10px;
}

.menu-container {
  width: 40px;
  background-color: #ffffff;
}

.links-lists {
  background-color: #ffffff;
  color: #e0001b;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}
.links-lists :hover {
  background-color: #e0001b !important;
  color: #ffffff !important;
  padding: 8px;
}

.quick-links-container {
  width: 200px;
  background-color: #ffffff;
}
.useFull-link {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  margin-top: 10px;
  transition: background-color 0.3s, color 0.3s;
}
.useFull-link:hover {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 3px;
  margin-top: 10px;
  transition: background-color 0.3s, color 0.3s;
  /* width: 30px;
  height: 40px; */
  border-radius: 5px;
  /* margin: auto; */
}
.useFull-text {
  font-size: 15px !important;
  color: #b6b7ba !important;
  font-weight: 700 !important;
  padding: 20px 10px 10px 10px !important;
}

@media (max-width: 1120px) {
  .useCard {
    width: 90px;
    height: 60px;
    background-color: #fdebed;
    border-radius: 8px;
    padding: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .useCard {
    width: 220px;
    height: 60px;
    background-color: #fdebed;
    border-radius: 8px;
    padding: 5px;
  }
}
@media only screen and (max-width: 425px) {
  .useCard {
    width: 90px;
    height: 60px;
    background-color: #fdebed;
    border-radius: 8px;
    padding: 5px;
  }
}
@media only screen and (max-width: 320px) {
  .useCard {
    width: 70px;
    height: 60px;
    background-color: #fdebed;
    border-radius: 8px;
    padding: 5px;
  }
}
.viewallusefullHeader {
  color: #009bad !important;
  font-size: 12px !important;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  margin-right: 20px;
  border: none; /* Remove border */
  background: none; /* Remove background */
}
.DrawerheaderText {
  color: #e0001b !important;
  font-size: 15px !important;
  font-weight: bold !important;
  text-align: left;
  margin-bottom: 10px !important;
  margin-top: 12px !important;
}
.DraweruseCard {
  width: 120px;
  height: 60px;
  background-color: #fdebed;
  border-radius: 8px;
  padding: 5px;
}
.Drawericons {
  color: #e0001b;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.DrawerlabelD {
  color: #e0001b;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Drawerall-card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
/* .cancelicon{
  padding-bottom: 10px !important;
} */
