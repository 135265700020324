.PoliciesMainPart1 {
  height: 600px;
  width: 104%;
  /* background-color: white; */
  margin-left: 20px;
  padding-right: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.SectionPolicies {
  padding-right: 55px;
  cursor: pointer;
  user-select: none;
}

.upperPart1 {
  display: flex;
  justify-content: space-between;
  /* margin: 30px; */
  /* padding-top: 30px; */
  align-items: center;
}

.policiesContenHeader {
  color: #02354d;
  font-size: 16px;
  font-weight: 600;
}

.newFolder {
  background-color: #e0001b !important;
  border-radius: 5px !important;
  color: white !important;
  padding: 6px 12px !important;
  position: relative;
}
.PolicyArrowIcon {
  /* width: 7px;
  height: 14px; */
  color: #e0001b !important;
  /* margin: -2px; */
  height: 23px;
  width: 20px;
}

.upload {
  background-color: #e0001b !important;
  border-radius: 5px !important;
  color: white !important;
  padding: 6px 12px !important;
  position: relative;
}

/* .newPosOfDialog {
  padding: 20px;
  width: 40%;
} */

.newItem {
  text-align: left;
  color: #02354d !important;
  font-weight: 500;
  font-size: 22px !important;
  margin-top: 10px !important;
}

.label {
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.subHeading {
  font-size: 12px !important;
  color: #606C74 !important;
  font-weight: 500;
}

.span {
  color: gray;
}

.span .MuiInputBase-root {
  height: 40px;
  color: gray;
}

.saveBtn {
  background-color: #009BAD !important;
  color: white !important;
  text-decoration: none !important;
  font-size: 14px !important;
}

.actionDivTwo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  margin: 40px 0px;
}

.dialogTitle {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.documentTypeWrapper {
  display: flex;
  align-items: center;
}

.reduced-width {
  width: 50% !important;
}

.manual-document-type {
  margin-left: 10px !important;
  flex-grow: 1;
  top: -5px;
  width: 360px !important;
}
.policiescontenteditorBox {
  margin: 30px;
}

.folderPathComponent{
  font-size: small !important;
}
.addpolicy{
 
    color: white;
    height: 40px;
    margin-left: 38px !important;
    margin-top: -27px !important;
}
.plusIcon{
    background-color: #e0001b;
    width: 112%;
    margin-left: -25px;
    margin-top: -30px;
}
.even-row {
  background-color: rgba(249, 249, 249, 1) !important;
}

.odd-row {
  background-color: rgba(255, 255, 255, 1) !important;
  /* Red color for odd rows */
}

.ByrnePost-table1 {
  /* max-width: fit-content; */
  margin-top: 15px;
  /* margin-top: 15px;
  max-width: fit-content;
  /* Set a maximum width */
  /* width: 100%; */
  /* Ensure it takes full width of its container */
  /* padding: 20px; */
  /* border-radius: 20px; */
  /* box-shadow: 10px !important; */
  box-shadow: none;
  height: 430px !important;
  /* border: 1px solid red; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow-x: auto;
  background-color: white;
  /* margin-left: -14px; */
  /* margin-right: 20px !important; */
  /* Enable horizontal scrolling for small screens */
  
}
.css-1iyq7zh-MuiDataGrid-columnHeaders {
  max-height: 45px !important;

  min-height: 45px !important;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid;
  border-top-left-radius: var(--unstable_DataGrid-radius);
  border-top-right-radius: var(--unstable_DataGrid-radius);
}
.css-184cyof-MuiDataGrid-root {
  min-height: auto;
  --unstable_DataGrid-radius: 4px;
  --unstable_DataGrid-headWeight: 500;
  --unstable_DataGrid-overlayBackground: rgba(255, 255, 255, 0.38);
  --DataGrid-cellOffsetMultiplier: 2;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  box-sizing: border-box;
  position: relative;
  /* border-width: 1px; */
  border-style: none !important;
  border-color: none !important;
  border-radius: var(--unstable_DataGrid-radius);
  color: #333333;
  font-family: Calibri Regular !important;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  outline: none;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  min-height: 0;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-anchor: none;
}

.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
  padding-left: 0;
  font-size: 12px !important;
}
/* .mainTable {
  margin-left: 2px;
  margin-right: 3px;
} */
.TableHeader {
  /* background-color: #e0001b0d !important; */
  /* font-size: 14px; */
  /* color: #e0001b; */
  /* color: #707073; */
  font-weight: 500;
  /* line-height: 21.09px; */
  font-size: 14px !important;
  color: #707073 !important;
  font-weight: 600 !important;
  line-height: 21.09px;
}

/* .EventsTableHeader .MuiDataGrid-columnHeaderTitleContainer {
  margin-bottom: 15px;
} */
.ByrnePostTableCell {
  font-size: 14px !important;
  color: #ababab !important;
  /* background-color: rgba(255, 255, 255, 1) !important; */
}


.MuiInputBase-input.MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: border-box;
  background: none;
  /* height: 0.4375em !important; */
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100% !important;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
   padding: 16.5px 14px;
  padding-right: 0;
  font-size: 14px !important;
}