.root {
  max-width: 100%;
  background: #e0001b !important;
  color: #ffffff !important;
  height: 315px;
}


/* BirthdayContentpage.tsx css */
.ceocontentbigPaper {
  box-shadow: 10px 1px 30px -10px #c2bcbc;
  border-radius: 20px;
  background-color: #d1eae33b;
}

.ceocontenteditorBox {
  margin: 30px;
}

.error {
  color: red;
  font-size: 12px;
}

.media {
  height: 0;
  padding-top: 56.25%;
  /* 16:9 */
}

.expand {
  transform: rotate(0deg);
  margin-left: auto;
}

.expandOpen {
  transform: rotate(180deg);
}

.avatar {
  background-color: #f44336;
  /* red[500] in MUI */
}

.annHeader {
  padding-left: 16px;
  padding-top: 15px;
  font-size: 1rem !important;
  color: #ffffff !important;
}

.annTitle {
  line-height: 24px !important;
  height: 50px;
  overflow: hidden;
  color: #ffffff !important;
  margin-bottom: 10px;
  font-size: 20px !important;
  text-decoration: none;
}

.annTitle a {
  color: #ffffff !important;
  text-decoration: none;
}

.annContents {
  overflow: hidden;
  color: #ffffff !important;
  margin-bottom: 10px;
  opacity: 0.8;
}

.annContents a {
  color: #ffffff !important;
  text-decoration: none;
}

.annContents a:focus {
  color: #ffffff !important;
}

.annContents a:hover {
  color: #ffffff !important;
}

.annDesc {
  opacity: 0.8;
  height: 161px;
  overflow: hidden;
}

.readMore {
  font-size: 12px;
  color: #089DE3;
}

.HeroBannerMainPart {
  height: 601px;
  width: 101%;
  background-color: white;
  margin-left: 20px;
  padding-right: 10px;
  border-radius: 10px;
  margin-bottom: 1px !important;
  margin-top: -28px !important;
}


.DataGrid {
  border: none !important;
}

.HeroBannerSection {
  /* height: auto;
    width: 100%;
    background-color: #d1eae33b; */
  padding-right: 65px;
  /* border-top-right-radius: 9px; */
}

.upperPart {
  display: flex;
  justify-content: space-between;
  /* margin: 30px; */
  padding-top: 30px;
  align-items: center;
}

.ceocontenHeader {
  color: #02354d;
  font-size: 16px;
  font-weight: 600;
}

.new {
  background-color: #e0001b;
  border-radius: 5px;
}

.new MuiButton {
  color: white !important;
  background-color: #e0001b;
}

.create {
  color: white !important;
  padding: 6px 12px !important;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.plus {
  text-align: center;
  margin-top: 5px;
  margin-right: 10px;
  color: white !important;
}

/* .newPosOfDialog {
  padding: 20px;
  width: 40%;
} */

.dropZone {
  padding: 20px;
  text-align: center;
  border: 3px dashed #eeeeee;
  color: #bdbdbd;
  background-color: #fafafa;
  margin-bottom: 20px;
}

.newItem {
  text-align: left;
  color: #02354d !important;
  font-weight: 500;
  font-size: 22px !important;
  margin-top: 10px !important;
}

.label {
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.label2 {
  display: flex;
  align-items: center;
}

.subHeading {
  font-size: 12px !important;
  color: #606C74 !important;
  font-weight: 500;
}

.subHeadingimg {
  width: 15px;
  margin-right: 8px;
}

.HeroBannerpreviewHeading {
  font-size: 20px !important;
  /* color: #333333 !important; */
  font-weight: 500;
  margin-top: 20px !important;
  justify-content: center;
  display: flex;
  color: #e0001b !important;
}

.previewParagraph {
  font-size: 13px !important;
  color: #606C74 !important;
  /* display: flex; */
  /* justify-content: center; */
}



.sideBar {
  height: 100%;
  background-color: #02354d;
  text-align: center;
  border-radius: 20px 1px 1px 20px;
}

.span {
  color: gray;
}

.span .MuiInputBase-root {
  height: 40px;
  color: gray;
}

.file MuiFormControl-root MuiSvgIcon {
  color: black;
  background-color: black;
}

.iconDiv {
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin: 15px;
}

.iconView {
  display: flex;
  justify-content: space-between;
  width: 50px;
}

.actionPart {
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin-right: 350px;
}

.saveBtn {
  background-color: #e0001b !important;
  color: white !important;
  text-decoration: none !important;
  font-size: 14px !important;
}

.cancelBtn {
  background-color: #606C74 !important;
  color: white !important;
  font-size: 14px !important;
}

.actionDivTwo {
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  gap: 30px;
  margin: 40px 0px;
  justify-content: flex-end !important;
}
.actionDivTwo12{
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  align-items: center;
  margin: 40px 0px; /* Adjust margin as needed */
}
.titleIcon {
  width: 13px;
  margin-right: 5px;
}

.checkLike {
  width: 15px;
  margin-right: 15px;
}

.dialogTitle {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.HeroBannerbackgroundImage {
  margin: 15px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center !important;
  /* Align the image to the center */
  border-radius: 8px !important;
  /* border: 1px solid; */
  /* text-align: center; */
  /* border-radius: 8px; */
  width: 100% !important;
  height: 200px !important;
  width: auto;
  /* Let the browser calculate the width to maintain aspect ratio */
  height: auto;
  /* Let the browser calculate the height to maintain aspect ratio */
  max-width: 70% !important;
  /* Limit the maximum width of the image */
  max-height: 228px !important;
  /* Limit the maximum height of the image */
  display: block;
  /* Ensure it behaves like a block element */
}
.HeroBannerbackgroundImage1 {
  margin: 10px;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center !important;
  /* Align the image to the center */
  border-radius: 8px !important;
  /* border: 1px solid; */
  /* text-align: center; */
  /* border-radius: 8px; */
  width: 100% !important;
  height: 200px !important;
  width: auto;
  /* Let the browser calculate the width to maintain aspect ratio */
  height: auto;
  /* Let the browser calculate the height to maintain aspect ratio */
  max-width: 70% !important;
  /* Limit the maximum width of the image */
  max-height: 228px !important;
  /* Limit the maximum height of the image */
  display: block;
  /* Ensure it behaves like a block element */
}
/* Adjust size for smaller devices */
@media screen and (max-width: 768px) {
  .backgroundImage {
    max-width: 90%;
    /* Reduce max-width for smaller devices */
  }
}

/* Adjust size for even smaller devices */
@media screen and (max-width: 480px) {
  .backgroundImage {
    max-width: 100%;
    /* Reduce max-width further for even smaller devices */
  }
}

.dialogBtn {
  color: #606C74;
  font-size: 12px;
}

.svg {
  cursor: pointer;
  border: 1px dashed gray;
  border-radius: 5px;
  padding: 20px;
  height: auto;
  align-items: center;
}

.svg input {
  color: white;
  background-color: #e0001b;
}

.svg p {
  font-size: 12px;
  margin: 0;
  text-align: center;
  color: #606C74;
}

.svg span {
  display: none !important;
  font-size: 12px;
}

.svg button {
  color: white;
  background-color: #e0001b;
  font-size: 12px;
  margin-top: 10px;
}

.svg img {
  width: 45px;
}






/* .heroBannerDescription-style1{
    
  } */
.heroBannerDescription-style2 {
  color: rgba(125, 125, 125, 1);
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  /* white-space nowrap; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 2; */

}

.NewTableHeader {
   font-size: 14px !important;
   color: #423E44 !important;
   font-weight: 600 !important;
   line-height: 21.09px;
}
/* .HeroBannerTableHeader .MuiDataGrid-columnHeaderTitleContainer {
  margin-bottom: 15px;
} */

.HeroBannerTableCell {
  font-size: 14px;
  color: #ABABAB !important;
}

.custom-data-grid .MuiDataGrid-colCellTitle {
  height: 30px;
  /* Adjust the height as per your requirement */
}

.heroBannerData-table1 {
  /* margin-top: 10px; */
  /* max-width: fit-content; */
  /* Set a maximum width */
  /* width: 100%; */
  /* Ensure it takes full width of its container */
  /* padding: 20px; */
  /* border-radius: 20px; */
  /* box-shadow: 10px !important; */
  box-shadow: none;
  box-shadow: 0px 0px 0px 0px #c2bcbc !important;
  height: 430px !important;
  /* border: 1px solid red; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow-x: auto;
  margin-top: 15px;
  background-color: white;

  /* overflow-x: auto;
  margin-left: 15px; */
  /* margin-right: 20px !important; */
  /* background-color: rgba(248, 246, 246, 1); */
  /* Enable horizontal scrolling for small screens */
}


.css-1iyq7zh-MuiDataGrid-columnHeaders {
  max-height: 50px;

  min-height: 50px;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid;
  border-top-left-radius: var(--unstable_DataGrid-radius);
  border-top-right-radius: var(--unstable_DataGrid-radius);
}

.css-184cyof-MuiDataGrid-root {
  min-height: auto;
  --unstable_DataGrid-radius: 4px;
  --unstable_DataGrid-headWeight: 500;
  --unstable_DataGrid-overlayBackground: rgba(255, 255, 255, 0.38);
  --DataGrid-cellOffsetMultiplier: 2;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  box-sizing: border-box;
  position: relative;
  /* border-width: 1px; */
  border-style: none !important;
  border-color: none !important;
  border-radius: var(--unstable_DataGrid-radius);
  color: #333333;
  font-family: Calibri Regular !important;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  outline: none;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  min-height: 0;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-anchor: none;

}

.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
  padding-left: 0;
  font-size: 12px !important;
}

.even-row {
  background-color: rgba(249, 249, 249, 1) !important 
  /* Blue color for even rows */
}

.odd-row {
  background-color: rgba(255, 255, 255, 1) !important;
  /* Red color for odd rows */
}

.css-1621746-MuiDataGrid-root .MuiDataGrid-withBorderColor {
  border-color: snow !important;
}

.HeroaddUser1 {
  display: flex;
  justify-content: left;
  line-height: 1.334 !important;
  margin-left: 12px !important;
  font-size: 1rem !important;
  margin-top: 0px !important;
  gap: 10px !important;
  position: relative;
  top: 25px;
  /* align-items: left; */
  /* background-color: #009BAD; */
}

.HeroplusIcon1 {
  width: 17px !important;
  height: 14px !important;
}

.Heroaddevents {
  background-color:#e0001b;
  color: white;
  height: 56px;
  position: relative;
  right: 10px;
  margin-top: -41px !important;
  margin-right: -35px;
  margin-left: -17px;
}

.HeroaddUser2 {
  display: flex;
  justify-content: left;
  line-height: 1.334 !important;
  margin-left: 14px !important;
  font-size: 1rem !important;
  margin-top: 0px !important;
  gap: 10px !important;
  position: relative;
  top: 23px;
  /* align-items: left; */
  /* background-color: #009BAD; */
}

.Heroaddevents2 {
  background-color: #e0001b;
  color: white;
  height: 56px;
  position: relative;
  right: 10px;
  margin-top: -36px !important;
  margin-right: -30px;
  margin-left: -10px;
  margin-bottom: 32px;
}

.Breadcrumbs {
  display: flex;
  font-size: 13px;
  position: relative;
  left: 31px;
  bottom: 19px;
  gap: 10px;
  font-weight: 600 !important;

}

.Breadcrumbs1 {
  color: rgba(125, 125, 125, 1);
}

.Breadcrumbs2 {
  color: #e0001b;
}

.ArrowIcon {
  width: 7px;
  height: 14px;
}

.HeroBannerbackgroundVideo {
  width: 30%;
  /* height: auto !important;
  position: relative !important;
  right: 39px !important;
  top: 4px !important; */
}
.imageAndVideohyphen{
  position:relative;
  left:13px;
  color: black;
}
.HeropreviewParagraph {
  font-size: 13px !important;
  color: #606C74 !important;
}
.herobannerHeaderStack {
 
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  padding-top: 10px;
  gap: 20px;
}
.HeroBannerheader {
  /* color: #01375b; */
  color: #D20F3D;
  font-size: 18px !important;
  font-weight: 600;
  white-space: nowrap;
}
.Tablebreadcrumb {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.Tablebreadcrumb1 {
  font-size: 14px !important;
  color: #7D7D7D !important;
  text-transform: capitalize !important;
  opacity: 0.8;
  font-weight: 500;
}
.Tablebreadcrumb2 {
  font-size: 14px !important;
  color:var(--greenprimary)!important;
  text-transform: capitalize !important;
  font-weight: 500;
}
.Tablebreadcrumbarrow-icon {
  margin: 5px 0px 0 0px !important;
  color: #7c7f82 !important;
}
.ql-editor::before {
  font-size: 14px !important;
  /* color: #999;  */
}
.ReadmoreherobannerDesc{
  color: #605D62;
}
.ReadmoreherobannerDesc img {
  max-width: 70%;
  max-height: 300px;
  /* display: block; */
  margin: 0 auto;
  border-radius: 10px;
}
.ReadmoreherobannerDesc video {
  max-width: 70%;
  max-height: 300px;
  /* display: block; */
  margin: 0 auto;
  border-radius: 10px;
}