.newsCard {
  background-color: #ffffff;
  width: 100% !important;
  border-radius: 8px;
  /* height: 250px; */
  padding: 15px;
  /* margin-top: 16px; */
  margin-bottom: 16px;
  margin-right: 10px;
}
.textDiv {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
}
.Header {
  color:var(--redprimary);
  font-size: 16px;
  font-weight: 600 !important;
  font-family: "Calibri Regular";
}
.NewPostHeader {
  color:var(--redprimary);
  font-size: 16px;
  font-weight: 600 !important;
  font-family: "Calibri Regular";
  margin-left: -14px !important;

}
.errorNews {
  color: #8a9198;
  font-size: 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Calibri Regular";
}
.selected-label {
  /* background-color: #fdf0f1; */
  font-size: 12px !important;
  color: #8c8c8f;
}
/* .viewnewsHeader {
  color: #009bad !important;
  font-size: 12px !important;
  font-weight: 600;
  text-decoration: none;
} */
.newsImage {
  width: 100%;
  /* height: 150px; */
  object-fit: cover;
  border-radius: 8px;
  margin-top: 7px;
}
.newsImageinpost {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-top: 14px;
}
.newsText {
  color: #a5a5a5 !important;
  font-size: 13px !important;
  margin-top: 15px !important;
  text-align: left;
}
.newsTitleText {
  color: grey !important;
  font-size: 15px !important;
  margin-top: 15px !important;
  text-align: left;
  font-weight: 1000 !important;
}
.timeSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}
.clock svg {
  width: 10px !important;
}
.time-text {
  font-size: 12px;
  color: #a5a5a5;
}
@media (max-width: 1024px) {
  .time-text {
    font-size: 10px;
    color: #a5a5a5;
  }
}
.timeSection svg {
  width: 15px !important;
  color: #a5a5a5;
}
.eventdate {
  display: flex;
  align-items: center;
  width: 100%;
  /* margin-top: 10px; */
  /* padding-left: 11px; */
  height: 5px;
  color: #605D62;
  font-size: 12px !important;
  margin-bottom: 5px;
  /* margin-top: 45px; */
}
@media (max-width: "768px") {
  .eventdate {
    display: flex;
    align-items: center;
    width: 100%;
    height: 5px;
    color: #605D62;
    font-size: 12px !important;
    margin-bottom: 5px;
    margin-top: 25px;
  }
}
.clock svg {
  width: 10px !important;
}

.newsHeaders {
  color: black !important;
  font-weight: 1000 !important;
  opacity: 0.6;
  font-size: 13px !important;
  text-align: left;
  padding: 12px 0px 0px 0px;
  height: 35px;
  overflow: hidden;
  margin-bottom: 0px !important;
  margin-left: 11px !important;
  /* border-bottom: 1px solid #e6e6e6; */
}
.newspost {
  width: 100% !important;
  height: 246px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  /* margin-bottom: 16px; */
  /* margin-right: 10px; */
  /* margin-left: 16px; */
  /* margin-top: 16px; */
  gap: 10px !important;
}

.newsTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* .newsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
}

.newsItem {
  position: relative;
  width: 100%;
}

.newsImage {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
} */

.newsGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
}

.newsItem {
  position: relative;
  width: 85%;
  margin-left: 10px;
  margin-right: 10px;
}

.newsImage {
  width: 100%;
  height: 200px;
  display: block;
  border-radius: 8px;
}

.navigationButtons {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
.custom-prev {
  font-size: 12px !important;
  margin-top: -125px !important;
}
.custom-next {
  font-size: 12px !important;
  margin-top: -125px !important;
}
@media (max-width: "1024px") {
  .newsItem {
    position: relative;
    height: 100px;
    width: 85%;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.paper {
  margin: 0px 0px 12px 0px !important;
  border-radius: 8px !important;
  /* margin-top: 0px !important; */
  /* margin-left: 2px; */
  /* margin-right: 2px; */
  width: 100%;
}

.ByrnenewsPaper {
  /* margin: 24px; */
  border-radius: 10px !important;
  margin-top: 16px !important;
  margin-left: 13px;
}

.background-red {
  background-color: red; /* Adjust color as needed */
}

.background-blue {
  background-color: blue; /* Adjust color as needed */
}

.background-green {
  background-color: green; /* Adjust color as needed */
}

.background-white {
  background-color: white; /* Adjust color as needed */
}

.background-yellow {
  background-color: yellow; /* Adjust color as needed */
}

/* Add more classes as per your needs */
.newsDescription1 {
  color: #6c6c6e !important;
  opacity: 0.6;
  font-size: 12px !important;
  text-align: left;
  /* padding: 12px 0px 12px 0px; */
  /* height: 58px; */
  padding-bottom: 20px;
  overflow: hidden;
  margin-bottom: 5px !important;
  /* border-bottom: 1px solid #e6e6e6; */

  padding-left: 11px !important;
  font-family: Calibri Regular !important;
}

.newsTitle1 {
  color: #6c6d70 !important;
  /* opacity: 0.7; */
  font-size: 14px !important;
  text-align: left;
  /* padding: 12px 0px 12px 0px; */
  padding-left: 11px;
  height: 25px;
  overflow: hidden;
  margin-bottom: 0px !important;
  /* border-bottom: 1px solid #e6e6e6; */
  /* margin-left: 11px !important; */
  font-weight: 600 !important;
  font-family: Calibri Regular !important;
}
.NewsReference {
  /* background-color: green; */
  color: #ffffff;
  border-radius: 5px;
  padding: 3px 10px 3px 10px;
  text-transform: capitalize !important;
  font-size: 12px !important;
  font-family: Calibri Regular !important;
}
.ByrneNewsReference {
  /* background-color: green; */
  color: #ffffff;
  border-radius: 5px;
  padding: 3px 5px 3px 5px;
  text-transform: capitalize !important;
  font-size: 12px !important;
  position: absolute;
  left: 5%;
  bottom: 10%;
  font-family: Calibri Regular !important;
  cursor: pointer;
}
.news-readmore {
  color: #6c6d70 !important;
  border: none;
  /* text-align: center; */
  font-size: 12px;
  /* width: 210px; */
  transition: all 0.2s;
  cursor: pointer;
  background: none;
  border-radius: 4px;
  padding-left: 0px;
  /* padding-top: 3px !important; */

  padding-left: 11px !important;

  text-decoration: none !important;

  font-family: Calibri Regular !important;
}
.news-readmore:hover {
  color: green !important;
  text-decoration: underline !important;
}
.createone {
  /* color: white !important; */
  padding: 4px !important;
  background-color: #ffdf00 !important;
    color: #000000 !important;
    font-size: 12px !important;
    width: 89% !important;
    height: 24px !important;
    margin-left: 11px !important;
    margin-top: 10px !important;
    font-weight: 400 !important;
}

.filterApply-btn {
  /* color: white !important; */
  padding: 4px !important;
    font-size: 12px !important;
    width: 89% !important;
    height: 24px !important;
    margin-left: 11px !important;
    margin-top: 10px !important;
    font-weight: 400 !important;
}

.css-1dixmk6-MuiCardContent-root {
  padding: 16px;
  padding-bottom: 16px !important;
  border-radius: 10px;
  height: 298px;
}
.Byrnenewsimage{
  height: 120px;
  width: 200px;
  border-radius: 8px;
  position: relative;
  object-fit: fill;
}
@media (max-width: 1024px) {
  .Byrnenewsimage {
    height: 120px;
    width: 170px !important;
    border-radius: 8px;
    position: relative;
    object-fit: fill;
  }
}
.clamped-text {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  /* height: 40px; */
}
#basic-button{
  color:grey;
     min-width: 10px !important;
    max-width: 33px !important;
    height: 40px !important;
}


.slick-prev:before {
  color: var(--blackprimary) !important;
}
.slick-next:before {
  color: var(--blackprimary) !important;
}