.employeeText {
  color: var(--redprimary) !important;
  /* margin-left: 20px !important; */
  font-weight: 600 !important;
  font-size: 14px !important;
  margin-bottom: 10px !important;
  font-family: Calibri Regular !important;
}
.basicText {
  font-size: 14px !important;
  /* font-weight: bold !important; */
  padding-bottom: 15px;
  font-family: Calibri Regular !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.detailsText {
  color: #3e3e3e !important;
  /* border-bottom: 2px solid #e0001b; */
  font-family: Calibri Regular !important;
}
.headTextNew {
  margin: auto;
  text-align: center;
  color: #2face7;
  font-size: 14px;
  /* font-weight: 600; */
  padding-top: 1px;
  padding-bottom: 1px;
}

.emp-banner {
  height: 190px;
  width: 98%;
  border-radius: 8px;
  margin: 20px;
  /* padding-right: 30px;
  padding-left: 30px; */
}
.emp-container {
  background-color: #f1f1f1;
  padding: "30px";
}

.emp-card {
  background-color: #ffffff;
  margin: 30px;
  width: 100%;
  padding: 30px;
  border-radius: 8px;
}
.managementDiv {
  background-color: #fdf0f1;
  height: 50px;
  width: 100%;
  text-align: center;
  color: var(--redprimary) !important;
  font-weight: 600;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.managementCard {
  height: 225px;
  width: 200px;
  /* border: 1px solid gray; */
  padding: 5px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.OurManagement {
  border: 1px solid #fdf0f1;
  padding: 30px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  align-items: center; /* Aligns items vertically */
  justify-items: center; /* Aligns items horizontally */
  /*
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap !important; */
}
@media only screen and (max-width: 1024px) {
  .OurManagement {
    border: 1px solid #fdf0f1;
    padding: 30px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 768px) {
  .OurManagement {
    border: 1px solid #fdf0f1;
    padding: 30px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 450px) {
  .OurManagement {
    /* Adjust your styles for screens up to 450px width here */
    /* For example: */
    padding: 20px;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (max-width: 425px) {
  .OurManagement {
    border: 1px solid #fdf0f1;
    padding: 30px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(1, 1fr);
  }
}

.management-group {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 13px;
  border: 1px solid #fdf0f1;
  padding: 30px 20px;
  max-height: 460px;
  overflow-y: auto;
}
.management-group::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px; /* Height of the scrollbar */
}
.management-group::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.management-group::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.empImage {
  width: 200px;
  height: 175px;
  border-radius: 10px;
}
.empName {
  text-align: center;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #353535;
  margin-top: 10px !important;
}
.emp-designation {
  color: #cdcdcd !important;
  font-size: 10px !important;
  text-align: center;
}

.root {
  align-items: center;
  position: relative;
  border-radius: 30px;
  /* padding-right: 14px; */
  height: 24px !important;
  width: 45px;
  padding-left: 12px;
  border-radius: 25px;
}
.root .MuiOutlinedInput-root {
  border-radius: 25px;
}
/* .css-i4bv87-MuiSvgIcon-root {
  width: 17px !important;
} */
.root .MuiInputAdornment-root {
  background-color: red !important;
  border-radius: 50%;
  color: white;
  padding: 10px;
}
/* .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  align-items: center;
  position: relative;
  border-radius: 30px;
  height: 50px;
  border-radius: 30px !important;
  padding-right: 0px !important;
} */

.employee-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  height: 60px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  cursor: pointer;
}
.emp-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.emp-sec-name {
  text-align: left;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9b9b9b;
  margin-top: 0px !important;
}
.post {
  color: #605D62 !important;
  font-size: 10px !important;
  text-align: left;
}
.org-part {
  background-color: #fdf0f1;
  width: 110px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.org-Text {
  font-size: 14px !important;
  text-align: left;
  color: #e0001b;
  font-weight: 600;
  padding-left: 8px;
}
.filter-part {
  width: 60%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  cursor: pointer;
}
.org-icon {
  color: red;
  width: 20px;
}
.filter-section {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9b9b9b;
}
.filtersection1 {
  display: flex;
  margin-top: 21px;
  color: #9b9b9b;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 425px) {
  .filtersection1 {
    display: flex;
    margin-top: 16px;
    color: #9b9b9b;
  }
}

.filters-part {
  border: 1px solid #cdcdcd;
  padding: 10px;
  margin-top: 20px;
  border-radius: 5px;
  height: 500px;
}
.branch-part {
  background-color: #fdf0f1;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin-top: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 400px;
  overflow-y: auto; 
}
.branch-part {
  scrollbar-width: none;
}
.results {
  color: #e0001b;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-top: 15px !important;
  margin-bottom: 20px !important;
}
.branch-text {
  color: #9b9b9b;
  font-size: 12px !important;
}
.add-icon {
  width: 18px !important;
}
.accordian-head {
  font-size: 12px !important;
}
.accordion-div {
  background-color: #fdf0f1 !important;
}

.accordion-summery {
  background-color: #ffffff !important;
}
.w-40 {
  /* width: 40%; */
  width: 30%;
}
.w-55 {
  /* width: 55%; */
  width: 70%;
}

@media only screen and (max-width: 768px) {
  .employeeText {
    /* margin-left: 50px !important; */
    margin-bottom: 15px;
  }
  .filter-part {
    width: 90%;
  }
  .emp-banner {
    height: 190px;
    width: 90%;
    border-radius: 8px;
    margin: 5px;
  }
}

@media (min-width: 280px) and (max-width: 499px) {
  .management-group {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    padding: 30px 20px;
  }
  .root {
    height: 13px !important;
    width: 45px;
    padding-left: 8px;
  }
  /* .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    height: 39px;
  } */
  .emp-card {
    margin: 2px;
    padding: 0px;
  }
}
@media (min-width: 500px) and (max-width: 768px) {
  .management-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    padding: 30px 20px;
    align-items: center; /* Aligns items vertically */
    justify-items: center; /* Aligns items horizontally */
  }
}
@media (min-width: 768px) and (max-width: 990px) {
  .management-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    padding: 30px 15px;
    align-items: center; /* Aligns items vertically */
    justify-items: center; /* Aligns items horizontally */
  }
}
@media (min-width: 1024px) {
  .management-group {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    padding: 30px 15px;
    align-items: center; /* Aligns items vertically */
    justify-items: center; /* Aligns items horizontally */
  }
}
@media (min-width: 1200px) and (max-width: 1324px) {
  .management-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    padding: 30px 20px;
    align-items: center; /* Aligns items vertically */
    justify-items: center; /* Aligns items horizontally */
  }
}
.SearchIcon {
  padding-top: 4px !important;
}
.DrawerHeader-emp {
  background-color: var(--redprimary);
  margin: 0px -20px;
  margin-top: -20px;
  padding: 10px 20px;
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; */
  width: 100%;
}
#new {
  background-color: white;
  width: 100%;
  text-align: left;
  font-size: 14px !important;
  color: grey;
  border-radius: 5px;
  border: 1px solid #cdcdcd;
}
.iconfilter {
  height: 15px !important;
  margin-top: 3px !important;
  cursor: pointer;
}
.FilterResults {
  font-size: 12px !important;
  cursor: pointer;
  /* margin-left: 20px !important; */
  background-color: white;
  /* margin: 13px; */
  /* width: 100%; */
  text-align: left;
  color: grey;
  border-radius: 5px;
  border: 1px solid #cdcdcd;
  padding: 5px;
  margin-top: 8px !important;
}
.FilterResults:hover {
  background-color: #fdf0f1;
  color: #e5263d;
}
.linkText {
  color: #e6354a;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  cursor: default;
  font-family: Calibri Regular !important;
}
.css-1wuw8dw-MuiBreadcrumbs-separator {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.profile {
  color: #777777;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  cursor: default;
}
.employeeBreadcrumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.employeeBreadcrumbText {
  font-size: 14px !important;
  /* color:var(--redprimary) !important; */
  color: white !important;
  opacity: 0.8;
  font-weight: 500;
  flex-wrap: nowrap !important;
  white-space: nowrap; /* Prevents text wrapping */
}
.employeeBreadcrumb2 {
  font-size: 14px !important;
  /* color: #848484 !important; */
  color: white !important;
  font-weight: 500;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expanded .truncate {
  white-space: normal;
}

.employeeBreadcrumbRowicon {
  margin: 6px 0px 0 0px !important;
  /* color:var(--redprimary) !important; */
  color: white !important;
}

.FilterResults.selected {
  /* background-color: #fdf0f1 !important; */
  background-color: #ffffff !important;
  color: #e5263d !important;
}
.panel {
  margin-top: 5px !important;
  padding: 5px 0px 5px 5px;
  /* background-color: white; */
  display: block;
}
.Employeebreadcrumb {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.Employeebreadcrumb1 {
  font-size: 14px !important;
  color: #9f9c9c !important;
  text-transform: uppercase !important;
  opacity: 0.8;
  font-weight: 500;
  text-decoration: none; /* Remove underline */
}
.Employeebreadcrumb2 {
  font-size: 12px !important;
  color: var(--redprimary) !important;
  text-transform: uppercase !important;
  font-weight: 500;
  margin-top: 1px;
}
.Employeebreadcrumbarrow-icon {
  margin: 5px 0px 0 0px !important;
  color: var(--redprimary) !important;
  padding-top: 2px !important;
  font-size: 1.3rem !important;
}
.employeefirstName {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #949494;
  opacity: 0.8;
}
@media (max-width: 375px) {
  .employeefirstName {
    font-size: 12px !important;
  }
}
.employeefield {
  font-size: 14px !important;
  color: #3e3e3e !important;
  font-weight: 500 !important;
}
@media (max-width: 375px) {
  .employeefield {
    font-size: 12px !important;
  }
}
