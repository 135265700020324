.PoliciesMainPart {
  height: 600px;
  width: 100%;
  /* background-color: white; */
  margin-left: 20px;
  padding-right: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.SectionPolicies {
  padding-right: 5px;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  padding-top: 10px;
  gap: 20px;
}

.MarketingupperPart {
  display: flex;
  justify-content: space-between;
  /* margin: 30px; */
  /* padding-top: 30px; */
  align-items: center;
}

.policiesContenHeader {
  color: #02354d;
  font-size: 16px;
  font-weight: 600;
}

.newFolder {
  background-color: #e0001b !important;
  border-radius: 5px !important;
  color: white !important;
  padding: 6px 12px !important;
  position: relative;
}
.PolicyArrowIcon {
  /* width: 7px;
  height: 14px; */
  color: #e0001b !important;
  margin: -2px;
}

.upload {
  background-color: #e0001b !important;
  border-radius: 5px !important;
  color: white !important;
  padding: 6px 12px !important;
  position: relative;
}

/* .newPosOfDialog {
  padding: 20px;
  width: 40%;
} */

.newItem {
  text-align: left;
  color: #02354d !important;
  font-weight: 500;
  font-size: 22px !important;
  margin-top: 10px !important;
}

.label {
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.subHeading {
  font-size: 12px !important;
  color: #606C74 !important;
  font-weight: 500;
}

.span {
  color: gray;
}

.span .MuiInputBase-root {
  height: 40px;
  color: gray;
}

.saveBtn {
  background-color: #009BAD !important;
  color: white !important;
  text-decoration: none !important;
  font-size: 14px !important;
}

.actionDivTwo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  margin: 40px 0px;
}

.dialogTitle {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.documentTypeWrapper {
  display: flex;
  align-items: center;
}

.reduced-width {
  width: 50% !important;
}

.manual-document-type {
  margin-left: 10px !important;
  flex-grow: 1;
  top: -5px;
  width: 360px !important;
}
.policiescontenteditorBox {
  margin: 30px;
}

.folderPathComponent{
  font-size: small !important;
}
.addpolicy{
 
    color: white;
    height: 40px;
    margin-left: 38px !important;
    margin-top: -27px !important;
}
.plusIcon{
    background-color: #e0001b;
    width: 112%;
    margin-left: -25px;
    margin-top: -30px;
}

.Marketing-table1 {
  /* max-width: fit-content; */

  /* margin-top: 15px;
  max-width: fit-content;
  /* Set a maximum width */
  /* width: 100%; */
  /* Ensure it takes full width of its container */
  /* padding: 20px; */
  /* border-radius: 20px; */
  /* box-shadow: 10px !important; */
  box-shadow: none;
  height: 430px !important;
  /* border: 1px solid red; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow-x: auto;
  background-color: white;
  /* margin-left: -13px;
  margin-top: 25px !important;
  margin-right: -29px !important; */
  /* Enable horizontal scrolling for small screens */
  
}
.TableHeader {
  font-size: 14px !important;
  color: #707073 !important;
  font-weight: 600 !important;
  line-height: 21.09px;

}

/* .EventsTableHeader .MuiDataGrid-columnHeaderTitleContainer {
  margin-bottom: 15px;
} */
.MarketingTableCell {
  font-size: 14px !important;
  color: #ababab !important;
  /* background-color: rgba(255, 255, 255, 1) !important; */
}
.Marketing-Breadcrumbs{
  display: flex;
  font-size: 13px;
  position: relative;
  right: 12px;
  top: 10px !important;
  gap: 10px;
  font-weight: 600 !important;
}
.even-row {
  background-color: rgba(249, 249, 249, 1) !important;
}

.odd-row {
  background-color: rgba(255, 255, 255, 1) !important;
  /* Red color for odd rows */
}
.Tablebreadcrumb0{
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  position: relative;
  right: 13px;
}
.MuiDataGrid-footerContainer{
  min-height: 0px !important;
}