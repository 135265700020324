.Header1 {
  font-size: 16px !important;
  color: #e0001b !important;
  font-family: 'Calibri Regular';
  font-weight: 500;
}
.createdBy {
  font-size: 12px !important;
}
.currentDate {
  font-size: 12px !important;
}
.viewnewsHeader {
  font-size: 14px !important;
  color: #6c6d70 !important;
  text-decoration: none !important;
  font-family: Calibri Regular !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.viewnewsHeader:hover {
  color: green !important;
  text-decoration: underline !important;
}
.viewnewsHeaderNew {
  font-size: 14px !important;
  color: #6c6d70 !important;
  text-decoration: none !important;
  font-family: Calibri Regular !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: -13px !important;
}
.viewnewsHeaderNew:hover {
  color: green !important;
  text-decoration: underline !important;
}
.viewnewsHeader1 {
  font-size: 14px !important;
  color: #6c6d70 !important;
  text-decoration: none !important;
  font-family: Calibri Regular !important;
  margin-right: 14px;
  cursor: pointer;
}
.viewnewsHeader1:hover {
  color: green !important;
  text-decoration: underline !important;
}

.card-container {
  margin-right: 16px;
}
.card-media:hover {
  /* Disable hover effect */
  box-shadow: none !important;
  transform: none !important;
}
.card-media img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 160px !important;
  width: 100% !important;
  object-fit: fill;
  filter: brightness(85%);
}
.cardcontent-news {
  padding: 8px 10px !important;
}
.cardnews-tile {
  font-size: 12px;
}

.cardnews-tiledate {
  font-size: 11px;
  color: #333333;
  opacity: 0.6;
}

.overlay-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
}
.News-icon {
  width: 15px !important;
  height: 15px !important;
}
.NewssectionArrowbutton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.mainGridNews {
  width: 100%;
}

.grid1 {
  width: 2000px !important;
}

.NewslistGrid {
  position: absolute;
  left: 680px;
  margin-left: 500px;
  /* top: 100px */
}
/* .slick-slider .slick-track,
.slick-slider .slick-list {
  max-width: 100% !important;
  width: 100% !important;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}
.slick-slide {
  width: 200px;
  height: 250px;
  max-width: 100%;
  z-index: 0;
  margin: 10px;
}
.slick-slide img {
  display: flex;
  width: 20%;
  height: 130px !important;
} */

.moreReference {
  color: var(--redprimary) !important;
  margin-bottom: 10px;
}
.readmoreheading-left-rightside {
  color: var(--redprimary);
  margin-bottom: 10px !important;
}

.cardGrid {
  display: flex !important;
  gap: 20px !important;
}

.customCardContent {
  padding: 0px !important;
  margin: 20px 0px 20px 5px !important;
}

ul {
  margin-inline-end: 35px; /* Adjust the value as needed */
}

.Selectednewsbox {
  background-color: #ffffff;
  max-width: 100% !important;
  margin: 24px;
  padding: 20px;
  border-radius: 10px !important;
}

.upperContent {
  background-color: white;
  border-radius: 10px;
}
.RelatedCategoriesBox {
  padding-left: 30px;

  @media (max-width: 768px) {
    padding-left: 0px !important;
    padding-top: 20px;
  }
}
.newsHeadertop1 {
  color: var(--redprimary) !important;
  font-size: 16px !important;
}

.newsHeadertop {
  color: var(--redprimary) !important;
  font-size: 16px !important;
  /* padding-left: 30px;
    padding-top: 23px; */
}

.imgDetails {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  padding-top: 15px;
  /* padding-left: 30px; */
  padding-right: 30px;

  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 425px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

.fieldImage {
  height: 150px;
  border-radius: 8px;
}

.nationalContent {
  padding-top: 20px;
  height: 115px !important;
  text-align: left;
  font-size: 13px !important;
  color: #333333 !important;
  opacity: 0.6;
  /* max-height: 3.5em; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
  display: -webkit-box;
  /* -webkit-line-clamp: 2; */
  -webkit-box-orient: vertical;
  /* max-width: 350px; */
  width: 190px !important;
}
.newbyrnenewsreadmore {
  color: #7c7f82 !important;
  border: none;
  /* text-align: center; */
  font-size: 13px;
  /* width: 210px; */
  transition: all 0.2s;
  cursor: pointer;
  background: none;
  border-radius: 4px;
  padding-left: 0px;
  /* padding-top: 3px !important; */
  /* margin-left: 11px !important; */

  font-family: Calibri Regular !important;

}
.newbyrnenewsreadmore:hover {
  color: green !important;
  text-decoration: underline !important;
}
.relateCategory {
  /* color: #333333 !important; */
  opacity: 0.8;
  font-size: 13px !important;
}
.selectedCategory {
  color: blue;
}

.defaultCategory {
  color: gray;
}
.readmoreimg {
  padding-right: 15px;
  border-right: 1px solid #f1f1f1;
}

.readmoreimges {
  height: 300px;
  border-radius: 8px;
  object-fit: fill;
}

.ceoContent {
  width: 100%;
  display: block;
  margin-top: 7px;
  opacity: 0.6;
  padding-right: 10px;
  margin-bottom: 10px;
  font-size: 13px !important;
  color: #333333 !important;
}

.CeonewsTitle {
  font-size: 28px;
}

.ceoContentHeader {
  width: 100%;
  display: flex;
  text-align: left;
  justify-content: space-between;
  font-size: 28px !important;
  color: #333333;
}

.calendarContainer {
  margin-top: 30px;
  margin-bottom: 80px;
  color: red;
}

.calendar {
  width: 100% !important;
  height: 100% !important;
}

.eventsContainer {
  margin-top: 100px;
}

.fullCard {
  padding: 20px;
}
.fullCardforreadmorepage {
  padding: 20px;
  /* padding-left: 24px;
    margin-top: 20px;
    border-radius: 8px; */
}
.typographyStyles {
  color: #e0001b !important;
}

.typographyStyles1 {
  color: #e0001b !important;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-bottom: 1px solid #f1f1f1;
}

.cardHeight {
  padding-top: 20px;
  background: transparent !important;
  box-shadow: none;
  width: 100%;
  margin-bottom: 16px;
}

.contentHeader {
  position: relative;
  background-color: unset !important;
  padding-top: 15px;
}

.contentArea {
  padding-left: 0px !important;
  padding-right: 0px !important;
  display: flex;
  justify-content: space-between;
}

.innerBackground {
  /* background: url(${background}); */
  height: 100px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  border-radius: 8px;
  position: relative;
}

.innerBannerOverlay {
  position: absolute;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 0, 0, 0),
    rgb(2 53 77)
  );
  height: 90%;
  color: #ffffff;
  bottom: 0;
  border-radius: 8px;
}

.breadcrumbsHeader {
  font-size: 20px;
  line-height: 20px;
  padding-top: 10px;
}

.breadcrumbsLinks {
  background: #e0001b !important;
  height: 30px;
  text-transform: capitalize;
}

.breadcrumbs {
  color: #ffffff !important;
  display: inline-flex;
}

.breadLinks {
  color: #ffffff !important;
  text-decoration: none !important;
  font-size: 24px !important;
}

.breadLinks1 {
  color: #ffffff !important;
  font-size: 14px !important;
  text-decoration: none !important;
}

.breadLinks2 {
  color: #ffffff !important;
  font-size: 14px !important;
  opacity: 0.5;
  text-decoration: none !important;
}

.moreReferenceContainer {
  position: relative;
  padding-top: 0px;
  margin-left: 270px;
  padding-bottom: 5px;
}

.ceoCreatedDate {
  color: #e0001b !important;
  padding-bottom: 10px;
  text-align: left;
  font-size: 16px !important;
}

.contentRoot {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  border-radius: 8px;
}

.paperStyles1 {
  position: absolute;
}

.paperStyles2 {
  position: relative;
  margin-bottom: 340px;
  padding-bottom: 200px;
  margin-left: 800px;
  margin-right: 80px;
  margin-top: 50px;
}

.paperStyles {
  height: 375px;
}

.hoverDot {
  color: red;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.5);
  }
}

/* .Date {}
  gridContainer {}
  Content {}
  headRow {} */

/* RecentNewsBigcardComponents.tsx file css */
.readMore {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #e0001b;
}

.nextIcon {
  font-size: 16px;
}
.newsHeader {
  color: black !important;
  opacity: 0.6;
  font-size: 12px !important;
  text-align: left;
  /* padding: 12px 0px 12px 0px; */
  /* height: 40px; */
  overflow: hidden;
  margin-bottom: 0px !important;
  /* border-bottom: 1px solid #e6e6e6; */
  margin-left: 11px !important;
}

.newsHeader12 {
  color: black !important;
  opacity: 0.6;
  font-size: 14px !important;
  text-align: left;
  /* padding: 12px 0px 12px 0px; */
  /* height: 40px; */
  overflow: hidden;
  margin-bottom: 0px !important;
  /* border-bottom: 1px solid #e6e6e6; */
  margin-left: 11px !important;
  font-weight: 900 !important;
}
.Topnews-paper {
  /* margin: 24px; */
  background-color: #effcff !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
  margin-left: 24px;
  margin-right: 24px;
}
.Educationnewspaper {
  border-radius: 10px !important;
  margin-top: 10px !important;
  margin-left: 24px;
  margin-right: 24px;
  /* display: flex; */
  /* overflowX: auto; */
}
.Worldnews-paper {
  margin: 24px;
  border-radius: 10px !important;
  margin-top: 10px !important;
}
/* RecentNews.tsx file css*/

/* nationalBox */
.nationalBox {
  background-color: white;
  margin: 0px 25px;
  border-radius: 10px !important;
  /* padding:26px; */
}
.nationalBox2 {
  background-color: white;
  margin: 0px 25px;
  border-radius: 10px !important;
  margin-bottom: 25px;
}
/* customList */
.customList {
  padding-bottom: 5px !important;
  padding-top: 0px !important;
}

.descriptionStyle {
  font-size: 13px !important;
  color: #333333;
  opacity: 0.6;
}
.img-wrapper12 {
  width:190px;
  height: 100px;
  overflow: hidden;
  border-radius: 8px;
}

.nationalSmallimg12 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* nationalSmallimg */
.nationalSmallimg {
  height: 92px;
  border-radius: 8px;
  object-fit: cover;
  cursor: pointer;

  @media (max-width: 425px) {
    /* flex-wrap: wrap !important; */
    width: 100%;
  }
}
.nationalSmallimg1 {
  position: relative;

  height: 80px;
  width: 130px;
  border-radius: 8px !important;
}

.fieldsTitle {
  display: flex;
  flex-direction: row;
  /* gap: 20px; */
  align-items: center;
}
.fieldsTitle2 {
  margin: 5px 0px 5px 0px;
}

.newsTitle {
  position: relative;
  top: -15px;
  font-size: 15px !important;
  color: #333333;
}

.dateStyle1 {
  margin-left: 20px !important;
  font-size: 13px !important;
  color: #333333 !important;
  opacity: 0.6 !important;
  text-align: justify;
  padding-bottom: 20px;
}

/* name */
.name {
  color: #333333 !important;
  font-size: 12px !important;
  opacity: 0.6;
}

/* name1 */
.name1 {
  color: #e0001b !important;
  font-size: 12px !important;
  width: 100px !important;
  text-align: left !important;
}
.newsTitle-forreadmorerightsidedescription{
    font-size: 13px;
  color:#605D62;
  opacity: 0.6;
  height: 50px;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;

  cursor: pointer;
}
.newsTitle-forreadmorerightside {
  font-size: 13px;
  color: #333333;
  opacity: 0.6;
  /* height: 65px; */
}
.newsTitle-forreadmoreleftside {
  font-size: 14px;
  color: #6C6D70;
  opacity: 0.6;
  margin-top: 20px;
}
.stack-list {
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media only screen and (max-width: 425px) {
    flex-direction: column;
  }
}
.errorNews {
  color: #8a9198;
  font-size: 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stack-list2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.stack-listnew {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.stack-list3 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.maindiv-stack {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.readmore-button {
  color: #089de3 !important;
  border: none;
  /* text-align: center; */
  font-size: 12px;
  /* width: 210px; */
  transition: all 0.2s;
  cursor: pointer;
  background: none;
  margin-top: 5px;
  border-radius: 4px;
  padding: 5px;
  padding-left: 0px;
  padding-top: 3px !important;
  /*     margin-right: 176px; */
}
.descriptionLeftAlign {
  text-align: left;
}
.newsCard {
  /* display: grid;
  grid-template-columns: repeat(4, 1fr); */
  width: 92%;
}

.Ceo-button-readmore {
  color: #089de3;
  border: none;
  /* text-align: center; */
  font-size: 12px;
  /* width: 210px; */
  transition: all 0.2s;
  cursor: pointer;
  background: none;
  margin-top: 5px;
  border-radius: 4px;
  padding: 5px;
  padding-left: 0px;
  /* padding-top: 3px !important; */
  margin-left: 11px !important;
}
.Ceo-button-readmore:hover {
  background-color: #f0f8fc !important;
  border-radius: 4px;
  padding: 5px;
  /* padding-left: 0px; */
}
.Ceo-button-readmore span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.Ceo-button-readmore span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.Ceo-button-readmore:hover span {
  padding-right: 20px;
}

.Ceo-button-readmore:hover span:after {
  opacity: 1;
  right: 8px;
  top: -1px;
}

@media (min-width: 769px) and (max-width: 1500px) {
  .newsCard {
    width: 90%;
  }
}

.Reference-readmore {
  /* background-color: green; */
  color: #ffffff;
  border-radius: 5px;
  padding: 4px 10px 4px 10px;
  text-transform: capitalize !important;
  font-size: 12px !important;
}
@media (width: 1024px) {
  .Reference-readmore {
    width: 255px !important ;
  }
}
@media (width: 768px) {
  .Reference-readmore {
    width: 288px !important ;
  }
}
@media (width: 425px) {
  .Reference-readmore {
    width: 290px !important ;
  }
}
.NewsReferenceleft{
  background-color: green;
  color: #ffffff;
  border-radius: 5px;
  padding: 3px 5px 3px 5px;
  text-transform: capitalize !important;
  font-size: 12px !important;
}
.NewsReference1 {
  background-color: green;
  color: #ffffff;
  border-radius: 5px;
  padding: 6px 10px 6px 10px;
  text-transform: capitalize !important;
  font-size: 12px !important;
}
.eventMonth {
  /* margin-top: 10px !important; */
  color: black;
  background-color: lightgrey;
  font-size: 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 10px; */
  /* margin-left: 10px; */
  padding-bottom: 3px;
  height: 30px;
}
.eventDate {
  display: flex;
  width: 100%;
  margin-top: 10px;
  color: #000000;
  font-size: 40px !important;
  font-weight: 800 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.eventTime {
  display: flex;
  width: 100%;
  /* margin-top: 10px !important; */
  margin-bottom: 10px !important;
  color: #8a9198;
  font-size: 12px !important;
  display: flex;
  align-items: left;
  /* margin-left: 12px !important; */
  justify-content: center;
  align-items: center;
}
.eventsTitle {
  color: black !important;
  font-weight: 900 !important;
  opacity: 0.6;
  font-size: 14px !important;
  text-align: left;
  padding: 12px 0px 0px 0px;
  height: 40px;
  overflow: hidden;
  margin-bottom: 10px !important;
  margin-left: 11px !important;
  text-transform: capitalize;
  /* border-bottom: 1px solid #e6e6e6; */
}
.eventsDescription {
  color: #8a9198;
  opacity: 0.6;
  font-size: 13px !important;
  text-align: left;
  padding: 12px 0px 0px 0px;
  height: 35px;
  overflow: hidden;
  margin-bottom: 0px !important;
  margin-left: 11px !important;
}
.MarketButton {
  background-color: yellow;
  color: #000000;
  border-radius: 5px;
  width: 210px;
  padding: 6px 10px 6px 10px;
  text-transform: capitalize !important;
  font-size: 12px !important;
}
@media (max-width: 1024px) {
  .MarketButton {
    background-color: yellow;
    color: #000000;
    border-radius: 5px;
    width: 255px;
    padding: 6px 10px 6px 10px;
    text-transform: capitalize !important;
    font-size: 12px !important;
  }
}
@media (max-width: 768px) {
  .MarketButton {
    width: 285px;
  }
}

@media (max-width: 375px) {
  .MarketButton {
    width: 235px;
  }
}
@media (max-width: 375px) {
  .MarketButton {
    width: 185px;
  }
}

.newsHeader1 {
  font-size: 12px !important;
  color: #8a9198 !important;
}

.MarketingHeader {
  color: black !important;
  opacity: 0.6;
  font-size: 13px !important;
  text-align: left;
  /* height: 35px; */
  margin-top: 10px !important;
  overflow: hidden;
  margin-bottom: 0px !important;
  margin-left: 11px !important;
}
.Marketingaddress {
  color: black !important;
  opacity: 0.6;
  font-size: 13px !important;
  text-align: left;
  /* height: 35px; */
  overflow: hidden;
  margin-bottom: 0px !important;
  margin-left: 11px !important;
  font-weight: 900 !important;
}

.industryheader {
  font-size: 18px !important;
  font-weight: 600;
  color: #605D62 !important;
  margin: 10px 0px 0px 10px;
  margin-bottom:14px !important;
}
.Byrnepostyear {
  margin-top: 10px;
  margin-left: 88%;
}
@media (max-width: 1024px) {
  .Byrnepostyear {
    margin-top: 10px;
    margin-left: 83%;
  }
}
@media (max-width: 768px) {
  .Byrnepostyear {
    margin-top: 10px;
    margin-left: 80%;
  }
}
@media (max-width: 425px) {
  .Byrnepostyear {
    margin-top: 10px;
    margin-left: 63%;
  }
}
@media (max-width: 320px) {
  .Byrnepostyear {
    margin-top: 10px;
    margin-left: 50%;
  }
}
.Byrnepostyears {
  margin-top: -17px;
    margin-bottom: -7px;
  margin-left: 95%;
}
@media (max-width: 1024px) {
  .Byrnepostyears {
    margin-top: -17px;
    margin-bottom: -7px;
    margin-left: 94%;
  }
}
@media (max-width: 768px) {
  .Byrnepostyears {
    margin-top: -17px;
    margin-bottom: -7px;
    margin-left: 92%;
  }
}
@media (max-width: 425px) {
  .Byrnepostyears {
    margin-top: -17px;
    margin-bottom: -7px;
    margin-left: 85%;
  }
}
@media (max-width: 320px) {
  .Byrnepostyears {
    margin-top: -17px;
    margin-bottom: -7px;
    margin-left: 81% !important;
  }
}
.byrnenewsTitle1 {
  color: #8c8c8f !important;
  /* opacity: 0.7; */
  font-size: 16px !important;
  text-align: left;
  /* padding: 12px 0px 12px 0px; */
  height: 25px;
  overflow: hidden;
  margin-bottom: 0px !important;
  /* border-bottom: 1px solid #e6e6e6; */
  margin-left: 11px !important;
  font-weight: 700 !important;

  font-family: Calibri Regular !important;

}
.byrnenewsTitle {
  color: #8c8c8f !important;
  font-size: 14px !important;
  text-align: left;
  /* height: 25px; */
  overflow: hidden;
  margin-bottom: 0px !important;
  font-weight: 700 !important;
  text-overflow: ellipsis;
  font-family: Calibri Regular !important;
  width: 95%;
  white-space: nowrap;

}
.byrnenewsDescription {
  color: #656465 !important;
  opacity: 0.6;
  font-size: 12px !important;
  text-align: left;
  /* padding:10px 0px; */
  /* height: 58px; */
  overflow: hidden;
  margin-bottom: 5px !important;
  font-family: Calibri regular !important;
  width: 95%;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.byrnenewsDescription.single-line {
  -webkit-line-clamp: 1;
}

.byrnenewsDescription.double-line {
  -webkit-line-clamp: 2;
}

.byrnenewsDescription.triple-line {
  -webkit-line-clamp: 3;
}


.byrnenewsDescription1 {
  color: #7c7f82 !important;
  opacity: 0.6;
  font-size: 12px !important;
  text-align: left;
  /* padding: 12px 0px 12px 0px; */
  height: 58px;
  overflow: hidden;
  margin-bottom: 5px !important;
  /* border-bottom: 1px solid #e6e6e6; */
  margin-left: 11px !important;

  font-family: Calibri regular !important;

}

.byrneeventdate {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  height: 5px;
  color: #a5a5a5;
  font-size: 12px !important;
  margin-bottom: 5px;
  /* margin-top: 45px; */
}
@media (max-width: "768px") {
  .byrneeventdate {
    display: flex;
    align-items: center;
    width: 100%;
    height: 5px;
    color: #a5a5a5;
    font-size: 12px !important;
    margin-bottom: 5px;
    margin-top: 25px;
  }
}
.byrnenewreadmore {
  color: #7c7f82 !important;
  border: none;
  font-size: 11px;
  transition: all 0.2s;
  cursor: pointer;
  background: none;
  border-radius: 4px;
  padding-left: 0px;
  font-family: Calibri Regular !important;

}
.byrnenews-readmore {
  color: #7c7f82 !important;
  border: none;
  /* text-align: center; */
  font-size: 11px;
  /* width: 210px; */
  transition: all 0.2s;
  cursor: pointer;
  background: none;
  border-radius: 4px;
  padding-left: 0px;
  /* padding-top: 3px !important; */
  margin-left: 11px !important;

  font-family: Calibri Regular !important;

}
.eventdate12 {
  display: flex;
  align-items: center;
  width: 100%;
  /* margin-top: 10px; */
  /* height: 15px; */
  color: #605D62 !important;
  font-size: 12px !important;
  margin-bottom: 5px;
  /* margin-top: 45px; */
}
@media (max-width: "768px") {
  .eventdate12 {
    display: flex;
    align-items: center;
    width: 100%;
    height: 5px;
    color: #a5a5a5;
    font-size: 12px !important;
    margin-bottom: 5px;
    margin-top: 25px;
  }
}
.industryeventdate12{ 
  display: flex;
  align-items: center;
  width: 100%;
  /* margin-top: 10px; */
  /* height: 15px; */
  color: #605D62 !important;
  font-size: 12px !important;
  /* margin-bottom: 5px; */
  /* margin-top: 45px; */
}
@media (max-width: "768px") {
  .industryeventdate12 {
    display: flex;
    align-items: center;
    width: 100%;
    height: 5px;
    color: #a5a5a5;
    font-size: 12px !important;
    /* margin-bottom: 5px; */
    /* margin-top: 25px; */
  }
}
.newsHeaderindustry {
  color: #605D62 !important;
  opacity: 0.6;
  font-size: 12px !important;
  text-align: left;
  /* padding: 12px 0px 12px 0px; */
  height: 40px;
  overflow: hidden;
  margin-bottom: 0px !important;
  /* border-bottom: 1px solid #e6e6e6; */
  margin-left: 4px !important;
}

.newsHeaderindustry12 {
  color: #605D62 !important;
  opacity: 0.6;
  font-size: 14px !important;
  text-align: left;
  /* padding: 12px 0px 12px 0px; */
  height: 40px;
  overflow: hidden;
  margin-bottom: 0px !important;
  /* border-bottom: 1px solid #e6e6e6; */
  margin-left: 4px !important;
  font-weight: 600 !important;
}
.ByrneNewsReference123 {
  background-color: green;
  color: #ffffff;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  text-transform: capitalize !important;
  font-size: 12px !important;
  position: absolute;
  left: 4%;
  /* bottom: 10%; */
  font-family: Calibri Regular !important;
  cursor: pointer;
  /* margin-top: 10px; */
  margin-bottom: 10px;
}

.container12 {
  display: flex;
  justify-content: center; /* Horizontal center */
  align-items: center;    /* Vertical center */
  height: 75vh;          /* Full viewport height */
  text-align: center;
}

.typographyStyles212 {
  color: #8c8c8f;
  font-size: 1px;
}
.clamped-text {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  /* height: 40px; */
}