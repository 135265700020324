.root {
  max-width: 100%;
  background: #e0001b !important;
  color: #ffffff !important;
  height: 315px;
}

/* BirthdayContentpage.tsx css */
.bigPaper {
  box-shadow: 10px 1px 30px -10px #c2bcbc;
  border-radius: 20px;
  background-color: rgba(243, 246, 249, 1);
  height: 660px !important;
}
@media (max-width: 1440px) {
  .bigPaper {
    box-shadow: 10px 1px 30px -10px #c2bcbc;
    border-radius: 20px;
    background-color: rgba(243, 246, 249, 1);
    height: 550px !important;
  }
}
@media (max-width: 768px) {
  .bigPaper {
    height: 550px !important;
  }
}

.calendarMain .MuiInputBase-root {
  border-bottom: none !important;
  outline: none;
  padding: 2px 10px !important;
  font-size: 12px;
  color: gray;
  /* background-color: #e6ffe6; */
  border-radius: 5px;
  border: #ffffff !important;
}
.newscontenteditorBox {
  margin: 30px;
}

.error {
  color: red;
  font-size: 12px;
}

.media {
  height: 0;
  padding-top: 56.25%;
  /* 16:9 */
}
/* .bigPaper1{
  color:rgba(243, 246, 249, 1) !important;
  background-color: rgba(243, 246, 249, 1) !important;
  border-radius: 20px;
} */
.expand {
  transform: rotate(0deg);
  margin-left: auto;
}

.expandOpen {
  transform: rotate(180deg);
}

.avatar {
  background-color: #f44336;
  /* red[500] in MUI */
}

.annHeader {
  padding-left: 16px;
  padding-top: 15px;
  font-size: 1rem !important;
  color: #ffffff !important;
}

.annTitle {
  line-height: 24px !important;
  height: 50px;
  overflow: hidden;
  color: #ffffff !important;
  margin-bottom: 10px;
  font-size: 20px !important;
  text-decoration: none;
}

.annTitle a {
  color: #ffffff !important;
  text-decoration: none;
}

.annContents {
  overflow: hidden;
  color: #ffffff !important;
  margin-bottom: 10px;
  opacity: 0.8;
}

.annContents a {
  color: #ffffff !important;
  text-decoration: none;
}

.annContents a:focus {
  color: #ffffff !important;
}

.annContents a:hover {
  color: #ffffff !important;
}

.annDesc {
  opacity: 0.8;
  height: 161px;
  overflow: hidden;
}

.readMore {
  font-size: 12px;
  color: #089de3;
}

.NewsMainPart {
  height: 600px;
  width: 100%;
  background-color: white;
  margin-left: 20px;
  padding-right: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.DataGrid {
  border: none !important;
}

.NewsSection {
  /* height: auto;
  width: 100%;
  background-color: #d1eae33b; */
  padding-right: 55px;
  /* border-top-right-radius: 9px; */
}

.upperPart {
  display: flex;
  justify-content: space-between;
  /* margin: 30px; */
  padding-top: 30px;
  align-items: center;
}

.newscontenHeader {
  color: #02354d;
  font-size: 16px;
  font-weight: 600;
}

.new {
  background-color: #009bad;
  border-radius: 5px;
}

.new MuiButton {
  color: white !important;
  background-color: #009bad;
}

.create {
  color: white !important;
  padding: 6px 12px !important;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.plus {
  text-align: center;
  margin-top: 5px;
  margin-right: 10px;
  color: white !important;
}

/* .newPosOfDialog {
  padding: 20px;
  width: 40%;
} */

.dropZone {
  padding: 20px;
  text-align: center;
  border: 3px dashed #eeeeee;
  color: #bdbdbd;
  background-color: #fafafa;
  margin-bottom: 20px;
}

.newItem {
  text-align: left;
  color: #02354d !important;
  font-weight: 500;
  font-size: 22px !important;
  margin-top: 10px !important;
}

.label {
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.label2 {
  display: flex;
  align-items: center;
}

.subHeading {
  font-size: 12px !important;
  color: #606c74 !important;
  font-weight: 500;
}

.subHeadingimg {
  width: 15px;
  margin-right: 8px;
}

.previewHeading {
  font-size: 20px !important;
  color: #333333 !important;
  font-weight: 500;
  margin-top: 20px !important;
}

.previewParagraph {
  font-size: 13px !important;
  color: #606c74 !important;
}

.sideBar {
  height: 100%;
  background-color: #02354d;
  text-align: center;
  border-radius: 20px 1px 1px 20px;
}

.span {
  color: gray;
}

.span .MuiInputBase-root {
  height: 40px;
  color: gray;
}

.file MuiFormControl-root MuiSvgIcon {
  color: black;
  background-color: black;
}

.iconDiv {
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin: 15px;
}

.iconView {
  display: flex;
  justify-content: space-between;
  width: 50px;
}

.actionPart {
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin-right: 350px;
}

.saveBtn {
  background-color: #009bad !important;
  color: white !important;
  text-decoration: none !important;
  font-size: 14px !important;
}

.cancelBtn {
  background-color: #606c74 !important;
  color: white !important;
  font-size: 14px !important;
}

.actionDivTwo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  margin: 40px 0px;
}

.titleIcon {
  width: 13px;
  margin-right: 5px;
}

.checkLike {
  width: 15px;
  margin-right: 15px;
}

.dialogTitle {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.backgroundImage {
  text-align: center;
  border-radius: 8px;
  width: 100%;
  height: 300px;
}

.dialogBtn {
  color: #606c74;
  font-size: 12px;
}

.svg {
  cursor: pointer;
  border: 1px dashed gray;
  border-radius: 5px;
  padding: 20px;
  height: auto;
  align-items: center;
}

.svg input {
  color: white;
  background-color: #009bad;
}

.svg p {
  font-size: 12px;
  margin: 0;
  text-align: center;
  color: #606c74;
}

.svg span {
  display: none !important;
  font-size: 12px;
}

.svg button {
  color: white;
  background-color: #009bad;
  font-size: 12px;
  margin-top: 10px;
}

.svg img {
  width: 45px;
}

.content11 {
  font-size: 30px;
  /* margin-top: 14px !important; */
  color: var(--redprimary);
}

.content12 {
  font-size: 12px;
  color: #333333;
  opacity: 0.6;
  margin-top: 5px !important;
}

.subdivContainer {
  padding: 3px;
  border: 1px solid #009bad !important;
  height: 34px;
}

.vector-icon {
  width: 15.58px;
  height: 15.57px;
  top: 4.21px;
  left: 4.21px;
  color: #ffffff;
}
.vector-iconnew {
  width: 35.58px;
  height: 32.57px;
  color: #ffffff;
  margin-top: 3px;
}

.Adduser-Button,
.Adduser-Button1 {
  background-color: var(--blackprimary) !important;
  color: white !important;
  display: flex;
  width: 114px !important;
}
.Adduser-Button2 {
  background-color: var(--blackprimary) !important;
  color: white !important;
  display: flex;
  width: 200px !important;
}
.Adduser-Button:hover,
.Adduser-Button1:hover {
  background: none;
  /* Change color on hover */
}
.Export-Button{
  border: 1px solid black !important; 
  color: #423E44 !important;
  display: flex;
  width: 114px !important;
  background: none !important;
  justify-content: space-evenly !important;
}
.Employeemaster-Stack {
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 0px;
}

@media (max-width: 1024px) {
  .Employeemaster-Stack {
    flex-direction: row !important;
    gap: 20px;
    align-items: flex-start;
  }
}

.slide-effect {
  transition: transform 0.3s ease-in-out;
}

.slide-effect .subdivContainer {
  transform: translateX(0);
}

.slide-effect .dashboardContainer {
  transform: translateX(-100%);
}

.data-table {
  margin-top: 15px;
  max-width: fit-content;
  /* Set a maximum width */
  /* width: 100%; */
  /* Ensure it takes full width of its container */
  /* padding: 20px; */
  border-radius: 8px;
  /* box-shadow: 10px !important; */
  box-shadow: 0px 0px 6px 0px #c2bcbc;
  height: 510px !important;
  /* border: 1px solid red; */
  /* border-top-left-radius: 20px; */
  /* border-top-right-radius: 20px; */
  overflow-x: auto;
  /* margin-left: 20px;
  margin-right: 20px !important; */
  background-color: rgba(248, 246, 246, 1);
  /* Enable horizontal scrolling for small screens */
}
@media (max-width: 1440px) {
  .data-table {
    height: 400px !important;
  }}

@media (max-width: 768px) {
  .data-table {
    padding: 10px;
    height: 320px !important;
    border-radius: 10px;
  }
}

/* .custom-cell-class {
  background-color: #FEFEFE !important; /* Set cell background to red */
/* opacity: 0.6;
} */

.custom-header-class {
  background-color: rgba(255, 0, 0, 0.06) !important;
  font-size: 13px;
  color: rgb(0, 110, 255);
}

.custom-header-class1 {
  background-color: rgba(5, 137, 152, 0.06) !important;
  /* Set header background to blue with opacity */
  /* color:rgba(125, 125, 125, 1) !important; */
  height: 20px;
  font-size: 13px;
}

/* .MuiDataGrid-root .MuiDataGrid-colCellWrapper .MuiDataGrid-colCell,
.MuiDataGrid-root .MuiDataGrid-cell {
  font-size: 12px !important;
} */
/* Adjust font size if needed */
/* } */

/* Table Cell LIne Brakes code */
/* .MuiDataGrid-root .MuiDataGrid-colCellWrapper .MuiDataGrid-colCell,
.MuiDataGrid-root .MuiDataGrid-cell {
  height: 48px !important;
} */

.MuiDataGrid-root .MuiDataGrid-colCellWrapper .MuiDataGrid-colCell {
  color: var(--header-text-color) !important;
}

.Badge {
  margin-left: 136px;
}
.css-1iyq7zh-MuiDataGrid-columnHeaders {
  max-height: 40px;

  min-height: 40px !important;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid;
  border-top-left-radius: var(--unstable_DataGrid-radius);
  border-top-right-radius: var(--unstable_DataGrid-radius);
}

.css-184cyof-MuiDataGrid-root {
  min-height: auto;
  --unstable_DataGrid-radius: 4px;
  --unstable_DataGrid-headWeight: 500;
  --unstable_DataGrid-overlayBackground: rgba(255, 255, 255, 0.38);
  --DataGrid-cellOffsetMultiplier: 2;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  box-sizing: border-box;
  position: relative;
  /* border-width: 1px; */
  border-style: none !important;
  border-color: none !important;
  border-radius: var(--unstable_DataGrid-radius);
  color: #333333;
  font-family: Calibri Regular !important;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  outline: none;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  min-height: 0;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-anchor: none;
}
.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
  padding-left: 0;
  font-size: 12px !important;
}
.AddUser ul {
  opacity: 1 !important;
  transform: none;
  transition: opacity 232ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 155ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  top: 0px !important;
  left: 0px !important;
  transform-origin: 0px 0px !important;
  border-radius: 30px !important;
}

.div11 {
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.addUser {
  background-color: var(--blackprimary);
  display: flex;
  justify-content: space-between;
  padding: 10px;
  /* align-items: left; */
    position: fixed;
  width: 26.8%;
  z-index: 999;
  height: 22px !important;
}
@media screen and (max-width: 1440px) {
  .addUser {
    width: 28.6%;
  }
  }
@media screen and (max-width: 1024px) {
  .addUser {
    width: 39.6%;
  }
  }
  @media screen and (max-width: 768px) {
    .addUser {
      width: 53%;
    }
    }
    @media screen and (max-width: 425px) {
      .addUser {
        width: 87% !important;
      }
      }

.Add1 {
  /* color: #009BAD; */
  background-color: #009bad;
}

.AddCancel-Button {
  display: flex;
  justify-content: right;
  /* position: relative; */
  /* bottom: 152px; */
  margin-top: 30px;
  /* margin-bottom: 20px; */
}

.containerheader11 {
  background-color: var(--redprimary);
  display: flex;
  justify-content: right;
  width: 464px !important;
  height: 114px !important;
  justify-content: space-between;
}

.containerheader12 {
  margin-right: 10px;
  position: relative;
  bottom: 20px;
}

.profilePhoto {
  position: relative;
  bottom: 55px;
  left: 17px;
}

.preveiw-Name {
  display: flex;
  position: relative;
  bottom: 46px !important;
  font-weight: 500px !important;
  left: 60px !important;
  font-size: 30px;
  color: rgba(30, 30, 30, 1) !important;
}

.remoicon-style1 {
  height: 20px !important;
  width: 20px !important;
}

.remoicon-style {
  width: 91px !important;
  height: 85px !important;
  position: relative;
  right: 3px;
}

.savecontent-btn {
  display: flex;
  position: relative;
  bottom: 100px;
  background-color: #009bad !important;
  color: #ffffff !important;
  bottom: 45px;
  left: 195px;
  height: 33px;
  width: 110px;
  text-transform: capitalize;
}

.SmallIcon {
  display: flex;
  position: relative;
  bottom: 132px;
  left: 125px;
  height: 40px;
  width: 40px;
  border: 4px solid #ffffff;
  background-color: var(--redprimary);
  border-radius: 50%;
}

.saveyourcontent-btn {
  display: flex;
  position: relative;
  bottom: 100px;
  background-color: #009bad !important;
  color: #ffffff !important;
  bottom: 45px;
  left: 196px;
  height: 33px;
  width: 145px;
  text-transform: capitalize;
}

.custom-row-class {
  height: 40px;
}

.department-content {
  color: rgba(125, 125, 125, 1);
  display: flex;
  justify-content: space-around;
}

.Mobileicon-style {
  display: flex;
  height: 22px;
  width: 60px;
}

.Mail-style {
  display: flex;
  height: 22px;
  width: 60px;

  display: flex;
  align-items: center;
  position: relative;
  /* top: 19px; */
  /* left: 4px;  */
}

.EarthIcon1-style {
  display: flex;
  height: 22px;
  width: 60px;
}

.EarthIcon2-style {
  display: flex;
  height: 22px;
  width: 60px;
}

/* dialog Box Css */
.customDialog {
  width: 500px;
  /* Adjust the width as needed */
}

.DialogTitle1 {
  padding-bottom: 0px !important;
}

.DialogTitle2 {
  /* position: relative; */
  font-size: 12px !important;
  padding-top: 0px !important;
  /* bottom: 33px; */
  padding-bottom: 0px !important;
}

.dialogBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  border-radius: 8px;
  border: 2px dashed rgba(1, 54, 84, 0.8);
  /* Opacity value: 0.5 */
  color: rgba(1, 54, 84, 1);
}

.Browse-btn {
  margin-top: 16px;
  background-color: #009bad;
}

.Browse-btn:hover {
  background-color: #009bad;
}

.file-Size {
  margin-left: 45px !important;
  font-size: 9px !important;
  position: relative;
  bottom: 60px;
}
.file-Size1 {
  margin-left: 68px !important;
  font-size: 9px !important;
  position: relative;
  bottom: 11px;
}

.closeIcon {
  position: relative;
  left: 280px;
  width: 10px !important;
  bottom: 64px;
}

.AddUser,
.AddMultipleUsersIcon {
  font-size: 13px;
}

.excelIcon {
  margin-right: 8px;
  height: 45px;
  width: 52px;
  position: relative;
  top: 11px;
  right: 10px;
}
.custom-data-grid .MuiDataGrid-colCellTitle {
  height: 30px; /* Adjust the height as per your requirement */
}
/*row style*/
.rowEmail {
  font-size: 12px;
  color: #605D62 !important;
  /* color: rgba(168, 168, 168, 1); */
}
.rowName {
  font-size: 14px !important;
  color: #605D62 !important;
  /* color: #707073 !important; */
  font-weight: 600 !important;
  line-height: 21.09px;
}
/* .custom-cell-class {
  font-size: 12px;
  color: rgb(255, 0, 0);
} */
.pagination-bottom {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.even-row {
  background-color: rgba(249, 249, 249, 1) !important;
}

.odd-row {
  background-color: rgba(255, 255, 255, 1) !important;
  /* Red color for odd rows */
}

/* .EmployeeMastertableheader {
  color: rgba(125, 125, 125, 1) !important;
  font-size: 14px !important;
  background-color: rgba(5, 137, 152, 0.06) !important;
} */
.EmployeeMastertablecellcontent {
  font-size: 14px !important;
  color: #605D62 !important;
}
.div-contentnew {
  display: flex !important;
  flex-direction: column !important;
  gap: 20px !important;
  position: relative;
  /* bottom: 27px !important; */
}
/* view profile div-content */
.div-content {
  display: flex !important;
  flex-direction: column !important;
  gap: 20px !important;
  position: relative;
  bottom: 27px !important;
}
.icon-style {
  display: flex;
  height: 22px !important;
  width: 54px !important;
}
.GroupIcon {
  display: flex;
  position: relative;
  left: 13px !important;
}
.uploadPercentage{
  margin-top: 4px !important;
  margin-left: 244px !important;
}
.headTextNew{
  font-size: 22px;
  color: #423E44;
}
.basicdetails{
  margin-left: 10px;
}
.WhitecolorBlocksParagraph{
  color:#9D9B9E;
  font-size: 14px;
}

.DrawerHeaderBoxEM {
  background-color: var(--blackprimary);
  margin: 0px;
  margin-top: 0px;
  padding: 10px 0px 10px 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 413px;
  z-index: 999;
  height: 22px !important;
}