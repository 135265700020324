.PoliciesMainPart {
  height: 600px;
  width: 100%;
  /* background-color: white; */
  margin-left: 20px;
  padding-right: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.SectionPolicies {
  padding-right: 55px;
  cursor: pointer;
  user-select: none;
}

.upperPart2 {
  display: flex;
  justify-content: space-between;
  /* margin: 30px; */
  /* padding-top: 30px; */
  align-items: center;
}

.policiesContenHeader {
  color: #02354d;
  font-size: 16px;
  font-weight: 600;
}

.newFolder {
  background-color: #e0001b !important;
  border-radius: 5px !important;
  color: white !important;
  padding: 6px 12px !important;
  position: relative;
}
.PolicyArrowIcon {
  /* width: 7px;
  height: 14px; */
  color: #e0001b !important;
  margin: -2px;
}

.upload {
  background-color: #e0001b !important;
  border-radius: 5px !important;
  color: white !important;
  padding: 6px 12px !important;
  position: relative;
}

/* .newPosOfDialog {
  padding: 20px;
  width: 40%;
} */
.newPosOfDialognew {
  padding: 20px;
  width: 34% !important;
}
@media (max-width: 1024px) {
  .newPosOfDialognew {
    padding: 20px;
    width: 40% !important;
  }}
  

.newItem {
  text-align: left;
  color: #02354d !important;
  font-weight: 500;
  font-size: 22px !important;
  margin-top: 10px !important;
}

.label {
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.subHeading {
  font-size: 12px !important;
  color: #606C74 !important;
  font-weight: 500;
}
.subHeadingnew {
  font-size: 12px !important;
  color: #606C74 !important;
  font-weight: 500;
  margin-bottom: 5px;
}
.subHeadingfolder {
  font-size: 12px !important;
  color: #606C74 !important;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
}
.span {
  color: gray;
}

.span .MuiInputBase-root {
  height: 40px;
  color: gray;
}

.saveBtn {
  background-color: #009BAD !important;
  color: white !important;
  text-decoration: none !important;
  font-size: 14px !important;
}

.actionDivTwo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  margin: 40px 0px;
}

.dialogTitle {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.documentTypeWrapper {
  display: flex;
  align-items: center;
}

.reduced-width {
  width: 40% !important;
}

.manual-document-type {
  margin-left: 10px !important;
  flex-grow: 1;
  top: -5px;
  width: 360px !important;
}
.policiescontenteditorBox {
  margin: 30px;
}

.folderPathComponent{
  font-size: small !important;
}
.addpolicy{
 
    color: white;
    height: 40px;
    margin-left: 38px !important;
    margin-top: -27px !important;
}
.plusIcon{
    background-color: #e0001b;
    width: 112%;
    margin-left: -25px;
    margin-top: -30px;
}
.policyEditorDescription-style2 {
  color: rgba(125, 125, 125, 1);
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  /* white-space nowrap; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-left: 34px;

}
.DepartmentTable-table1 {
  /* max-width: fit-content; */

  /* margin-top: 15px;
  max-width: fit-content;
  /* Set a maximum width */
  /* width: 100%; */
  /* Ensure it takes full width of its container */
  /* padding: 20px; */
  /* border-radius: 20px; */
  /* box-shadow: 10px !important; */
  box-shadow: none;
  height: 430px !important;
  /* border: 1px solid red; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
  margin-top: 15px;
  overflow-x: auto;
  /* margin-left: -14px;
  margin-right: -20px !important; */
  /* Enable horizontal scrolling for small screens */
  
}
.newFolder {
  background-color: #e0001b !important;
  border-radius: 5px !important;
  color: white !important;
  padding: 6px 12px !important;
  position: relative;
}
.selectedDepartmentName{
  cursor: pointer;
  /* color: rgb(132, 132, 132); */
  white-space: nowrap;
  position: relative;
  bottom: 2px;
  right: 14px;
}
.Tablebreadcrumbarrow-icon12newedit {
  margin: 5px 0px 0 0px !important;
  color: #7c7f82 !important;
}
.Tablebreadcrumbarrow-iconnewedit {
  margin: 5px 0px 0 0px !important;
  color: #7c7f82 !important;
}
.Tablebreadcrumb1{
  font-size: 14px !important;
  color: #7D7D7D !important;
  text-transform: capitalize !important;
}



.fileList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.fileItem {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures the name is on the left and delete button on the right */
  margin-bottom: 5px;
  padding: 5px 10px; /* Adds some padding for better spacing */
  border: 1px solid #ccc; 
  /* color: var(--redprimary); */
  border-radius: 4px; /* Optional: Rounds the corners */
}

.fileName {
  flex-grow: 1; /* Makes the name take up all available space */
  text-align: left; /* Aligns the text to the left */
}

.deleteButton {
  border: none;
  background: none;
  cursor: pointer;
  margin-left: 10px; /* Adds some space between the name and button */
  display: flex;
  align-items: center;
  justify-content: center;
}
.addChoicebutton{ 
  margin-left: 8px !important;
   font-size: 12px !important;
  }
  @media (max-width: 1355px) {
    .addChoicebutton {
      margin-left: 8px !important;
      font-size: 10px !important;
      width: 94px;
    }
  }
  .DrawerHeaderBoxGallery {
    background-color: var(--blackprimary);
    margin: 0px -20px;
    margin-top: -20px;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .fileItemnew{
    margin-top: 10px;
     display: flex; 
     align-items: center;
    justify-content: space-between;
      border: 1px solid #ccc;
      padding-right: 0px; 
      padding-left: 10px;
      border-radius:4px;
  }