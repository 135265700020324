.salesBackground {
  /* background-color: hsl(0, 0%, 100%); */
  background-color: #fffade;
  /* border-radius: 20px !important; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  /* width: 100% !important;
    height: 100%; */
  padding: 20px;
  border-radius: 8px;
  margin-top: 10px;
}
.sidebar {
  /* padding-left: 65px; */
  padding-left: 30px;
}
/* @media only screen and (max-width: 600px) {
    
  } */
.sales-card-details {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 100%;
  padding: 20px;
}
.span-s-text {
  font-size: 12px;
  color: #8f9094;
}
.box-text {
  text-align: left;
  color: #8f9094 !important;
  cursor: pointer;
  font-weight: 600 !important;
  font-size: 14px !important;
}
.salesMarketBox {
  background-color: #f3f3f3;
  padding: 0px;
  margin-top: 0px !important;
}

.DepartmentMainPartBackground {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* background-color: #f6ebebac; */
  max-width: 100% !important;
  width: 100% !important;
  /* border-radius: 0px 20px 20px 0px; */
}
.salesPart {
  margin: 30px;
}
.blogBox {
  height: 100%;
  width: 200px;
  background-color: #ffffff;
  border-radius: 5px;
  margin-left: 20px;
}
.custom-border {
  position: relative;
}

.custom-border:after {
  content: " ";
  position: absolute;
  border-left: 1px #8f9094 solid;
  top: 35%;
  right: 0;
  height: 50%;
  margin-top: auto;
  margin-bottom: auto;
}
.color-Card {
  /* width: 210px; */
  border-radius: 8px;
  height: auto;
  padding: 0px;
  cursor: pointer;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgb(201 206 211 / 15%) 0px 0px 0px 1px;
}
.upper-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
}
.root-search {
  align-items: center;
  position: relative;
  border-radius: 30px;
  /* padding-right: 14px; */
  height: 24px !important;
  width: 45px;
  padding-left: 12px;
  border-radius: 25px;
}
.root-search .MuiOutlinedInput-root {
  border-radius: 25px;
}
/* .css-i4bv87-MuiSvgIcon-root {
  width: 30px !important;
} */
.root-search .MuiInputAdornment-root {
  background-color: red;
  border-radius: 50%;
  color: white;
  padding: 10px;
}

.upperText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
  border-radius: 20px;
  max-width: 770px;
  /* height: 100%; */
  font-size: 11px;
  color: #782e2e;
  line-height: 150%;
  font-weight: 600;
}
.policyUpperText {
  color: #a7a7a7;
  padding-right: 20px;
  font-size: 15px;
}
.hereText {
  color: #9a0910;
  padding-left: 20px;
}

.contentBox {
  background-color: #ffffff;
  height: 100%;
  border-radius: 20px;
  width: 100% !important;
  margin: 10px 0px 10px 0px;
  /* margin-bottom: 20px; */
  cursor: pointer;
}

.upperPart .MuiIconButton-root {
  color: #4d020c;
}

.upperPart .MuiTypography-root {
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
  color: #4d0202;
}
.file-part {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin-top: 5px;
}
.sales-img {
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 120px;
}

.new\&uploadGrid {
  display: flex;
  align-items: center;
}

.newFolder {
  background-color: #ad0017;
  color: #ffffff;
  text-transform: uppercase;
  margin-right: 10px;
}

.upload {
  background-color: #ffffff;
  color: #009bad;
  text-transform: uppercase;
  border: 1px solid #009bad;
}

.searchFilterBox {
  padding-top: 10px;
  margin-bottom: 15px;
  margin-left: 20px;
  display: flex;
  justify-content: left;
}
/* Search Bar */
.departSearchBar {
  background-color: #ffffff;
  width: 200px;
  height: 30px;
  margin-right: 15px;
  line-height: 28px;
  padding: 0 1rem;
  padding-left: 2.5rem;
  border: 1px solid #dfe1e5 !important;
  border-radius: 15px;
  outline: none;
  transition: 0.3s ease;
  color: #c2c0c0 !important;
}

.searchIcon {
  position: relative;
  left: 40px;
  top: 10px;
  color: #c2c0c0;
  cursor: pointer;
  
}
.policiesSearchBar::placeholder {
  color: #9e9ea7;
}

.policiesSearchBar .MuiTextField-root {
  width: 100%;
}

/* Filter Box */
.policiesSearchBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.policiesSearchBox .MuiSelect-root {
  width: 48%;
}

.policiesSelectMenu {
  margin-bottom: 16px;
  background-color: #ffffff;
  border: none !important;
  width: 100px;
  height: 40px;
  line-height: 28px;
  padding: 0 1rem;
  padding-left: 2.5rem;
  border: 2px solid transparent;
  border-radius: 5px;
  outline: none;
  transition: 0.3s ease;
}

.sectionSelector {
  outline: none !important;
  border: 1px solid transparent !important;
}

.sectionSelector:hover {
  border: none !important;
}

.documentTypeSelector {
  outline: none !important;
}

.filterButton {
  margin-left: 10px !important;
  background-color: #009bad !important;
}

.recentlyViewedBox {
  background-color: #ffffff;
  margin-bottom: 10px;
  margin-left: 20px;
  height: 150px;
  border-radius: 15px;
  cursor: pointer;
}

.trendingDocumentsBox {
  background-color: #ffffff;
  margin-bottom: 10px;
  margin-right: 15px;
  height: 200px;
  border-radius: 15px;
  cursor: pointer;
}

.trendingTopicsTypo,
.trendingTopicsTypo p {
  color: #ad0000;
  font-weight: bold !important;
  text-align: left;
}

.trendingDocumentsBox {
  padding: 15px;
  min-height: 180px;
}

.recentlyViewedBox {
  background-color: #ffffff;
  margin-bottom: 10px;
  margin-left: 20px;
  border-radius: 15px;
  padding: 20px;
  height: 200px;
  overflow-y: auto;
}

.recentlyViewedTypo,
.recentlyViewedTypo p {
  color: #d60813;
  font-weight: bold !important;
  font-size: 16px !important;
}

.recentlyViewedContent {
  display: flex;
  flex-direction: column;
}

.recentlyViewedGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.recentlyViewedItem {
  border: 2px solid #eaeaea;
  display: flex;
  align-items: center;
  padding: 9px;
  border-radius: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.recentlyViewedName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial !important;
  font-size: 15px !important;
  color: #727272;
  /* width: 150px; */
}

.recentlyViewedIcon {
  margin-right: 10px;
}
.recentDiv {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.seeText,
.seeText p {
  color: #154662;
  font-size: 14px !important;
}
.accessText {
  color: #c2c3c4;
  border-left: 2px solid #c2c3c4;
  padding-left: 15px;
}

.recentlyViewedDetails {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.recentlyViewedDate {
  color: #888888;
  font-size: 12px;
}

.noRecentlyViewedText {
  color: #888888;
  text-align: center;
}

.documentPortalBox {
  /* width: 200px; */
  max-width: 100% !important;
  height: 100% !important;
  cursor: pointer;
  background-color: var(--redprimary);
  padding: 0px 0px 0px 15px;
  position: static;
}
.documentPortalDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.documentPortalText {
  color: #02354d;
  font-weight: 600 !important;
  margin-bottom: 15px !important;
  font-size: 15px !important;
  margin-top: 35px !important;
  margin-left: 10px !important;
}
.documentIcon {
  width: 20px !important;
}
.documentIconText {
  font-size: 13px !important;
}
.docDiv {
  display: flex !important;
  /* margin-bottom: 20px !important; */
}

.paginationBottom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-bottom {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.policies-pagination-root {
  display: flex;
  justify-content: center;
}

.policies-pagination-ul {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 4px;
  border: none;
}

.policies-pagination-ul .MuiPaginationItem-root {
  color: #000;
  border: none;
  margin: 0 2px;
  padding: 6px 12px;
  font-size: 14px;
  min-width: 32px;
  height: 32px;
  border-radius: 4px;
  transition: background-color 0.1s ease;
  position: relative;
}
.policies-pagination-ul .MuiPaginationItem-root.MuiPaginationItem-firstLast {
  display: none;
}

.policies-pagination-ul .MuiPaginationItem-root:hover {
  background-color: #e0e0e0;
}

.policies-pagination-ul .MuiPaginationItem-root.Mui-selected {
  background-color: #009bad;
  color: #fff;
}

.policies-pagination-ul .MuiPaginationItem-root.Mui-selected:hover {
  background-color: #008a9d;
}

.policies-pagination-ul .MuiPaginationItem-root.Mui-selected::after {
  content: "";
  position: absolute;
  bottom: 0;
  border: none;
  transform: translateX(-50%);
  transform: translateY(300%);
  width: 100%;
  height: 2px;
  background-color: #009bad;
  border-radius: 5px;
}

.policies-pagination-ul .MuiPaginationItem-root.Mui-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.policiesLogo,
.processMapLogo,
.guidelinesLogo,
.authorityMatricesLogo {
  height: 17px !important;
  width: 20px !important;
  margin-right: 15px !important;
}

.policiesLogo {
  height: 25px;
  width: 25px;
  margin-right: 15px;
}
.MuiMenu-paper .MuiList-root .MuiMenuItem-root {
  border: none !important;
  border-radius: 0 !important; /* Optional: to remove any border-radius */
  font-size: 12px;
}
.ml15Grid {
  margin-left: 15px;
}

.gridCard {
  border: 1px solid gray;
  width: 150px;
  height: auto;
  padding: 16px;
}
/* .css-1qp6tnv-MuiDataGrid-root .MuiDataGrid-row {
  max-height: 43px !important;
  min-height: 40px !important;
}
.css-1qp6tnv-MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  max-height: 43px !important;
  min-height: 40px !important;
}

.css-1qp6tnv-MuiDataGrid-root .MuiDataGrid-columnHeader {
  height: auto !important;
}
.css-1qp6tnv-MuiDataGrid-root .MuiDataGrid-withBorderColor {
  border: "none";
} */

/* .EventsTableHeader .MuiDataGrid-columnHeaderTitleContainer {
  margin-top: 5px;
}
.column-header.css-yrdy0g-MuiDataGrid-columnHeaderRow {
  color: #707073 !important;
  font-weight: 600 !important;
  background-color: #fce4e5 !important;
  max-height: 43px !important;
  min-height: 43px !important;
} */
.MuiDataGrid-columnHeaders {
  max-height: 43px !important;
  min-height: 43px !important;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid;
  border-top-left-radius: var(--unstable_DataGrid-radius);
  border-top-right-radius: var(--unstable_DataGrid-radius);
}
.css-k008qs {
  display: flex;
  /* background-color: #fde4e5 !important; */
}

.MuiDataGrid-root .MuiDataGrid-columnHeaders .EventsTableHeader {
  background-color: #fde4e5 !important;
  font-size: 14px;
  color: #707073;
  font-weight: 500;
  line-height: 21.09px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 43px !important;
  max-height: 43px !important;
  line-height: 56px !important;
}
.MuiDataGrid-main.MuiDataGrid-columnHeaders {
  min-height: 43px !important;
  max-height: 43px !important;
  line-height: 56px !important;
  background-color: #fde4e5 !important;
  font-size: 14px !important;
  color: #707073 !important;
  padding-top: 10px !important;
}

.MuiDataGrid-root .MuiDataGrid-cell .EventsTableCell {
  font-size: 14px !important;
  color: #ababab !important;
  min-height: 43px !important;
  max-height: 43px !important;
}

.MuiDataGrid-root .even-row {
  background-color: rgba(249, 249, 249, 1) !important;
}

.MuiDataGrid-root .odd-row {
  background-color: rgba(255, 255, 255, 1) !important;
}
.css-1621746-MuiDataGrid-root .MuiDataGrid-withBorderColor {
  border-color: snow !important;
}

.gridButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.gridButton:hover {
  background-color: lightgray; /* Hover background color */
}

.gridButton.active {
  background-color: red; /* Active background color */
}
.grid-card {
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 8px;
  height: 70px;
  width: 250px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px;
  margin: 10px;
}
.grid-card:hover {
  background-color: #fff7f7;
}
.grid-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.pdfIcon {
  width: 50px;
  height: 70px;
}
.dots {
  color: #888888;
  width: 20px;
  background-color: #f4eded;
  border-radius: 50%;
  height: 20px !important;
  cursor: pointer;
  float: right;
}
.section-text {
  font-size: 12px !important;
  color: rgb(195, 193, 193) !important;
  padding: 20px 10px 10px 10px !important;
}
.item-Text {
  font-size: 12px !important;
  color: #d2d2d2 !important;
  padding-top: 0px;
}
.new-button {
  background-color: #e0001b !important;
  border-radius: 5px;
  width: 50px;
  height: 30px;
}

/* .new MuiButton {
    color: white !important;
    background-color: #e0001b;
  } */
.usefull-icons {
  color: #e0001b !important;
  padding-right: 20px;
  width: 15px;
}
.usefull-label {
  color: #b7b7b7 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  text-align: left !important;
}
.policy-link {
  display: flex;
}
.create {
  color: white !important;
  /* padding: 4px !important; */
}
.breadbutton-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 60px;
}
.display-Button {
  width: 130px;
  display: flex;
  /* background-color: white; */
}
.whiteIcon {
  padding: 6px;
  background-color: #e0001b;
  border-radius: 5px;
  /* width: 20px;
    height: 20px; */
}
.redIcon {
  padding: 6px;
  background-color: white;
  border-radius: 5px;
  /* width: 20px;
    height: 20px; */
}
.menu-icons {
  padding-right: 20px;
}
.menu-items {
  font-size: 8px;
}
.next-icon {
  width: 30px;
  height: 40px;
  padding: 4px;
  background-color: white;
  border-radius: 0;
  margin-left: -28px;
}
.new-breadcrumbs {
  font-size: 12px;
  color: #e84659;
}
.new-breadcrumbs.section {
  font-size: 12px;
  color: #808080;
}

.new-breadcrumbs span {
  color: #808080;
  cursor: pointer;
}
.salesMain-div {
  padding-right: 30px !important;
  margin-bottom: 20px !important;
  background-color: rgb(249, 249, 249);
  /* margin-top: 30px !important; */
}
/* .sales-upper-div { */
  /* padding-left: 30px !important; */
  /* padding-bottom: 30px !important; */
/* } */

@media only screen and (max-width: 768px) {
  .sales-card-details {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    width: 100%;
  }
  .color-Card {
    width: 100%;
  }
  .ml15Grid {
    margin-left: 3px;
  }
  .grid-details {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}
@media (min-width: 769px) and (max-width: 1100px) {
  .grid-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .sales-card-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
  }
}
.DepartmentData-table1 {
  /* max-width: fit-content; */

  /* margin-top: 15px;
  max-width: fit-content;
  /* Set a maximum width */
  /* width: 100%; */
  /* Ensure it takes full width of its container */
  /* padding: 20px; */
  /* border-radius: 20px; */
  /* box-shadow: 10px !important; */
  box-shadow: none;
  background-color: white;
  /* height: 430px !important; */
  /* border: 1px solid red; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow-x: auto;
  /* margin-top: 58px !important; */
  /* margin-left: 15px; */
  /* margin-right: 20px !important; */
  /* Enable horizontal scrolling for small screens */
}
.even-row {
  background-color: rgba(249, 249, 249, 1) !important;
}

.odd-row {
  background-color: rgba(255, 255, 255, 1) !important;
  /* Red color for odd rows */
}
.Tablebreadcrumbarrow-icon2 {
  position: relative;
  left: 24px !important;
}
.policiesDepartmentBox {
  margin: 0px;
  background-color: #f3f3f3;
}
