/* General styles */
/* body {
    background: #F7F7F7 !important;
    margin: 0;
    padding: 0;
    font-family: 'DM Sans' !important;
} */

p, h1, h2, h3, h4, h5, h6 {
    margin: 0 !important;
}

/* Circle button styling */
.circle {
    /* position: absolute; */
    position: fixed;   
    right: 25px;
    bottom: 50px; 
   /* bottom: 15px; */
   z-index: 11; 
}

.circle img {
    width: 35px;
    height: 35px;
}

/* Initial hidden state for close button */
.circle .closeh {
    display: none;
  
}

/* Show close button when active */
.circle.active .closeh {
    display: block;
    bottom: 50px;
}

/* Hide open button when active */
.circle.active .open {
    display: none;
}

/* Show-item container styling */
.show-item {
    display: flex;
    flex-direction: column;
    width: 300px;
    border-radius: 20px;
    background: white;
    position: fixed;
    right: 27px;
    /* bottom: 68px; */
    bottom: 153px !important;
    height: 71% !important;
    display: none;
    z-index: 10; 
    /* border: 1px solid yellow; */
    
}
.show-item.maximize {
    width: 30%; 
    display: flex;
    flex-direction: column;
    width: 300px;
    border-radius: 20px;
    background: white;
    position: fixed;
    right: 27px !important;
    /* bottom: 89px !important; */
}

/* Header and content styling */
.show-item h1 {
    font-size: 21px;
    font-weight: 700;
    color:#990065;
}

.show-item h2 {
    font-size: 17px;
    font-weight: 600;
    padding: 10px 0px 6px 0px;
}

/* User icon styling */
.show-item .user-icon {
    width: 15px;
}

/* General text styling */
.show-item span {
    color: #585858;
    font-weight: 600;
    font-size: 12px;
}

.show-item .time {
    position: absolute;
    left: 48px;
    bottom: 6px;
    font-weight: 500;
}

/* Main container styling */
.main1 {
    text-align: center;
    padding:15px 0px;
    background-color:#f3e0ed;
    border-radius: 20px 20px 0px 0px;
  
}

/* Logo styling */
.logo {
    padding: 10px 0px;
    width: 71px;
    margin-top: 10px;
}

/* Link styling */
/* a:hover {
    text-decoration: none !important;
} */

/* Content container styling */
.chat-content {
    padding: 24px 24px 0px;
    /* overflow-y: scroll; */
    overflow-y: auto;
    scrollbar-width:thin;
    /* overflow-x: hidden; */
    height: 403px;
    margin: 0px;
}

.chat-content{
    position: relative;
    text-align: left;
    padding-left: 20px;
    padding-bottom: 16px;
}
.left-msg {
    position: relative;
    text-align: left;
    padding-left: 55px;
    /* padding-bottom: 16px; */
}

.img-msg {
    position: absolute;
    left: -8px;
    bottom: 25px;
}

.img-msg img {
    width: 38px;
}

/* Bot message styling */
.bot-msg {
    padding: 15px;
    border-radius: 10px;
    position: relative;
    display: inline-block;
    width: auto;
    margin-bottom: 10px;
    max-width: 98%;
    color: rgb(0, 0, 0);
    background-color: rgb(243 224 237 / 35%);
    font-size: 14px;
   
}

/* Right message styling */
.right-msg {
    margin-top: 5px;
    margin-bottom: 15px;
    text-align: -webkit-right;
    display: flex;
    justify-content: flex-end;  /* Align the message to the right */
    margin-bottom: 10px;
    
}

.right-msg .user-msg {
    padding: 10px 17px;
    border-radius: 10px;
    width: auto;
    color: rgb(255, 255, 255);
    background-color: #990065;
    margin-bottom: 10px;
    font-size: 14px;
    max-width: 80%;
    width: fit-content;
    text-align: left;
    position: relative;
   /* left: 260px; */
}

/* Text area styling */
.text-area {
    max-height: 200px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
}

.text-area input {
    color: rgb(26, 26, 26);
    border: none;
    transition: background-color 200ms ease 0s, box-shadow 200ms ease 0s;
    outline-offset: -5px;
    overflow-y: auto;
    resize: none;
    width: 290px;
    padding: 13px 10px 14.5px 15px;
    border-radius: 20px;
    margin-left: 11px;
   
    
}
@media (max-width: 1024px) {
    .text-area input {
    width: 270px;
    }
}
.send-btn {
    position: absolute;
    top: 59%;
    right: 14px;
    transform: translate(-50%, -50%);
}

.send-box {
    position: relative;
    padding: 0px;
    /* padding: 10px 0px 0px 0px; */
}

/* Remove focus outline from input */
input:focus-visible {
    outline: none;
}

/* Scrollbar styling */
/* ::-webkit-scrollbar {
    width: 3px; 
      
} */

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Response rating styling */
.response_rating {
    display: flex;
    align-items: center;
    position: absolute;
    left: 47%;
    bottom: -1px;
}

.response_rating img {
    width: 15px;
}

.response_rating span {
    color: #585858;
    font-weight: 600;
    font-size: 10px;
    padding-right: 6px;
}

.like_dislike {
    background: rgb(242 242 242);
    padding: 4px 10px;
    border-radius: 23px;
    border: 2px solid #fff;
    outline: 2px solid rgb(242 242 242);
}

.like_dislike .msg_like {
    float: left;
    width: 12px;
    display: flex;
    margin-right: 10px;
}

.like_dislike .msg_dislike {
    float: left;
    display: flex;
    margin-top: 3px;
}

.like_dislike .msg_dislike::before {
    margin-right: 8px;
    height: 15px;
    margin-top: -1.5px;
    width: 1px;
    border: 1px solid #333333ab;
    content: "";
}

textarea {
    resize: none;
    height: 120px;
    width: 100%;
    border: none;
    outline: none;
    padding: 10px 20px;
}

.feedback_heading {
    font-size: 16px;
    font-weight: 600;
    color: #008c99;
    padding: 10px 20px;
    border-bottom: 1px solid #33333370;
}

.feedback_heading a img {
    width: 14px;
}

.feedback_heading a {
    width: 14px;
    float: right;
}

.feedback_heading .HEADER {
    float: left;
}

.feedback_box {
    background-color: #fff;
    border-radius: 20px;
    width: 296px;
    border-radius: 10px;
    position: absolute;
    top: 245px;
    right: 81px;
    padding: 0;
}

textarea::placeholder {
    color: rgb(0 0 0 / 38%);
    font-size: 13px;
}

.feedback_box button {
    color: #fff;
    cursor: pointer;
    background: #008c99;
    padding: 8px 16px;
    border-radius: 5px;
    border: none;
    float: right;
    margin: 0px 20px 20px 0px;
}

.chat_feed {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(51 51 51 / 70%);
    z-index: 999999;
    cursor: pointer;
}

.tab-content {
    display: none;
}

/* Show the content of the active tab */
.tab-content.active {
    display: block;
}

/* Header styling */
.REMO_logo {
    width: 36px;
}

.user_logo {
    display: flex;
    align-items: center;
    gap: 8px;
    float: left;
}

.resize {
    float: right;
}

.resize img {
    width: 19px;
}

.remo_chat {
    padding: 0px 20px;
}

/* Emoji and attachment styling */
.att_emoji {
    /* padding: 10px 24px 15px 20.5px; */
    padding: 5px 12px 7.5px 10px;
    border-top: 1px solid rgb(238, 238, 238);
    background: rgb(0 0 0 / 6%);
    border-radius: 0px 0px 20px 20px;
}

.attachment {
    float: left;
}

.attachment img {
    width: 17px;
    opacity: 0.6;
    margin: 0px 5px;
}

.powered {
    float: right;
}

.powered img {
   /*  padding-top: 10px; */
    width: 40px;
}

.powered span {
    color: #585858a3;
    font-weight: 600;
    font-size: 10px;
    padding-right: 5px;
}

/* Like hover message styling */
.Response_msg {
    display: none;
}

.left-msg:hover .Response_msg {
    display: block;
}

/* Mini and maximize styling */
.mini {
    display: none;
}

.mini.active {
    display: block;
}

.max {
    display: none;
}

.max.active {
    display: block;
}

.quick_response {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    margin-top: 7px;
}

.quick_response p {
    appearance: none;
    font-weight: 400;
    overflow: hidden;
    word-break: break-word;
    font-size: 0.9em;
    /* color: rgb(27, 27, 32);
    border: 1px solid rgb(27, 27, 32);
    background-color: rgb(255, 255, 255); */
    margin: 0px 4px 8px !important;
    padding: 8px;
    border-radius: 6px !important;
    border: 1px solid rgb(99, 99, 99);
    color:rgb(45,45,45);
    background-color: #ffffff;
}

.quick_response p:hover {
    /* background: rgb(0 140 153 / 10%); */
    border:1px solid #990065;
    color:#990065;
    background-color: #f3e0ed
}

/* Maximize screen styling */
.show-item.maximize {
    width: 30%;
    right: 30px;
    position:fixed;
    top: 40px;
    bottom: 20px;
    height: 85%;
}

.left-msg.maximize {
    width: 85%;
}

.response_rating.maximize {
    left: 36%;
}

.feedback_box.maximize {
    left: 80px;
}

.quick_response.maximize {
    -webkit-box-pack: end;
    justify-content: center;
}

.right-msg .user-msg.maximize {
    max-width: 85%;
}

.circle.maximize {
    display: none;
}

/* Specific styles for the bot-wrap and close button */
/* .bot-wrap.active .show-item {
    display: flex;
}

.msg-close {
    display: none;
}

.bot-wrap.active .msg-icon {
    display: none;
}

.bot-wrap.active .msg-close {
    display: block;
} */

.bot-wrap.active .show-item {
    display: flex;
    border-radius: 20px;
}

.msg-close {
    display: none;
}

.bot-wrap.active .msg-icon {
    display: none;
}

.bot-wrap.active .msg-close {
    display: block;
}


/* Like and dislike button styling */
.msg_like .like_h {
    display: none;
}

.msg_like:hover .like_h {
    display: block;
}

.msg_like:hover .like {
    display: none;
}

.msg_dislike .dislike_h {
    display: none;
}

.msg_dislike:hover .dislike_h {
    display: block;
}

.msg_dislike:hover .dislike {
    display: none;
}

.msg_like.active .like_h {
    display: block;
}

.msg_like.active .like {
    display: none;
}

.msg_dislike.active .dislike_h {
    display: block;
}

.msg_dislike.active .dislike {
    display: none;
}

.p{
    font-size: 10px;
    font-weight: 500;  
}
.pnew{
    font-size: 10px;
    font-weight: 500;  
}

/* 30/5/24 */
.PowerByByrne{
    font-size:13px;
    color:#990065;
    font-weight: 600;
}

.bot-wrap.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
   
    z-index: 9999;
    /* overflow: auto; */
  }
  /* Adjustments for fullscreen mode */
.fullscreen .show-item {
    width: 80%;
    height: 75% !important;
    right: 10%;
    top: 10%;
    bottom: 0;
    border-radius: 0;
}

/* .fullscreen .chat-content {
    height: calc(100% - 180px);
    
} */

.fullscreen .send-box input {
    width: calc(100% - 60px); /* Adjust input width for fullscreen */
}

/* Adjustments for chat content in fullscreen mode */
.chat-content.fullscreen {
    height: 100%;
    padding: 20px;
}

.fullscreen .resize img.max {
    display: none;
}

.fullscreen .resize img.mini {
    display: block;
}