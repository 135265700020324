.fa-2x {
  font-size: 2em;
}
.fa {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
}

.main-menu:hover,
nav.main-menu.expanded {
  width: 250px;
  overflow: visible;
}

.main-menu {
  background: #ffffff;
  /* border-right: 1px solid #e5e5e5; */
  position: absolute;
  top: 70px;
  bottom: 0;
  height: 100%;
  left: 106px;
  overflow: hidden;
  width: 60px;
  -webkit-transition: width 0.05s linear;
  transition: width 0.05s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: translateZ(0) scale(1, 1);
}

.main-menu > ul {
  margin: 16px 0px;
}

.main-menu li {
  position: relative;
  display: block;
  width: 250px;
}

.main-menu li > a {
  position: relative;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  color: #999;
  font-family: Calibri Regular !important;
  font-size: 14px;
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1, 1);
  -webkit-transition: all 0.1s linear;
  transform: translateZ(0) scale(1, 1);
  transition: all 0.1s linear;
}

.main-menu .nav-icon {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}

.main-menu .nav-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 190px;
}

.main-menu > ul.logout {
  position: absolute;
  left: 0;
  bottom: 0;
}

.no-touch .scrollable.hover {
  overflow-y: hidden;
}

.no-touch .scrollable.hover:hover {
  overflow-y: auto;
  overflow: visible;
}

a:hover,
a:focus {
  text-decoration: none;
}

nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

nav ul,
nav li {
  outline: 0;
  margin: 0;
  padding: 0;
}
.main-menu li:hover > a,
nav.main-menu li.active > a,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus,
.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,
.dashboard-page nav.dashboard-menu ul li.active a {
  color: #000000;
  opacity: 0.6;
  background-color: #f0f8fc;
}
.area {
  float: left;
  background: #e2e2e2;
  width: 100%;
  height: 100%;
}

/* ContentElements.tsx file css */
.ContentEditorNewmainBox {
  background-color: #f3f3f3;
  display: flex;
  flex-direction: row;
  /* margin: 0px 30px; */
  padding: 30px 30px;
  height: 100%;
  position: relative;
  margin-bottom: 45px;
}

/* For small screens */
@media only screen and (max-width: 768px) {
  .ContentEditorNewmainBox {
    flex-direction: column !important;
    /* margin: 5px; */
  }
}
.side-navbar {
  transition: width 0.3s ease; /* Smooth transition for width change */
  cursor: pointer; /* Change cursor to pointer to indicate clickability */
  width: 35px;
  height: 100vh !important;
  box-shadow: 3px 0 10px rgba(0, 0, 0, 0.1);
  padding: 15px 10px;
  border-radius: 10px 1px 1px 10px;
  /* background: rgba(255, 255, 255); */
  overflow: auto;
  overflow-x: hidden;
}
.side-navbar::-webkit-scrollbar {
  width: 0;
}
.side-navbar::-webkit-scrollbar-track {
  background: transparent;
}
.side-navbar2 {
  transition: width 0.3s ease;
  cursor: pointer;
  width: 35px;
  height: 100vh !important;
  box-shadow: 3px 0 10px rgba(0, 0, 0, 0.1);
  padding: 15px 10px;
  border-radius: 1px 1px 1px 1px;
  /* background: rgba(255, 255, 255); */
}
.side-navpageicon div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* font-size: 18px; */
  padding-top: 10px;
  border-radius: 8px;
  padding-left: 5px;
}
.side-navheader {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
  padding-top: 15px;
  padding-left: 5px;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
}
.SidebarHeader {
  font-size: 20px;
  color: #ffffff;
  /* font-weight: 600; */
}
.LeftRightarrowicon {
  display: inline-block;
  transition: transform 0.3s ease-in-out;
}
.LeftRightarrowicon:hover {
  animation: bounce 0.5s;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.SidebarsubHeading {
  font-size: 14px !important;
  color: white !important;
  opacity: 0.6 !important;
}
.SidebarTitles2 {
  font-size: 16px !important;
  color: white !important;
  font-style: italic;
  /* opacity: 0.6 !important; */
}
.Plusbuttonforsliderincontenteditor {
  width: 15px;
  color: white;
}

.side-navheader div {
  display: none;
}
.side-navbar .side-navheader div .side-navpageicon div {
  display: none;
}
.side-navbar .side-navpageicon div {
  display: none;
}

/* Sidebar2 */

.side-navbar2 .side-navheader div .side-navpageicon div {
  display: none;
}
.side-navbar2 .side-navpageicon div {
  display: none;
}

/* .sidenavbarulist {
    list-style: none !important;
    padding: 10px !important;
    display: none;
    padding-top: 0px !important;
  } */

.side-navbar.open .sidenavbarlist.active {
  background-color: rgba(5, 137, 152, 0.1);
  border-right: 5px solid rgba(5, 137, 152, 1);
}
.side-navbar2.open .sidenavbarlist.active {
  background-color: rgba(5, 137, 152, 0.1);
  border-right: 5px solid rgba(5, 137, 152, 1);
}
.sidenavbarulist .sidenavbarlistt {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  cursor: pointer !important;
}

.sidenavbarulist .sidenavbarlistt p {
  white-space: nowrap !important;
  display: none;
}

.sidenavbarul {
  list-style: none !important;
  /* padding: 10px !important; */
  padding-top: 0px !important;
  margin-inline-end: 0px !important;
}

.Side-Navbar-New .sidenavbarlistnew:hover {
  /* background-color: rgba(5, 137, 152, 0.1) !important; */
  background-color: #e0001b0d !important;
}
.Side-Navbar-New .sidenavbarlistnew:hover {
  /* background-color: rgba(5, 137, 152, 0.1) !important; */
  background-color: #e0001b0d !important;
}
.Side-Navbar-New .sidenavbarlist2:hover {
  /* background-color: rgba(5, 137, 152, 0.1) !important; */
  background-color: #e0001b0d !important;
}
.Side-Navbar-New .sidenavbarlist2:hover {
  /* background-color: rgba(5, 137, 152, 0.1) !important; */
  background-color: #e0001b0d !important;
}
.Side-Navbar-New .sidenavbarlistnew.selected {
  background-color: #f5ede6 !important;
}
.sidenavbarulnew .sidenavbarlistnew {
  margin: 15px 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  cursor: pointer !important;
  padding: 5px 10px !important;
}

.sidenavbarulnew .sidenavbarlistnew img {
  max-width: 25px !important;
  margin-right: 10px !important;
}
/* .sidenavbarul .sidenavbarlist p {
    font-size: 14px;
    color: rgb(0, 0, 0);
  } */
/* .sidenavbarul .sidenavbarlist p {
    white-space: nowrap !important;
    display: none;
  } */
.side-navbar.open .sidenavbarul .sidenavbarlist p {
  display: block;
}
.side-navbar2.open .sidenavbarul .sidenavbarlist p {
  display: block;
}
.side-navbar.open {
  width: 230px;
  /* margin-right: 3px; */
}
.side-navbar2.open {
  width: 230px;
  /* margin-right: 3px; */
}
.side-navbar.open .side-navheader div {
  display: block;
}

.side-navbar.open .side-navpageicon div {
  display: flex;
}
.side-navbar2.open .side-navheader div {
  display: block;
}

.side-navbar2.open .side-navpageicon div {
  display: flex;
}
/* List item styles */
.sidenavbarulist {
  list-style: none;
  padding: 10px;
  display: none; /* Hide list items by default */
  padding-top: 0;
}

/* List item text styles */
.sidenavbarulist .sidenavbarlistt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

/* List item text styles */
/* .sidenavbarulist .sidenavbarlistt p {
    white-space: nowrap;
    display: none;
  } */

/* List styles */
.sidenavbarul {
  list-style: none;
  /* padding: 10px; */
  padding-top: 0;
  margin-inline-end: 0;
}

.sidenavbarul .sidenavbarlist {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.side-navbar .sidenavbarul .sidenavbarlist img {
  margin-right: 20px;
}
/* List item image styles */
/* .sidenavbarul .sidenavbarlist img {
    width: 25px;
    margin-right: 10px;
  } */

/* List item text styles */
/* .sidenavbarul .sidenavbarlist p {
    font-size: 14px;
    color: rgb(0, 0, 0);
    white-space: nowrap;
    display: none; 
  } */

/* Select container styles */
.select-container {
  margin: 8px 10px;
}

/* Show select container when sidebar is open */
.side-navbar.open .select-container {
  display: block;
}
.SidebarTitles {
  position: relative;
  left: 10px;
  font-size: 15px;
  color: white;
  cursor: pointer;
}
.SidebarTitleshover {
  position: relative;
  left: 10px;
  font-size: 15px;
  color: white;
  cursor: pointer;
}
.SidebarTitlesnew {
  position: relative;
  left: 10px;
  font-size: 15px;
  color: white;
  cursor: pointer;
  padding: 3px 0px !important;
}
.SidebarTitlesnewhover {
  position: relative;
  left: 10px;
  font-size: 15px;
  color: var(--redprimary);
  cursor: pointer;
  padding: 3px 0px !important;
}
/* .side-navbar {
    width: 50px;
    height: 100vh;
    box-shadow: 3px 0 10px rgba(0, 0, 0, 0.1);
    padding: 15px 15px;
    border-radius: 10px 1px 1px 10px;
    background: rgba(255, 255, 255);
    transition: width 0.5s;
  }
  .side-navpageicon div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    padding: 10px;
    border-radius: 8px;
  
  }
  
  .side-navpageicon div {
    display: none;
  }
  .side-navheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 18px;
    padding: 10px;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
  }
  .SidebarHeader {
    font-size: 18px;
    color: #02354d;
    font-weight: 600;
  }
  .sidebarsubHeading {
    font-size: 14px;
    color: #333333;
    opacity: 0.6;
  }
  .Plusbuttonforsliderincontenteditor {
    width: 15px;
  }
  
  .side-navheader div {
    display: none;
  }
  .side-navbar .side-navheader div .side-navpageicon div {
    display: none;
  }
  .sidenavbarulist {
    list-style: none !important;
    padding: 10px !important;
    display: none;
    padding-top: 0px !important;
  }
  
  
  
  .sidenavbarulist .sidenavbarlistt {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    cursor: pointer !important;
  }
  
  .sidenavbarulist .sidenavbarlistt p {
    white-space: nowrap !important;
    display: none;
  }
  
  .sidenavbarul {
    list-style: none !important;
    padding: 10px !important;
    padding-top: 0px !important;
    margin-inline-end: 0px !important;
  }
  .sidenavbarlist:hover {
    background-color: #f0f0f0;
  }
  .sidenavbarul .sidenavbarlist {
    margin: 20px 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    cursor: pointer !important;
  }
  .sidenavbarul .sidenavbarlist img {
    width: 25px !important;
    margin-right: 10px !important;
  }
  .sidenavbarul .sidenavbarlist p {
    font-size: 14px;
    color: rgb(0, 0, 0);
  }
  .sidenavbarul .sidenavbarlist p {
    white-space: nowrap !important;
    display: none;
  }
  .side-navbar:hover {
    width: 200px;
  }
  .side-navbar:hover .side-navpageicon div {
    display: block;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .side-navbar:hover .side-navpageicon {
    width: 100%;
  }
  .side-navbar:hover .side-navheader div {
    display: block;
  }
  .side-navbar:hover .side-navheader {
    width: 100%;
  }
  .side-navbar:hover .sidenavbarul .sidenavbarlist p {
    display: block;
  }
  .side-navbar:hover .sidenavbarul .sidenavbarlist img {
    margin-right: 20px;
  }
  .side-navbar:hover .sidenavbarul .sidenavbarlist {
    justify-content: flex-start;
  }
  .side-navbar:hover .sidenavbarulist {
    display: block;
  }
  .side-navbar:hover .sidenavbarulist .sidenavbarlistt p {
    display: block;
  }
  .side-navbar:hover .sidenavbarulist .sidenavbarlistt img {
    margin-right: 20px;
  }
  .side-navbar:hover .sidenavbarulist .sidenavbarlistt {
    justify-content: space-between;
  }
  .select-container {
    display: none;
    margin: 0px 10px;
  }
  .side-navbar:hover .select-container {
    display: block;
  } */

/* Content Editor Slider Select option */
.input {
  /* Reset. */
  border: unset;

  /* Style */
  width: 100%;
  max-width: 400px;
  padding: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  /* background: #f0f0f0 !important; */

  /* Arrow */
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
}

/* .side-navbar.open .Tooltipimg:hover{
    opacity: 0; }
  .side-navbar2.open .Tooltipimg:hover{
    opacity: 0; } */

/* New Side bar Css */

.Side-Navbar-New {
  width: 230px;
  height: 595px !important;
  box-shadow: 3px 0 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 10px 1px 1px 10px;
  overflow: auto;
  overflow-x: hidden;
  background-color: var(--redprimary);
}
@media only screen and (min-width: 1025px) and (max-width: 1430px) {
  .Side-Navbar-New {
    height: 620px !important;
  }
}

@media (max-width: 1024px) {
  .Side-Navbar-New {
  width: 230px;
  height: 611px !important;
}
}
@media (max-width: 768px) {
  .Side-Navbar-New {
    width: 100% !important;
  }
}
.Side-Navbar-New::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.Side-Navbar-New {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.Side-NavBarheader {
  display: flex;
  width: 100%;
  padding-top: 15px;
  padding-left: 5px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px;
}
.SideNavbar-navpageicon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-left: 5px;
}
.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.accordion-content.open {
  max-height: 500px; /* Adjust based on your content height */
}
.default-page {
  background-color: #009bad; /* Set the background color to red for the default page */
  border-radius: 4px;
}
/* styles.css */
.active1 .SidebarTitles {
  color: #f3f3f3 !important;
}

.active1 {
  /* background-color: #ffffff; */
  background-color: #f3f3f3;
  border-radius: 6px;
}
/* .active3{
  background-color: #ffffff;
  border-radius: 6px;
 
} */
.contenteditorimg {
  max-width: 25px !important;
  /* margin-right: 10px !important; */
}
.red-highlight {
  background-color: #e0001b;
  border-radius: 6px;
}
.selected-item {
  background-color: red; /* Or any color you want for selected items */
}
.Side-Navbar-New .sidenavbarlistnew:hover {
  /* background-color: #FDE4E5 !important; */
  border-radius: 6px; /* Adjust the radius as needed */
}

.Side-Navbar-New .sidenavbarlistnew:hover {
  background-color: #f3f3f3 !important;
  border-radius: 6px;

}
.Side-Navbar-New .sidenavbarlistneww:hover {
  background-color: #f3f3f3 !important;
  border-radius: 6px;

}
.filter-blue{
  /* filter: invert(42%) sepia(99%) saturate(1021%) hue-rotate(169deg) brightness(95%) contrast(85%); */
  filter: invert(200%) brightness(200%) contrast(200%);
  max-width: 25px !important;
  /* margin-right: 10px !important; */

}




.sidenavbarlistnew {
  cursor: pointer;
}

.sidenavbarlistnew img.contenteditorimg {
  display: block;
}

.sidenavbarlistnew img.contenteditorimghover {
  display: none;
}

.sidenavbarlistnew p.SidebarTitles {
  display: block;
  color: #ffffff; /* Normal color */
}

.sidenavbarlistnew p.SidebarTitleshover {
  display: none;
}

.sidenavbarlistnew:hover img.contenteditorimg {
  display: none;
}

.sidenavbarlistnew:hover img.contenteditorimghover {
  display: block;
}

.sidenavbarlistnew:hover p.SidebarTitles {
  display: none;
}

.sidenavbarlistnew:hover p.SidebarTitleshover {
  display: block;
  color: var(--redprimary); /* Hover color */
}

.sidenavbarlistnew.active1 img.contenteditorimg {
  display: none;
}

.sidenavbarlistnew.active1 img.contenteditorimghover {
  display: block;
}

.sidenavbarlistnew.active1 p.SidebarTitles {
  display: none;
}

.sidenavbarlistnew.active1 p.SidebarTitleshover {
  display: block;
  color: var(--redprimary); /* Active color */
}
