.headerAppbar {
  width: 100%;
  position: static;
  background-color: #e0001b !important;
  color: white;
  height: "48px";
 
}

.toolbarDiv {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  min-height: auto !important;
  background-color: #ffffff;
}
.css-hyum1k-MuiToolbar-root {
  min-height: 40px !important;
}

.profile {
  font-size: 12px !important;
  color: #8F9094;
  cursor: default !important;
}
.profileSignout{
  cursor: pointer !important;
  font-size: 12px !important;
  color: #8F9094;
}
.profile1 {
  font-size: 12px !important;
  color: #8F9094;
  
}
.profile1 img {
  width: 13px !important;
  margin-right: 10px;
}
.profile img {
  width: 15px !important;
  margin-right: 10px;
}
.user-email {
  cursor: default; 
  user-select: none; 
  text-decoration: none; 
}
.grey-placeholder::placeholder {
  color: grey;
}
.userDetailsGrid {
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  /* background-color: #cccccc60; */
  top: 10px !important;
  /* height: 70px !important; */
}
.signout{
  background-color: #179949;
  color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -8px;
  border-radius: 0 0 4px 4px;
}
