.Gallery-landingresponsive{
    padding:16px !important;
    border-radius: 8px !important;
  }
  .imageandVideoHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  .imgvideoHeader{
    white-space: inherit;
    /* font-size: 16px !important;
    color: #009BAD !important; */
  }
  .imgvideoViewall{
    /* font-size: 12px !important;
    color: #089DE3 !important; */
    margin-top: 0px;
    text-decoration: none !important;
  }
  .imageListStylesforlanding{
    padding: '10px';  
    display: 'grid';
    grid-template-columns: 'repeat(2, 1fr)';
    gap: '16px';
    margin-top: '5px';
  }
  .image-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 14px;
    margin-top: 8px;
    @media (max-width: 425px) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px; */
  }
  
  .image-gallerylanding {
      width: 100%;
      height: 100px !important;
      border-radius: 9px;
      object-fit: cover;
  
    /* max-width: 100%;
    height: auto;
    width: 100%;
    border-radius: 8px; */
  }
  
  .breadcrumbsHeader {
    font-size: 0;
    line-height: 20px;
    padding-top: 0;
    width: 0;
  }
  
  .mainconatinerforViewgallery {
    height: auto;
  }
  
  .breadcrumbs {
    color: #ffffff !important;
    display: inline-flex;
  }
  
  
  .breadLinks,
  .breadLinks1,
  .breadLinks2 {
    color: #ffffff !important;
    text-decoration: none !important;
  }
  
  .breadLinks {
    font-size: 24px !important;
  }
  
  .breadLinks1,
  .breadLinks2 {
    font-size: 14px !important;
  }
  
  .breadLinks2 {
    opacity: 0.5;
  }
  
  
  .cardMedia {
    height: 158px !important;
    background-origin: content-box;
    border-radius: 10px;
    margin-bottom: auto;
  }
  
  .cardeventName1 {
    color: var(--redprimary) !important;
    padding-left: 5px;
    /* padding-top: 10px; */
   font-size: 14px !important;
  }
  
  .cardforViewgallery {
    /* padding-bottom: 10px; */
    border-radius: 10px;
    box-shadow: none !important;
  }
  
  
  .contentRoot {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    border-radius: 10px;
  }
  
  .typographyStyles {
    /* color: #009bad !important; */
    color: #e0001b !important;
    font-size: 16px;
  }
  .responsiveimagegallery{
  
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* grid-template-rows: repeat(3, 1fr) !important; */
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    padding-top: 15px;
    /* height: 400px; */
    @media (max-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  
    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  
    @media (max-width: 425px) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .fullCard {
    width: 100%;
  width: fit-content;
    height: auto; /* Adjusted height, removed border */
    border-radius: 10px; /* Removed border */
  
  } 
  
  .paperStyleforViewgallery {
    /* width: 100%; */
    position: relative;
    /* left: -20px; */
    padding: 20px;
    border-radius: 10px !important;
    /* margin:24px; */
    /* height: 100vh !important; */
  }
  
  @media(max-width: 768px) {
    .paperStyleforViewgallery {
      /* width: 100%; */
      position: relative;
      /* left: -20px; */
      padding: 20px;
      border-radius: 10px !important;
      /* margin:24px; */
      height: auto !important;
    }
  }
  @media (max-width: 425px) {
    .paperStyleforViewgallery {
      height: auto !important;
    }
    }
    
    @media (max-width: 320px) {
    .paperStyleforViewgallery {
      height: auto !important;
    }
    }
  .gridContainer {
    width: 100%;
  }
  /* Media Queries for Responsive Design */
  
  @media only screen and (min-width: 600px) {
    .cardName {
      margin-left: 30px;
    }
  
    .contentRoot {
      flex-direction: row;
    }
  
    .gridContainer {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 16px;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .breadcrumbs {
      font-size: 18px !important;
    }
  
    .breadLinks {
      font-size: 20px !important;
    }
  
    .breadLinks1,
    .breadLinks2 {
      font-size: 16px !important;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cardName {
      margin-left: 40px;
    }
  
    .breadcrumbs {
      font-size: 24px !important;
    }
  
    .breadLinks {
      font-size: 26px !important;
    }
  
    .breadLinks1,
    .breadLinks2 {
      font-size: 18px !important;
    }
  
    .gridContainer {
      padding: 20px; 
    }
  }
  .GalleryIcons{
    width: 100%;
    height: 150px;
    border-radius: 15px;
  }
  
  .GalleryVideo{
    width: 100%;
    height: 151px;
    border-radius: 20px;
  }
  .galleryFolder{
    color: #009BAD;
  }
  .WhitecolorBlocksHeader{
    font-size: 16px !important;
    color: #e0001b !important;
    font-weight: 600 !important;
  }
  .imgvideoViewall {
    font-size: 12px !important;
    color: #009bad !important;
    text-decoration: none !important;

}